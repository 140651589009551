import { authAxios } from "@/libs/axios";

import type {
  FetchAppointmentCountReportsPayload,
  FetchAppointmentCountReportsResponseData,
  FetchBeautyCenterAppointmentCountReportsPayload,
  FetchBeautyCenterAppointmentCountReportsResponseData,
  FetchBeautyCenterAppointmentChargeTotalReportsPayload,
  FetchBeautyCenterAppointmentChargeTotalReportsResponseData,
} from "./report.api.types";
import type { AxiosResponseData } from "@/libs/axios";

const reportApi = {
  fetchAppointmentCountReports: (
    payload?: FetchAppointmentCountReportsPayload
  ) => {
    return authAxios.get<
      AxiosResponseData<FetchAppointmentCountReportsResponseData>
    >("reports/appointment_count_report", {
      params: payload?.params,
      cancelToken: payload?.cancelToken,
    });
  },
  fetchBeautyCenterAppointmentCountReports: (
    payload?: FetchBeautyCenterAppointmentCountReportsPayload
  ) => {
    return authAxios.get<
      AxiosResponseData<FetchBeautyCenterAppointmentCountReportsResponseData>
    >("reports/appointment_by_beauty_center_count_report", {
      params: payload?.params,
      cancelToken: payload?.cancelToken,
    });
  },
  fetchBeautyCenterAppointmentChargeTotalReports: (
    payload?: FetchBeautyCenterAppointmentChargeTotalReportsPayload
  ) => {
    return authAxios.get<
      AxiosResponseData<FetchBeautyCenterAppointmentChargeTotalReportsResponseData>
    >("reports/appointment_by_beauty_center_charge_total_report", {
      params: payload?.params,
      cancelToken: payload?.cancelToken,
    });
  },
};

export default reportApi;
