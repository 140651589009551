import { GradeLevelActionTypes } from "./types";
import { commonHelpers } from "@/utils/helpers";
import { HYDRATE } from "next-redux-wrapper";

import type { GradeLevelState, GradeLevelAction } from "./types";

export const initialState: GradeLevelState = {
  primaryGradeLevels: [],
  primaryGradeLevelsError: "",
  primaryGradeLevelsLoading: true,
  primaryGradeLevelsCount: 0,

  secondaryGradeLevels: [],
  secondaryGradeLevelsError: "",
  secondaryGradeLevelsLoading: true,
  secondaryGradeLevelsCount: 0,

  gradeLevel: null,
  gradeLevelError: "",
  gradeLevelLoading: true,
};

const reducer = (
  state = initialState,
  action: GradeLevelAction
): GradeLevelState => {
  switch (action.type) {
    case HYDRATE as any: {
      const { gradeLevel, gradeLevelError, gradeLevelLoading, hydrated } = (
        action as any
      ).payload.gradeLevel as GradeLevelState;

      const newState = {
        gradeLevel,
        gradeLevelError,
        gradeLevelLoading,
      };

      if (typeof hydrated !== "undefined") {
        Object.entries(newState).forEach(([key, _state]) => {
          if (typeof _state === "undefined") delete (newState as any)[key];
        });
      }

      return {
        ...state,
        ...(typeof hydrated !== "undefined" ? newState : {}),
        hydrated: true,
      };
    }

    case GradeLevelActionTypes.FETCH_REQUESTED: {
      const { scope, isReset } = action.payload;

      const newState = {
        ...state,
        ...(typeof state[`${scope}Loading` as keyof typeof state] !==
        "undefined"
          ? {
              [`${scope}Loading`]: true,
            }
          : {}),
        ...(typeof state[`${scope}Error` as keyof typeof state] !== "undefined"
          ? {
              [`${scope}Error`]: "",
            }
          : {}),
      };

      if (isReset) {
        Object.assign(newState, {
          [scope]: Array.isArray(newState[scope]) ? [] : null,
        });
      }

      return newState;
    }
    case GradeLevelActionTypes.FETCH_SUCCEEDED: {
      const { scope, data, count, isLoadMore } = action.payload;

      let newData = data;
      const stateData = state[scope];

      if (isLoadMore && Array.isArray(stateData) && Array.isArray(data)) {
        const filteredData = data.filter((item) => {
          return stateData.every(
            (stateDataItem) => item.id !== stateDataItem.id
          );
        });
        newData = [...stateData, ...filteredData];
      }

      return {
        ...state,
        [scope]: newData,
        ...(typeof state[`${scope}Loading` as keyof typeof state] !==
        "undefined"
          ? {
              [`${scope}Loading`]: false,
            }
          : {}),
        ...(commonHelpers.isNumber(count)
          ? {
              [`${scope}Count`]: count,
            }
          : {}),
      };
    }
    case GradeLevelActionTypes.FETCH_FAILED: {
      const { scope, error } = action.payload;

      return {
        ...state,
        ...(typeof state[`${scope}Loading` as keyof typeof state] !==
        "undefined"
          ? {
              [`${scope}Loading`]: false,
            }
          : {}),
        ...(typeof state[`${scope}Error` as keyof typeof state] !== "undefined"
          ? {
              [`${scope}Error`]: error,
            }
          : {}),
      };
    }

    case GradeLevelActionTypes.FETCH_GRADE_LEVEL_SUCCEEDED_SERVER: {
      return {
        gradeLevel: action.payload,
        gradeLevelLoading: false,
        gradeLevelError: "",
        hydrated: true,
      } as Partial<GradeLevelState> as GradeLevelState;
    }

    case GradeLevelActionTypes.UPDATE_GRADE_LEVEL_ENABLED_SUCCEEDED: {
      const { id, is_enabled } = action.payload;

      return {
        ...state,
        primaryGradeLevels: state.primaryGradeLevels.map(
          (primaryGradeLevel) => {
            if (primaryGradeLevel.id === id)
              return {
                ...primaryGradeLevel,
                is_enabled,
              };
            return primaryGradeLevel;
          }
        ),
        secondaryGradeLevels: state.secondaryGradeLevels.map(
          (primaryGradeLevel) => {
            if (primaryGradeLevel.id === id)
              return {
                ...primaryGradeLevel,
                is_enabled,
              };
            return primaryGradeLevel;
          }
        ),
        gradeLevel:
          state.gradeLevel?.id === id
            ? {
                ...state.gradeLevel,
                is_enabled,
              }
            : state.gradeLevel,
      };
    }

    default: {
      return state;
    }
  }
};

export default reducer;
