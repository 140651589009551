import { all, call, put, takeEvery, takeLatest } from "redux-saga/effects";
import axios from "axios";

import { axiosHelpers } from "@/utils/helpers";
import { beautyCenterApi } from "@/utils/apis";

import {
  BeautyCenterActionTypes,
  CreateBeautyCenterSagaAction,
  UpdateBeautyCenterSagaAction,
  DeleteBeautyCenterSagaAction,
} from "./types";
import {
  fetchRequested,
  fetchFailed,
  fetchSucceeded,
  updateBeautyCenterEnabledSucceeded,
} from "./action";

import type {
  FetchScope,
  FetchBeautyCentersSagaAction,
  UpdateBeautyCenterEnabledSagaAction,
} from "./types";

function* fetchBeautyCentersSaga(action: FetchBeautyCentersSagaAction) {
  const { params, cancelToken } = action.payload || {};
  const { resolve = () => {}, isLoadMore, isReset } = action.meta || {};
  const scope = "beautyCenters" as FetchScope;
  yield put(
    fetchRequested({
      scope,
      isReset,
    })
  );
  try {
    const {
      data: response,
    }: Awaited<ReturnType<typeof beautyCenterApi.fetchBeautyCenters>> =
      yield call(beautyCenterApi.fetchBeautyCenters, {
        params,
        cancelToken,
      });
    if (axiosHelpers.checkRequestSuccess(response)) {
      yield put(
        fetchSucceeded({
          scope,
          data: response.data?.data ?? [],
          count: response.data?.pagination?.total ?? 0,
          isLoadMore: !!isLoadMore,
        })
      );
    } else {
      yield put(
        fetchFailed({
          scope,
          error: response.message,
        })
      );
    }
    resolve(response);
  } catch (error) {
    if (axios.isCancel(error)) {
      resolve({ message: error.message, isCancelled: true });
      return;
    }
    const message = axiosHelpers.getErrorMessage(error);
    yield put(
      fetchFailed({
        scope,
        error: message,
      })
    );
    resolve({ message });
  }
}

function* updateBeautyCenterEnabledSaga(
  action: UpdateBeautyCenterEnabledSagaAction
) {
  const { params, cancelToken } = action.payload;
  const { resolve = () => {} } = action.meta || {};

  try {
    const {
      data: response,
    }: Awaited<ReturnType<typeof beautyCenterApi.updateBeautyCenterEnabled>> =
      yield call(beautyCenterApi.updateBeautyCenterEnabled, {
        params,
        cancelToken,
      });
    if (axiosHelpers.checkRequestSuccess(response)) {
      yield put(
        updateBeautyCenterEnabledSucceeded({
          id: params.id,
          is_enabled: params.is_enabled,
        })
      );
    }
    resolve(response);
  } catch (error) {
    if (axios.isCancel(error)) {
      resolve({ message: error.message, isCancelled: true });
      return;
    }
    const message = axiosHelpers.getErrorMessage(error);
    resolve({ message });
  }
}

function* updateBeautyCenterSaga(action: UpdateBeautyCenterSagaAction) {
  const { params, cancelToken } = action.payload;
  const { resolve = () => {} } = action.meta || {};

  try {
    const {
      data: response,
    }: Awaited<ReturnType<typeof beautyCenterApi.updateBeautyCenter>> =
      yield call(beautyCenterApi.updateBeautyCenter, {
        params,
        cancelToken,
      });
    resolve(response);
  } catch (error) {
    if (axios.isCancel(error)) {
      resolve({ message: error.message, isCancelled: true });
      return;
    }
    const message = axiosHelpers.getErrorMessage(error);
    resolve({ message });
  }
}

function* createBeautyCenterSaga(action: CreateBeautyCenterSagaAction) {
  const { params, cancelToken } = action.payload;
  const { resolve = () => {} } = action.meta || {};

  try {
    const {
      data: response,
    }: Awaited<ReturnType<typeof beautyCenterApi.createBeautyCenter>> =
      yield call(beautyCenterApi.createBeautyCenter, {
        params,
        cancelToken,
      });
    resolve(response);
  } catch (error) {
    if (axios.isCancel(error)) {
      resolve({ message: error.message, isCancelled: true });
      return;
    }
    const message = axiosHelpers.getErrorMessage(error);
    resolve({ message });
  }
}

function* deleteBeautyCenterSaga(action: DeleteBeautyCenterSagaAction) {
  const { params, cancelToken } = action.payload;
  const { resolve = () => {} } = action.meta || {};

  try {
    const {
      data: response,
    }: Awaited<ReturnType<typeof beautyCenterApi.deleteBeautyCenter>> =
      yield call(beautyCenterApi.deleteBeautyCenter, {
        params,
        cancelToken,
      });
    resolve(response);
  } catch (error) {
    if (axios.isCancel(error)) {
      resolve({ message: error.message, isCancelled: true });
      return;
    }
    const message = axiosHelpers.getErrorMessage(error);
    resolve({ message });
  }
}

function* beautyCenterSaga() {
  yield all([
    takeEvery(
      BeautyCenterActionTypes.FETCH_BEAUTY_CENTERS_SAGA,
      fetchBeautyCentersSaga
    ),
    takeLatest(
      BeautyCenterActionTypes.UPDATE_BEAUTY_CENTER_ENABLED_SAGA,
      updateBeautyCenterEnabledSaga
    ),
    takeLatest(
      BeautyCenterActionTypes.UPDATE_BEAUTY_CENTER_SAGA,
      updateBeautyCenterSaga
    ),
    takeLatest(
      BeautyCenterActionTypes.CREATE_BEAUTY_CENTER_SAGA,
      createBeautyCenterSaga
    ),
    takeLatest(
      BeautyCenterActionTypes.DELETE_BEAUTY_CENTER_SAGA,
      deleteBeautyCenterSaga
    ),
  ]);
}

export default beautyCenterSaga;
