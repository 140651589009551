import { all, call, put, takeEvery, takeLatest } from "redux-saga/effects";
import axios from "axios";

import { axiosHelpers } from "@/utils/helpers";
import { platformCodeApi } from "@/utils/apis";

import {
  PlatformCodeActionTypes,
  CreatePlatformCodeSagaAction,
  UpdatePlatformCodeSagaAction,
  DeletePlatformCodeSagaAction,
} from "./types";
import { fetchRequested, fetchFailed, fetchSucceeded } from "./action";

import type { FetchScope, FetchPlatformCodesSagaAction } from "./types";

function* fetchPlatformCodesSaga(action: FetchPlatformCodesSagaAction) {
  const { params, cancelToken } = action.payload || {};
  const { resolve = () => {}, isLoadMore, isReset } = action.meta || {};
  const scope = "platformCodes" as FetchScope;
  yield put(
    fetchRequested({
      scope,
      isReset,
    })
  );
  try {
    const {
      data: response,
    }: Awaited<ReturnType<typeof platformCodeApi.fetchPlatformCodes>> =
      yield call(platformCodeApi.fetchPlatformCodes, {
        params,
        cancelToken,
      });
    if (axiosHelpers.checkRequestSuccess(response)) {
      yield put(
        fetchSucceeded({
          scope,
          data: response.data?.data ?? [],
          count: response.data?.pagination?.total ?? 0,
          isLoadMore: !!isLoadMore,
        })
      );
    } else {
      yield put(
        fetchFailed({
          scope,
          error: response.message,
        })
      );
    }
    resolve(response);
  } catch (error) {
    if (axios.isCancel(error)) {
      resolve({ message: error.message, isCancelled: true });
      return;
    }
    const message = axiosHelpers.getErrorMessage(error);
    yield put(
      fetchFailed({
        scope,
        error: message,
      })
    );
    resolve({ message });
  }
}

function* updatePlatformCodeSaga(action: UpdatePlatformCodeSagaAction) {
  const { params, cancelToken } = action.payload;
  const { resolve = () => {} } = action.meta || {};

  try {
    const {
      data: response,
    }: Awaited<ReturnType<typeof platformCodeApi.updatePlatformCode>> =
      yield call(platformCodeApi.updatePlatformCode, {
        params,
        cancelToken,
      });
    resolve(response);
  } catch (error) {
    if (axios.isCancel(error)) {
      resolve({ message: error.message, isCancelled: true });
      return;
    }
    const message = axiosHelpers.getErrorMessage(error);
    resolve({ message });
  }
}

function* createPlatformCodeSaga(action: CreatePlatformCodeSagaAction) {
  const { params, cancelToken } = action.payload;
  const { resolve = () => {} } = action.meta || {};

  try {
    const {
      data: response,
    }: Awaited<ReturnType<typeof platformCodeApi.createPlatformCode>> =
      yield call(platformCodeApi.createPlatformCode, {
        params,
        cancelToken,
      });
    resolve(response);
  } catch (error) {
    if (axios.isCancel(error)) {
      resolve({ message: error.message, isCancelled: true });
      return;
    }
    const message = axiosHelpers.getErrorMessage(error);
    resolve({ message });
  }
}

function* deletePlatformCodeSaga(action: DeletePlatformCodeSagaAction) {
  const { params, cancelToken } = action.payload;
  const { resolve = () => {} } = action.meta || {};

  try {
    const {
      data: response,
    }: Awaited<ReturnType<typeof platformCodeApi.deletePlatformCode>> =
      yield call(platformCodeApi.deletePlatformCode, {
        params,
        cancelToken,
      });
    resolve(response);
  } catch (error) {
    if (axios.isCancel(error)) {
      resolve({ message: error.message, isCancelled: true });
      return;
    }
    const message = axiosHelpers.getErrorMessage(error);
    resolve({ message });
  }
}

function* platformCodeSaga() {
  yield all([
    takeEvery(
      PlatformCodeActionTypes.FETCH_PLATFORM_CODES_SAGA,
      fetchPlatformCodesSaga
    ),
    takeLatest(
      PlatformCodeActionTypes.UPDATE_PLATFORM_CODE_SAGA,
      updatePlatformCodeSaga
    ),
    takeLatest(
      PlatformCodeActionTypes.CREATE_PLATFORM_CODE_SAGA,
      createPlatformCodeSaga
    ),
    takeLatest(
      PlatformCodeActionTypes.DELETE_PLATFORM_CODE_SAGA,
      deletePlatformCodeSaga
    ),
  ]);
}

export default platformCodeSaga;
