import type {
  FetchBeautyCentersPayload,
  FetchBeautyCentersResponseData,
  FetchBeautyCenterResponseData,
  UpdateBeautyCenterEnabledPayload,
  DeleteBeautyCenterPayload,
  UpdateBeautyCenterPayload,
  CreateBeautyCenterPayload,
} from "@/utils/apis/beautyCenter";

export enum BeautyCenterActionTypes {
  FETCH_REQUESTED = "@@beautyCenter/FETCH_REQUESTED",
  FETCH_SUCCEEDED = "@@beautyCenter/FETCH_SUCCEEDED",
  FETCH_FAILED = "@@beautyCenter/FETCH_FAILED",

  UPDATE_BEAUTY_CENTER_ENABLED_SUCCEEDED = "@@beautyCenter/UPDATE_BEAUTY_CENTER_ENABLED_SUCCEEDED",
  FETCH_BEAUTY_CENTER_SUCCEEDED_SERVER = "@@beautyCenter/FETCH_BEAUTY_CENTER_SUCCEEDED_SERVER",

  // Saga
  FETCH_BEAUTY_CENTERS_SAGA = "@@beautyCenter/FETCH_BEAUTY_CENTERS_SAGA",
  UPDATE_BEAUTY_CENTER_ENABLED_SAGA = "@@beautyCenter/UPDATE_BEAUTY_CENTER_ENABLED_SAGA",
  UPDATE_BEAUTY_CENTER_SAGA = "@@beautyCenter/UPDATE_BEAUTY_CENTER_SAGA",
  CREATE_BEAUTY_CENTER_SAGA = "@@beautyCenter/CREATE_BEAUTY_CENTER_SAGA",
  DELETE_BEAUTY_CENTER_SAGA = "@@beautyCenter/DELETE_BEAUTY_CENTER_SAGA",
}

// State

export interface BeautyCenterState {
  hydrated?: boolean;

  beautyCenters: FetchBeautyCentersResponseData["data"];
  beautyCentersLoading: boolean;
  beautyCentersError: string;
  beautyCentersCount: number;

  beautyCenter: FetchBeautyCenterResponseData["data"] | null;
  beautyCenterLoading: boolean;
  beautyCenterError: string;
}

// ---- Reducer Action ----

export type FetchScope = "beautyCenters" | "beautyCenter";

export type FetchRequestedAction = {
  type: BeautyCenterActionTypes.FETCH_REQUESTED;
  payload: {
    scope: FetchScope;
    isReset?: boolean;
  };
};

export type FetchSucceededAction = {
  type: BeautyCenterActionTypes.FETCH_SUCCEEDED;
  payload: {
    scope: FetchScope;
    data: BeautyCenterState[FetchScope];
    count?: number;
    isLoadMore?: boolean;
  };
};

export type FetchFailedAction = {
  type: BeautyCenterActionTypes.FETCH_FAILED;
  payload: {
    scope: FetchScope;
    error: string;
  };
};

export type FetchBeautyCenterSucceededServerAction = {
  type: BeautyCenterActionTypes.FETCH_BEAUTY_CENTER_SUCCEEDED_SERVER;
  payload: FetchBeautyCenterResponseData["data"] | null;
};

export type UpdateBeautyCenterEnabledSucceededAction = {
  type: BeautyCenterActionTypes.UPDATE_BEAUTY_CENTER_ENABLED_SUCCEEDED;
  payload: {
    id: number;
    is_enabled: BooleanNumber;
  };
  meta?: {
    resolve?: (payload?: any) => void;
  };
};

// ---- Saga Action ----

export type FetchBeautyCentersSagaAction = {
  type: BeautyCenterActionTypes.FETCH_BEAUTY_CENTERS_SAGA;
  payload?: FetchBeautyCentersPayload;
  meta?: {
    isLoadMore?: boolean;
    isReset?: boolean;
    resolve?: (payload?: any) => void;
  };
};

export type UpdateBeautyCenterEnabledSagaAction = {
  type: BeautyCenterActionTypes.UPDATE_BEAUTY_CENTER_ENABLED_SAGA;
  payload: UpdateBeautyCenterEnabledPayload;
  meta?: {
    resolve?: (payload?: any) => void;
  };
};

export type UpdateBeautyCenterSagaAction = {
  type: BeautyCenterActionTypes.UPDATE_BEAUTY_CENTER_SAGA;
  payload: UpdateBeautyCenterPayload;
  meta?: {
    resolve?: (payload?: any) => void;
  };
};

export type CreateBeautyCenterSagaAction = {
  type: BeautyCenterActionTypes.CREATE_BEAUTY_CENTER_SAGA;
  payload: CreateBeautyCenterPayload;
  meta?: {
    resolve?: (payload?: any) => void;
  };
};

export type DeleteBeautyCenterSagaAction = {
  type: BeautyCenterActionTypes.DELETE_BEAUTY_CENTER_SAGA;
  payload: DeleteBeautyCenterPayload;
  meta?: {
    resolve?: (payload?: any) => void;
  };
};

export type BeautyCenterAction =
  | FetchRequestedAction
  | FetchSucceededAction
  | FetchFailedAction
  | FetchBeautyCenterSucceededServerAction
  | UpdateBeautyCenterEnabledSucceededAction
  //
  | FetchBeautyCentersSagaAction
  | UpdateBeautyCenterEnabledSagaAction
  | UpdateBeautyCenterSagaAction
  | CreateBeautyCenterSagaAction
  | DeleteBeautyCenterSagaAction;
