import { all, call, put, takeEvery } from "redux-saga/effects";
import axios from "axios";

import { axiosHelpers } from "@/utils/helpers";
import { appointmentRescheduledRequestApi } from "@/utils/apis";

import { AppointmentRescheduledRequestActionTypes } from "./types";
import { fetchRequested, fetchFailed, fetchSucceeded } from "./action";

import type {
  FetchScope,
  FetchAppointmentRescheduledRequestsSagaAction,
} from "./types";

function* fetchAppointmentRescheduledRequestsSaga(
  action: FetchAppointmentRescheduledRequestsSagaAction
) {
  const { params, cancelToken } = action.payload;
  const { resolve = () => {}, isLoadMore, isReset } = action.meta || {};
  const scope = "appointmentRescheduledRequests" as FetchScope;
  yield put(
    fetchRequested({
      scope,
      isReset,
    })
  );
  try {
    const {
      data: response,
    }: Awaited<
      ReturnType<
        typeof appointmentRescheduledRequestApi.fetchAppointmentRescheduledRequests
      >
    > = yield call(
      appointmentRescheduledRequestApi.fetchAppointmentRescheduledRequests,
      {
        params,
        cancelToken,
      }
    );
    if (axiosHelpers.checkRequestSuccess(response)) {
      yield put(
        fetchSucceeded({
          scope,
          data: response.data?.data ?? [],
          isLoadMore: !!isLoadMore,
        })
      );
    } else {
      yield put(
        fetchFailed({
          scope,
          error: response.message,
        })
      );
    }
    resolve(response);
  } catch (error) {
    if (axios.isCancel(error)) {
      resolve({ message: error.message, isCancelled: true });
      return;
    }
    const message = axiosHelpers.getErrorMessage(error);
    yield put(
      fetchFailed({
        scope,
        error: message,
      })
    );
    resolve({ message });
  }
}

function* appointmentRescheduledRequestSaga() {
  yield all([
    takeEvery(
      AppointmentRescheduledRequestActionTypes.FETCH_APPOINTMENT_RESCHEDULED_REQUESTS_SAGA,
      fetchAppointmentRescheduledRequestsSaga
    ),
  ]);
}

export default appointmentRescheduledRequestSaga;
