import { ProductServiceBeautyCenterSettingActionTypes } from "./types";
import type {
  FetchRequestedAction,
  FetchSucceededAction,
  FetchFailedAction,
  // Saga
  FetchProductServiceBeautyCenterSettingsSagaAction,
  CreateProductServiceBeautyCenterSettingSagaAction,
  DeleteProductServiceBeautyCenterSettingsSagaAction,
} from "./types";

// ---- REDUCER ACTION ----

export const fetchRequested = (
  payload: FetchRequestedAction["payload"]
): FetchRequestedAction => ({
  type: ProductServiceBeautyCenterSettingActionTypes.FETCH_REQUESTED,
  payload,
});

export const fetchSucceeded = (
  payload: FetchSucceededAction["payload"]
): FetchSucceededAction => ({
  type: ProductServiceBeautyCenterSettingActionTypes.FETCH_SUCCEEDED,
  payload,
});

export const fetchFailed = (
  payload: FetchFailedAction["payload"]
): FetchFailedAction => ({
  type: ProductServiceBeautyCenterSettingActionTypes.FETCH_FAILED,
  payload,
});

// ---- SAGA ACTION ----

export const fetchProductServiceBeautyCenterSettingsSaga = (
  payload: FetchProductServiceBeautyCenterSettingsSagaAction["payload"],
  meta?: FetchProductServiceBeautyCenterSettingsSagaAction["meta"]
): FetchProductServiceBeautyCenterSettingsSagaAction => ({
  type: ProductServiceBeautyCenterSettingActionTypes.FETCH_PRODUCT_SERVICE_BEAUTY_CENTER_SETTINGS_SAGA,
  payload,
  meta,
});

export const createProductServiceBeautyCenterSettingSaga = (
  payload: CreateProductServiceBeautyCenterSettingSagaAction["payload"],
  meta?: CreateProductServiceBeautyCenterSettingSagaAction["meta"]
): CreateProductServiceBeautyCenterSettingSagaAction => ({
  type: ProductServiceBeautyCenterSettingActionTypes.CREATE_PRODUCT_SERVICE_BEAUTY_CENTER_SETTING_SAGA,
  payload,
  meta,
});

export const deleteProductServiceBeautyCentersSettingSaga = (
  payload: DeleteProductServiceBeautyCenterSettingsSagaAction["payload"],
  meta?: DeleteProductServiceBeautyCenterSettingsSagaAction["meta"]
): DeleteProductServiceBeautyCenterSettingsSagaAction => ({
  type: ProductServiceBeautyCenterSettingActionTypes.DELETE_PRODUCT_SERVICE_BEAUTY_CENTER_SETTINGS_SAGA,
  payload,
  meta,
});
