import { authAxios } from "@/libs/axios";

import type {
  FetchEmailsPayload,
  FetchEmailsResponseData,
  CreateEmailPayload,
  DeleteEmailPayload,
} from "./email.api.types";
import type { AxiosResponseData } from "@/libs/axios";

const emailApi = {
  fetchEmails: (payload?: FetchEmailsPayload) => {
    return authAxios.get<AxiosResponseData<FetchEmailsResponseData>>(
      "settings/emails",
      {
        params: payload?.params,
        cancelToken: payload?.cancelToken,
      }
    );
  },
  createEmail: (payload: CreateEmailPayload) => {
    return authAxios.post<AxiosResponseData>(
      "settings/emails",
      payload.params,
      {
        cancelToken: payload?.cancelToken,
      }
    );
  },
  deleteEmail: (payload: DeleteEmailPayload) => {
    const { id } = payload.params;
    return authAxios.delete<AxiosResponseData>(`settings/emails/${id}`, {
      cancelToken: payload?.cancelToken,
    });
  },
};

export default emailApi;
