import { CommonActionTypes } from "./types";

import type { CommonState, CommonAction } from "./types";

export const initialState: CommonState = {
  adminSidebarCollapseOpened: false,
  floatAdminSidebarOpened: false,
  firebaseNotificationFcmToken: "",
};

const reducer = (state = initialState, action: CommonAction): CommonState => {
  switch (action.type) {
    case CommonActionTypes.SET_ADMIN_SIDEBAR_COLLAPSE_OPENED: {
      return {
        ...state,
        adminSidebarCollapseOpened: action.payload,
      };
    }

    case CommonActionTypes.SET_FLOAT_ADMIN_SIDEBAR_OPENED: {
      return {
        ...state,
        floatAdminSidebarOpened: action.payload,
      };
    }

    case CommonActionTypes.REGISTER_FIREBASE_NOTIFICATION_DEVICE_SUCCEEDED: {
      return {
        ...state,
        firebaseNotificationFcmToken: action.payload.fcm_token,
      };
    }

    case CommonActionTypes.REGISTER_FIREBASE_NOTIFICATION_DEVICE_REQUESTED: {
      return {
        ...state,
        firebaseNotificationFcmToken: action.payload.fcm_token,
      };
    }

    default: {
      return state;
    }
  }
};

export default reducer;
