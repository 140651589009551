import { SmsLogActionTypes } from "./types";
import type {
  FetchRequestedAction,
  FetchSucceededAction,
  FetchFailedAction,
  // Saga
  FetchSmsLogsSagaAction,
  ResendSmsSagaAction,
} from "./types";

// ---- REDUCER ACTION ----

export const fetchRequested = (
  payload: FetchRequestedAction["payload"]
): FetchRequestedAction => ({
  type: SmsLogActionTypes.FETCH_REQUESTED,
  payload,
});

export const fetchSucceeded = (
  payload: FetchSucceededAction["payload"]
): FetchSucceededAction => ({
  type: SmsLogActionTypes.FETCH_SUCCEEDED,
  payload,
});

export const fetchFailed = (
  payload: FetchFailedAction["payload"]
): FetchFailedAction => ({
  type: SmsLogActionTypes.FETCH_FAILED,
  payload,
});

// ---- SAGA ACTION ----

export const fetchSmsLogsSaga = (
  payload?: FetchSmsLogsSagaAction["payload"],
  meta?: FetchSmsLogsSagaAction["meta"]
): FetchSmsLogsSagaAction => ({
  type: SmsLogActionTypes.FETCH_SMS_LOGS_SAGA,
  payload,
  meta,
});

export const resendSmsSaga = (
  payload: ResendSmsSagaAction["payload"],
  meta?: ResendSmsSagaAction["meta"]
): ResendSmsSagaAction => ({
  type: SmsLogActionTypes.RESEND_SMS_SAGA,
  payload,
  meta,
});
