export const DEFAULT_RECONFIRM_SMS_TEMPLATE = `你好 Dear 🥰 提提你的預約喔~ 我地已經幫你安排好房間和師傅 🥰 如需更改時間或取消請通知

日期 : <appointment_date>
時間 : <appointment_time>
優惠 : <product_service>
公司 CODE : <appointment_id>
地址 : <beauty_center>
預約詳情:
<confirm_url>

期待你的來臨☺️`;

export const DEFAULT_BOOKING_SMS_TEMPLATE = `⭐ 登記已完成

日期 : <appointment_date>
時間 : <appointment_time>
優惠 : <product_service>
地址 : <beauty_center>
更多預約需知詳情:
https://www.smartbeautyhk.com/notes

再次多謝你參加本公司之優惠，希望你會滿意本公司的服務🥰`;

export const templateParamDescriptions = [
  {
    label: "<appointment_date>",
    description: "theAppointmentDate",
  },
  {
    label: "<appointment_time>",
    description: "theAppointmentTime",
  },
  {
    label: "<product_service>",
    description: "theProductService",
  },
  {
    label: "<appointment_id>",
    description: "theAppointmentCode",
  },
  {
    label: "<beauty_center>",
    description: "theBeautyCenterName",
  },
  {
    label: "<confirm_url>",
    description: "confirmationUrl_text",
  },
];

export const DEFAULT_WHATSAPP_TEMPLATE_RESCHEDULE_WITH_TIMES_EN = `My appointment code: <code>. I would like to reschedule the dates and times below:
<dates>
Please kindly help me review the schedule. Thanks.`;
export const DEFAULT_WHATSAPP_TEMPLATE_RESCHEDULE_WITHOUT_TIME_EN = `My appointment code: <code>. I don’t have a schedule. Please kindly help contact me to discuss. Thanks.`;

export const DEFAULT_WHATSAPP_TEMPLATE_RESCHEDULE_WITH_TIMES_HK = `我的預約編號：<code>。我想重新安排以下日期和時間：
<dates>
請幫忙檢查一下時間表。謝謝。`;
export const DEFAULT_WHATSAPP_TEMPLATE_RESCHEDULE_WITHOUT_TIME_HK = `我的預約編號：<code>。我沒有日程安排。請幫忙聯絡我以進行討論。謝謝。`;

export const DEFAULT_WHATSAPP_TEMPLATE_RESCHEDULE_WITH_TIMES_CN = `我的预约编号：<code>。我想重新安排以下日期和时间：
<dates>
请帮忙检查一下时间表。谢谢。`;
export const DEFAULT_WHATSAPP_TEMPLATE_RESCHEDULE_WITHOUT_TIME_CN = `我的预约编号：<code>。我没有日程安排。请帮忙联系我以进行讨论。谢谢。`;

export const whatsappTemplateParamDescriptions = [
  {
    label: "<code>",
    description: "appointmentCode",
  },
  {
    label: "<dates>",
    description: "rescheduledDates",
  },
];
