import { createSelector } from "@reduxjs/toolkit";

import type { AppState } from "@/store";

export const selectUnreadNotifications = (state: AppState) =>
  state.notification.unreadNotifications;

export const selectReadNotifications = (state: AppState) =>
  state.notification.readNotifications;

export const selectNotificationCountItem = (state: AppState) =>
  state.notification.notificationCountItem;

export const selectFullUnreadNotifications = createSelector(
  selectUnreadNotifications,
  (unreadNotifications) => {
    const newUnreadNotifications = [] as (Omit<
      (typeof unreadNotifications)[number],
      "data"
    > & {
      beauty_center: {
        id: number;
        name_en: string;
        name_hk: string;
        name_cn: string;
      } | null;
      appointment: {
        id: number;
        reference_id: string;
        order_type: string;
      } | null;
    })[];

    unreadNotifications.forEach((unreadNotification) => {
      let beautyCenter: any;
      let appointment: any;
      try {
        beautyCenter = JSON.parse(unreadNotification.data.beauty_center);
      } catch {}
      try {
        appointment = JSON.parse(unreadNotification.data.appointment);
      } catch {}
      newUnreadNotifications.push({
        id: unreadNotification.id,
        is_read: unreadNotification.is_read,
        type: unreadNotification.type,
        appointment,
        beauty_center: beautyCenter,
        created_at: unreadNotification.created_at,
      });
    });

    return newUnreadNotifications;
  }
);

export const selectFullReadNotifications = createSelector(
  selectReadNotifications,
  (readNotifications) => {
    const newReadNotifications = [] as (Omit<
      (typeof readNotifications)[number],
      "data"
    > & {
      beauty_center: {
        id: number;
        name_en: string;
        name_hk: string;
        name_cn: string;
      } | null;
      appointment: {
        id: number;
        reference_id: string;
        order_type: string;
      } | null;
    })[];

    readNotifications.forEach((readNotification) => {
      let beautyCenter: any;
      let appointment: any;
      try {
        beautyCenter = JSON.parse(readNotification.data.beauty_center);
      } catch {}
      try {
        appointment = JSON.parse(readNotification.data.appointment);
      } catch {}
      newReadNotifications.push({
        id: readNotification.id,
        is_read: readNotification.is_read,
        type: readNotification.type,
        appointment,
        beauty_center: beautyCenter,
        created_at: readNotification.created_at,
      });
    });

    return newReadNotifications;
  }
);
