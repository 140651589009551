import { PermissionActionTypes } from "./types";
import type {
  FetchRequestedAction,
  FetchSucceededAction,
  FetchFailedAction,
  // Saga
  FetchPermissionsSagaAction,
} from "./types";

// ---- REDUCER ACTION ----

export const fetchRequested = (
  payload: FetchRequestedAction["payload"]
): FetchRequestedAction => ({
  type: PermissionActionTypes.FETCH_REQUESTED,
  payload,
});

export const fetchSucceeded = (
  payload: FetchSucceededAction["payload"]
): FetchSucceededAction => ({
  type: PermissionActionTypes.FETCH_SUCCEEDED,
  payload,
});

export const fetchFailed = (
  payload: FetchFailedAction["payload"]
): FetchFailedAction => ({
  type: PermissionActionTypes.FETCH_FAILED,
  payload,
});

// ---- SAGA ACTION ----

export const fetchPermissionsSaga = (
  payload?: FetchPermissionsSagaAction["payload"],
  meta?: FetchPermissionsSagaAction["meta"]
): FetchPermissionsSagaAction => ({
  type: PermissionActionTypes.FETCH_PERMISSIONS_SAGA,
  payload,
  meta,
});
