import { GradeLevelActionTypes } from "./types";
import type {
  FetchRequestedAction,
  FetchSucceededAction,
  FetchFailedAction,
  FetchGradeLevelSucceededServerAction,
  UpdateGradeLevelEnabledSucceededAction,
  // Saga
  FetchPrimaryGradeLevelsSagaAction,
  FetchSecondaryGradeLevelsSagaAction,
  UpdateGradeLevelEnabledSagaAction,
  CreateGradeLevelSagaAction,
  UpdateGradeLevelSagaAction,
  DeleteGradeLevelSagaAction,
} from "./types";

// ---- REDUCER ACTION ----

export const fetchRequested = (
  payload: FetchRequestedAction["payload"]
): FetchRequestedAction => ({
  type: GradeLevelActionTypes.FETCH_REQUESTED,
  payload,
});

export const fetchSucceeded = (
  payload: FetchSucceededAction["payload"]
): FetchSucceededAction => ({
  type: GradeLevelActionTypes.FETCH_SUCCEEDED,
  payload,
});

export const fetchFailed = (
  payload: FetchFailedAction["payload"]
): FetchFailedAction => ({
  type: GradeLevelActionTypes.FETCH_FAILED,
  payload,
});

export const fetchGradeLevelSucceededServer = (
  payload: FetchGradeLevelSucceededServerAction["payload"]
): FetchGradeLevelSucceededServerAction => ({
  type: GradeLevelActionTypes.FETCH_GRADE_LEVEL_SUCCEEDED_SERVER,
  payload,
});

export const updateGradeLevelEnabledSucceeded = (
  payload: UpdateGradeLevelEnabledSucceededAction["payload"]
): UpdateGradeLevelEnabledSucceededAction => ({
  type: GradeLevelActionTypes.UPDATE_GRADE_LEVEL_ENABLED_SUCCEEDED,
  payload,
});

// ---- SAGA ACTION ----

export const fetchPrimaryGradeLevelsSaga = (
  payload?: FetchPrimaryGradeLevelsSagaAction["payload"],
  meta?: FetchPrimaryGradeLevelsSagaAction["meta"]
): FetchPrimaryGradeLevelsSagaAction => ({
  type: GradeLevelActionTypes.FETCH_PRIMARY_GRADE_LEVELS_SAGA,
  payload,
  meta,
});

export const fetchSecondaryGradeLevelsSaga = (
  payload?: FetchSecondaryGradeLevelsSagaAction["payload"],
  meta?: FetchSecondaryGradeLevelsSagaAction["meta"]
): FetchSecondaryGradeLevelsSagaAction => ({
  type: GradeLevelActionTypes.FETCH_SECONDARY_GRADE_LEVELS_SAGA,
  payload,
  meta,
});

export const updateGradeLevelEnabledSaga = (
  payload: UpdateGradeLevelEnabledSagaAction["payload"],
  meta?: UpdateGradeLevelEnabledSagaAction["meta"]
): UpdateGradeLevelEnabledSagaAction => ({
  type: GradeLevelActionTypes.UPDATE_GRADE_LEVEL_ENABLED_SAGA,
  payload,
  meta,
});

export const updateGradeLevelSaga = (
  payload: UpdateGradeLevelSagaAction["payload"],
  meta?: UpdateGradeLevelSagaAction["meta"]
): UpdateGradeLevelSagaAction => ({
  type: GradeLevelActionTypes.UPDATE_GRADE_LEVEL_SAGA,
  payload,
  meta,
});

export const createGradeLevelSaga = (
  payload: CreateGradeLevelSagaAction["payload"],
  meta?: CreateGradeLevelSagaAction["meta"]
): CreateGradeLevelSagaAction => ({
  type: GradeLevelActionTypes.CREATE_GRADE_LEVEL_SAGA,
  payload,
  meta,
});

export const deleteGradeLevelSaga = (
  payload: DeleteGradeLevelSagaAction["payload"],
  meta?: DeleteGradeLevelSagaAction["meta"]
): DeleteGradeLevelSagaAction => ({
  type: GradeLevelActionTypes.DELETE_GRADE_LEVEL_SAGA,
  payload,
  meta,
});
