import { all, fork } from "redux-saga/effects";

import auth from "./auth/sagas";
import adminUser from "./adminUser/sagas";
import role from "./role/sagas";
import permission from "./permission/sagas";
import beautyCenter from "./beautyCenter/sagas";
import district from "./district/sagas";
import staff from "./staff/sagas";
import productService from "./productService/sagas";
import category from "./category/sagas";
import gradeLevel from "./gradeLevel/sagas";
import platformCode from "./platformCode/sagas";
import customer from "./customer/sagas";
import appointment from "./appointment/sagas";
import appointmentStatus from "./appointmentStatus/sagas";
import appointmentLog from "./appointmentLog/sagas";
import adminActionLog from "./adminActionLog/sagas";
import email from "./email/sagas";
import smsLog from "./smsLog/sagas";
import productServiceBeautyCenterSetting from "./productServiceBeautyCenterSetting/sagas";
import beautyCenterProductServiceSetting from "./beautyCenterProductServiceSetting/sagas";
import appointmentAutoAssignedBeautyCenterGroup from "./appointmentAutoAssignedBeautyCenterGroup/sagas";
import appointmentRescheduledRequest from "./appointmentRescheduledRequest/sagas";
import notification from "./notification/sagas";
import common from "./common/sagas";
import report from "./report/sagas";

export default function* rootSaga() {
  yield all([
    fork(auth),
    fork(adminUser),
    fork(role),
    fork(permission),
    fork(beautyCenter),
    fork(district),
    fork(staff),
    fork(productService),
    fork(category),
    fork(gradeLevel),
    fork(platformCode),
    fork(customer),
    fork(appointment),
    fork(appointmentStatus),
    fork(appointmentLog),
    fork(adminActionLog),
    fork(email),
    fork(smsLog),
    fork(productServiceBeautyCenterSetting),
    fork(beautyCenterProductServiceSetting),
    fork(appointmentAutoAssignedBeautyCenterGroup),
    fork(appointmentRescheduledRequest),
    fork(notification),
    fork(common),
    fork(report),
  ]);
}
