import { authAxios } from "@/libs/axios";

import type {
  DeleteBeautyCenterProductServiceSettingsPayload,
  FetchBeautyCenterProductServiceSettingsResponseData,
  FetchBeautyCenterProductServiceSettingsPayload,
  CreateBeautyCenterProductServiceSettingPayload,
  FetchOptionBeautyCenterProductServiceSettingsPayload,
  FetchOptionBeautyCenterProductServiceSettingsResponseData,
  DownloadBeautyCenterProductServiceSettingsPayload,
} from "./beautyCenterProductServiceSetting.api.types";
import type { AxiosResponseData } from "@/libs/axios";

const beautyCenterProductServiceSettingApi = {
  fetchBeautyCenterProductServiceSettings: (
    payload: FetchBeautyCenterProductServiceSettingsPayload
  ) => {
    const { beauty_center_id, ...params } = payload.params;

    return authAxios.get<
      AxiosResponseData<FetchBeautyCenterProductServiceSettingsResponseData>
    >(
      `beauty_centers_product_services/beauty_centers/${beauty_center_id}/product_services`,
      {
        params,
        cancelToken: payload?.cancelToken,
      }
    );
  },
  fetchOptionBeautyCenterProductServiceSettings: (
    payload: FetchOptionBeautyCenterProductServiceSettingsPayload
  ) => {
    const { beauty_center_id, ...params } = payload.params;

    return authAxios.get<
      AxiosResponseData<FetchOptionBeautyCenterProductServiceSettingsResponseData>
    >(
      `beauty_centers_product_services/beauty_centers/${beauty_center_id}/product_services/select_list`,
      {
        params,
        cancelToken: payload?.cancelToken,
      }
    );
  },
  downloadBeautyCenterProductServiceSettings: (
    payload: DownloadBeautyCenterProductServiceSettingsPayload
  ) => {
    const { beauty_center_id } = payload.params;
    return authAxios.get<Blob>(
      `beauty_centers_product_services/beauty_centers/${beauty_center_id}/export`,
      {
        cancelToken: payload?.cancelToken,
        responseType: "blob",
        headers: {
          "Content-Type": "blob",
        },
      }
    );
  },
  createBeautyCenterProductServiceSetting: (
    payload: CreateBeautyCenterProductServiceSettingPayload
  ) => {
    const { beauty_center_id, ...params } = payload.params;

    return authAxios.put<AxiosResponseData>(
      `beauty_centers_product_services/beauty_centers/${beauty_center_id}/product_services/link`,
      params,
      {
        cancelToken: payload?.cancelToken,
      }
    );
  },
  deleteBeautyCenterProductServiceSettings: (
    payload: DeleteBeautyCenterProductServiceSettingsPayload
  ) => {
    const { beauty_center_id, ...params } = payload.params;

    return authAxios.put<AxiosResponseData>(
      `beauty_centers_product_services/beauty_centers/${beauty_center_id}/product_services/unlink`,
      params,
      {
        cancelToken: payload?.cancelToken,
      }
    );
  },
};

export default beautyCenterProductServiceSettingApi;
