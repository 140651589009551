import { authAxios } from "@/libs/axios";

import type {
  FetchBeautyCentersPayload,
  FetchBeautyCentersResponseData,
  FetchOptionBeautyCentersPayload,
  FetchOptionBeautyCentersResponseData,
  CreateBeautyCenterPayload,
  UpdateBeautyCenterPayload,
  UpdateBeautyCenterEnabledPayload,
  DeleteBeautyCenterPayload,
  FetchBeautyCenterPayload,
  FetchBeautyCenterResponseData,
  DownloadBeautyCentersPayload,
} from "./beautyCenter.api.types";
import type { AxiosResponseData } from "@/libs/axios";

const beautyCenterApi = {
  fetchBeautyCenters: (payload?: FetchBeautyCentersPayload) => {
    return authAxios.get<AxiosResponseData<FetchBeautyCentersResponseData>>(
      "beauty_centers",
      {
        params: payload?.params,
        cancelToken: payload?.cancelToken,
      }
    );
  },
  fetchOptionBeautyCenters: (payload?: FetchOptionBeautyCentersPayload) => {
    return authAxios.get<
      AxiosResponseData<FetchOptionBeautyCentersResponseData>
    >("beauty_centers/select_list", {
      params: payload?.params,
      cancelToken: payload?.cancelToken,
    });
  },
  fetchBeautyCenter: (payload: FetchBeautyCenterPayload) => {
    const { id, ...params } = payload.params;
    return authAxios.get<AxiosResponseData<FetchBeautyCenterResponseData>>(
      `beauty_centers/${id}`,
      {
        params: params,
        cancelToken: payload?.cancelToken,
        headers: payload.headers,
      }
    );
  },
  createBeautyCenter: (payload: CreateBeautyCenterPayload) => {
    return authAxios.post<AxiosResponseData>("beauty_centers", payload.params, {
      cancelToken: payload?.cancelToken,
    });
  },
  updateBeautyCenter: (payload: UpdateBeautyCenterPayload) => {
    const { id, ...params } = payload.params;
    return authAxios.put<AxiosResponseData>(`beauty_centers/${id}`, params, {
      cancelToken: payload?.cancelToken,
    });
  },
  updateBeautyCenterEnabled: (payload: UpdateBeautyCenterEnabledPayload) => {
    const { id, ...params } = payload.params;
    return authAxios.patch<AxiosResponseData>(
      `beauty_centers/enable/${id}`,
      params,
      {
        cancelToken: payload?.cancelToken,
      }
    );
  },
  deleteBeautyCenter: (payload: DeleteBeautyCenterPayload) => {
    const { id } = payload.params;
    return authAxios.delete<AxiosResponseData>(`beauty_centers/${id}`, {
      cancelToken: payload?.cancelToken,
    });
  },
  downloadBeautyCenters: (payload?: DownloadBeautyCentersPayload) => {
    return authAxios.get<Blob>("beauty_centers/export", {
      params: payload?.params,
      cancelToken: payload?.cancelToken,
      responseType: "blob",
    });
  },
};

export default beautyCenterApi;
