import type {
  FetchDistrictsPayload,
  FetchDistrictsResponseData,
} from "@/utils/apis/district";

export enum DistrictActionTypes {
  FETCH_REQUESTED = "@@district/FETCH_REQUESTED",
  FETCH_SUCCEEDED = "@@district/FETCH_SUCCEEDED",
  FETCH_FAILED = "@@district/FETCH_FAILED",

  // Saga
  FETCH_DISTRICTS_SAGA = "@@district/FETCH_DISTRICTS_SAGA",
}

// State

export type DistrictTree = FetchDistrictsResponseData["data"][number] & {
  is_last_item: boolean;
  sub_districts: DistrictTree[];
  sub_district_ids: number[];
};

export interface DistrictState {
  districts: FetchDistrictsResponseData["data"];
  districtsLoading: boolean;
  districtsError: string;
}

// ---- Reducer Action ----

export type FetchScope = "districts";

export type FetchRequestedAction = {
  type: DistrictActionTypes.FETCH_REQUESTED;
  payload: {
    scope: FetchScope;
    isReset?: boolean;
  };
};

export type FetchSucceededAction = {
  type: DistrictActionTypes.FETCH_SUCCEEDED;
  payload: {
    scope: FetchScope;
    data: DistrictState[FetchScope];
    count?: number;
    isLoadMore?: boolean;
  };
};

export type FetchFailedAction = {
  type: DistrictActionTypes.FETCH_FAILED;
  payload: {
    scope: FetchScope;
    error: string;
  };
};

// ---- Saga Action ----

export type FetchDistrictsSagaAction = {
  type: DistrictActionTypes.FETCH_DISTRICTS_SAGA;
  payload?: FetchDistrictsPayload;
  meta?: {
    isLoadMore?: boolean;
    isReset?: boolean;
    resolve?: (payload?: any) => void;
  };
};

export type DistrictAction =
  | FetchRequestedAction
  | FetchSucceededAction
  | FetchFailedAction
  //
  | FetchDistrictsSagaAction;
