import { CommonActionTypes } from "./types";
import type {
  SetAdminSidebarCollapseOpenedAction,
  SetFloatAdminSidebarOpenedAction,
  RegisterFirebaseNotificationDeviceRequestedAction,
  RegisterFirebaseNotificationDeviceSucceededAction,
  // Saga
  RegisterFirebaseNotificationDeviceSagaAction,
} from "./types";

// ---- REDUCER ACTION ----

export const setAdminSidebarCollapseOpened = (
  payload: SetAdminSidebarCollapseOpenedAction["payload"]
): SetAdminSidebarCollapseOpenedAction => ({
  type: CommonActionTypes.SET_ADMIN_SIDEBAR_COLLAPSE_OPENED,
  payload,
});

export const setFloatAdminSidebarOpened = (
  payload: SetFloatAdminSidebarOpenedAction["payload"]
): SetFloatAdminSidebarOpenedAction => ({
  type: CommonActionTypes.SET_FLOAT_ADMIN_SIDEBAR_OPENED,
  payload,
});

export const registerFirebaseNotificationDeviceSucceeded = (
  payload: RegisterFirebaseNotificationDeviceSucceededAction["payload"]
): RegisterFirebaseNotificationDeviceSucceededAction => ({
  type: CommonActionTypes.REGISTER_FIREBASE_NOTIFICATION_DEVICE_SUCCEEDED,
  payload,
});

export const registerFirebaseNotificationDeviceRequested = (
  payload: RegisterFirebaseNotificationDeviceRequestedAction["payload"]
): RegisterFirebaseNotificationDeviceRequestedAction => ({
  type: CommonActionTypes.REGISTER_FIREBASE_NOTIFICATION_DEVICE_REQUESTED,
  payload,
});

// ---- SAGA ACTION ----

export const registerFirebaseNotificationDeviceSaga = (
  payload: RegisterFirebaseNotificationDeviceSagaAction["payload"],
  meta?: RegisterFirebaseNotificationDeviceSagaAction["meta"]
): RegisterFirebaseNotificationDeviceSagaAction => ({
  type: CommonActionTypes.REGISTER_FIREBASE_NOTIFICATION_DEVICE_SAGA,
  payload,
  meta,
});
