export enum NotificationType {
  PUBLISHED = "published",
  ASSIGNED_TO_BEAUTY_CENTER_BY_SYSTEM = "assigned_to_beauty_center_by_system",
  ASSIGNED_TIMEOUT_BY_SYSTEM = "assigned_timeout_by_system",
  ASSIGNED_NOT_FOUND_BY_SYSTEM = "assigned_not_found_by_system",
  DECLINED = "declined",
  ACCEPTED = "accepted",
  CHECKED_IN_BY_BEAUTY_CENTER = "checked_in_by_beauty_center",
  CHECKED_IN_BY_SYSTEM = "checked_in_by_system",
  ABSENCE = "absence",
  ASSIGNED_TO_BEAUTY_CENTER_BY_ADMIN = "assigned_to_beauty_center_by_admin",
  CONFIRMED_BY_CUSTOMER = "confirmed_by_customer",
  RESCHEDULED_BY_CUSTOMER = "rescheduled_by_customer",
  RESCHEDULED_BY_BEAUTY_CENTER = "rescheduled_by_beauty_center",
  CANCELLED_BY_ADMIN = "canceled_by_admin",
  CANCELLED_BY_CUSTOMER = "canceled_by_customer",
  MAILED_TO_ADMIN = "mailed_to_admin",
  SENT_SMS_TO_CUSTOMER = "sent_sms_to_customer",
}
