import { districtHelpers } from "@/utils/helpers";
import { createSelector } from "@reduxjs/toolkit";

import type { AppState } from "@/store";

export const selectDistricts = (state: AppState) => state.district.districts;

export const selectDistrictTrees = (state: AppState) =>
  state.district.districts;

export const selectNestedDistricts = createSelector(
  selectDistricts,
  (districts) => districtHelpers.mapListToNestedList(districts)
);

export const selectIdToDistrictMap = createSelector(
  selectDistricts,
  (districts) => districtHelpers.mapListToIdToItemMap(districts)
);

export const selectIdToDistrictTreeMap = createSelector(
  selectDistricts,
  (districts) => districtHelpers.mapListToIdToTreeItemMap(districts)
);

export const selectBreadcrumbDistricts = createSelector(
  selectIdToDistrictMap,
  (_: AppState, districtId: number | null) => districtId,
  (idToDistrictMap, districtId) => {
    const breadcrumbDistricts = [] as (typeof idToDistrictMap)[number][];
    const addBreadcrumbDistrict = (districtId: number) => {
      const district = idToDistrictMap[districtId];
      if (!!district) {
        breadcrumbDistricts.unshift(district);
        district.parent_id && addBreadcrumbDistrict(district.parent_id);
      }
    };
    districtId && addBreadcrumbDistrict(districtId);
    return breadcrumbDistricts;
  }
);
