import { AdminActionLogActionTypes } from "./types";
import type {
  FetchRequestedAction,
  FetchSucceededAction,
  FetchFailedAction,
  FetchAdminActionLogSucceededServerAction,
  // Saga
  FetchAdminActionLogsSagaAction,
} from "./types";

// ---- REDUCER ACTION ----

export const fetchRequested = (
  payload: FetchRequestedAction["payload"]
): FetchRequestedAction => ({
  type: AdminActionLogActionTypes.FETCH_REQUESTED,
  payload,
});

export const fetchSucceeded = (
  payload: FetchSucceededAction["payload"]
): FetchSucceededAction => ({
  type: AdminActionLogActionTypes.FETCH_SUCCEEDED,
  payload,
});

export const fetchFailed = (
  payload: FetchFailedAction["payload"]
): FetchFailedAction => ({
  type: AdminActionLogActionTypes.FETCH_FAILED,
  payload,
});

export const fetchAdminActionLogSucceededServer = (
  payload: FetchAdminActionLogSucceededServerAction["payload"]
): FetchAdminActionLogSucceededServerAction => ({
  type: AdminActionLogActionTypes.FETCH_ADMIN_ACTION_LOG_SUCCEEDED_SERVER,
  payload,
});

// ---- SAGA ACTION ----

export const fetchAdminActionLogsSaga = (
  payload: FetchAdminActionLogsSagaAction["payload"],
  meta?: FetchAdminActionLogsSagaAction["meta"]
): FetchAdminActionLogsSagaAction => ({
  type: AdminActionLogActionTypes.FETCH_ADMIN_ACTION_LOGS_SAGA,
  payload,
  meta,
});
