import { authAxios } from "@/libs/axios";

import type {
  FetchCategoriesPayload,
  FetchCategoriesResponseData,
  FetchOptionCategoriesPayload,
  FetchOptionCategoriesResponseData,
  CreateCategoryPayload,
  UpdateCategoryPayload,
  UpdateCategoryEnabledPayload,
  DeleteCategoryPayload,
  FetchCategoryPayload,
  FetchCategoryResponseData,
  DownloadCategoriesPayload
} from "./category.api.types";
import type { AxiosResponseData } from "@/libs/axios";

const categoryApi = {
  fetchCategories: (payload?: FetchCategoriesPayload) => {
    return authAxios.get<AxiosResponseData<FetchCategoriesResponseData>>(
      "categories",
      {
        params: payload?.params,
        cancelToken: payload?.cancelToken,
      }
    );
  },
  fetchOptionCategories: (payload?: FetchOptionCategoriesPayload) => {
    return authAxios.get<AxiosResponseData<FetchOptionCategoriesResponseData>>(
      "categories/select_list",
      {
        params: payload?.params,
        cancelToken: payload?.cancelToken,
      }
    );
  },
  fetchCategory: (payload: FetchCategoryPayload) => {
    const { id, ...params } = payload.params;
    return authAxios.get<AxiosResponseData<FetchCategoryResponseData>>(
      `categories/${id}`,
      {
        params: params,
        cancelToken: payload?.cancelToken,
        headers: payload.headers,
      }
    );
  },
  createCategory: (payload: CreateCategoryPayload) => {
    return authAxios.post<AxiosResponseData>("categories", payload.params, {
      cancelToken: payload?.cancelToken,
    });
  },
  updateCategory: (payload: UpdateCategoryPayload) => {
    const { id, ...params } = payload.params;
    return authAxios.put<AxiosResponseData>(`categories/${id}`, params, {
      cancelToken: payload?.cancelToken,
    });
  },
  updateCategoryEnabled: (payload: UpdateCategoryEnabledPayload) => {
    const { id, ...params } = payload.params;
    return authAxios.patch<AxiosResponseData>(
      `categories/enable/${id}`,
      params,
      {
        cancelToken: payload?.cancelToken,
      }
    );
  },
  deleteCategory: (payload: DeleteCategoryPayload) => {
    const { id } = payload.params;
    return authAxios.delete<AxiosResponseData>(`categories/${id}`, {
      cancelToken: payload?.cancelToken,
    });
  },
  downloadCategories: (payload?: DownloadCategoriesPayload) => {
    return authAxios.get<Blob>("categories/export", {
      params: payload?.params,
      cancelToken: payload?.cancelToken,
      responseType: "blob",
    });
  },
};

export default categoryApi;
