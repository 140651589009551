import { authAxios } from "@/libs/axios";

import type {
  UploadFilePayload,
  UploadFileResponseData,
  RegisterFirebaseNotificationDevicePayload,
} from "./common.api.types";
import type { AxiosResponseData } from "@/libs/axios";

const commonApi = {
  uploadFile: (payload: UploadFilePayload) => {
    const { file } = payload.params;
    const fd = new FormData();
    fd.append("file", file);

    return authAxios.post<AxiosResponseData<UploadFileResponseData>>(
      "files/upload",
      fd,
      {
        cancelToken: payload?.cancelToken,
      }
    );
  },
  registerFirebaseNotificationDevice: (
    payload: RegisterFirebaseNotificationDevicePayload
  ) => {
    return authAxios.post<AxiosResponseData>(
      "admin_device/sync",
      payload.params,
      {
        cancelToken: payload?.cancelToken,
      }
    );
  },
};

export default commonApi;
