import type {
  FetchGradeLevelsPayload,
  FetchGradeLevelsResponseData,
  FetchGradeLevelResponseData,
  UpdateGradeLevelEnabledPayload,
  DeleteGradeLevelPayload,
  UpdateGradeLevelPayload,
  CreateGradeLevelPayload,
} from "@/utils/apis/gradeLevel";

export enum GradeLevelActionTypes {
  FETCH_REQUESTED = "@@gradeLevel/FETCH_REQUESTED",
  FETCH_SUCCEEDED = "@@gradeLevel/FETCH_SUCCEEDED",
  FETCH_FAILED = "@@gradeLevel/FETCH_FAILED",

  UPDATE_GRADE_LEVEL_ENABLED_SUCCEEDED = "@@gradeLevel/UPDATE_GRADE_LEVEL_ENABLED_SUCCEEDED",

  FETCH_GRADE_LEVEL_SUCCEEDED_SERVER = "@@gradeLevel/FETCH_GRADE_LEVEL_SUCCEEDED_SERVER",

  // Saga
  FETCH_PRIMARY_GRADE_LEVELS_SAGA = "@@gradeLevel/FETCH_PRIMARY_GRADE_LEVELS_SAGA",
  FETCH_SECONDARY_GRADE_LEVELS_SAGA = "@@gradeLevel/FETCH_SECONDARY_GRADE_LEVELS_SAGA",
  UPDATE_GRADE_LEVEL_ENABLED_SAGA = "@@gradeLevel/UPDATE_GRADE_LEVEL_ENABLED_SAGA",
  UPDATE_GRADE_LEVEL_SAGA = "@@gradeLevel/UPDATE_GRADE_LEVEL_SAGA",
  CREATE_GRADE_LEVEL_SAGA = "@@gradeLevel/CREATE_GRADE_LEVEL_SAGA",
  DELETE_GRADE_LEVEL_SAGA = "@@gradeLevel/DELETE_GRADE_LEVEL_SAGA",
}

// State

export interface GradeLevelState {
  hydrated?: boolean;

  secondaryGradeLevels: FetchGradeLevelsResponseData["data"];
  secondaryGradeLevelsLoading: boolean;
  secondaryGradeLevelsError: string;
  secondaryGradeLevelsCount: number;

  primaryGradeLevels: FetchGradeLevelsResponseData["data"];
  primaryGradeLevelsLoading: boolean;
  primaryGradeLevelsError: string;
  primaryGradeLevelsCount: number;

  gradeLevel: FetchGradeLevelResponseData["data"] | null;
  gradeLevelLoading: boolean;
  gradeLevelError: string;
}

// ---- Reducer Action ----

export type FetchScope =
  | "primaryGradeLevels"
  | "secondaryGradeLevels"
  | "gradeLevel";

export type FetchRequestedAction = {
  type: GradeLevelActionTypes.FETCH_REQUESTED;
  payload: {
    scope: FetchScope;
    isReset?: boolean;
  };
};

export type FetchSucceededAction = {
  type: GradeLevelActionTypes.FETCH_SUCCEEDED;
  payload: {
    scope: FetchScope;
    data: GradeLevelState[FetchScope];
    count?: number;
    isLoadMore?: boolean;
  };
};

export type FetchFailedAction = {
  type: GradeLevelActionTypes.FETCH_FAILED;
  payload: {
    scope: FetchScope;
    error: string;
  };
};

export type FetchGradeLevelSucceededServerAction = {
  type: GradeLevelActionTypes.FETCH_GRADE_LEVEL_SUCCEEDED_SERVER;
  payload: FetchGradeLevelResponseData["data"] | null;
};

export type UpdateGradeLevelEnabledSucceededAction = {
  type: GradeLevelActionTypes.UPDATE_GRADE_LEVEL_ENABLED_SUCCEEDED;
  payload: {
    id: number;
    is_enabled: BooleanNumber;
  };
  meta?: {
    resolve?: (payload?: any) => void;
  };
};

// ---- Saga Action ----

export type FetchPrimaryGradeLevelsSagaAction = {
  type: GradeLevelActionTypes.FETCH_PRIMARY_GRADE_LEVELS_SAGA;
  payload?: Pick<FetchGradeLevelsPayload, "cancelToken"> & {
    params?: Partial<
      Omit<NonNullable<FetchGradeLevelsPayload["params"]>, "filters">
    > & {
      filters?: Partial<
        Omit<
          NonNullable<
            NonNullable<FetchGradeLevelsPayload["params"]>["filters"]
          >,
          "level"
        >
      >;
    };
  };
  meta?: {
    isLoadMore?: boolean;
    isReset?: boolean;
    resolve?: (payload?: any) => void;
  };
};

export type FetchSecondaryGradeLevelsSagaAction = {
  type: GradeLevelActionTypes.FETCH_SECONDARY_GRADE_LEVELS_SAGA;
  payload?: Pick<FetchGradeLevelsPayload, "cancelToken"> & {
    params?: Partial<
      Omit<NonNullable<FetchGradeLevelsPayload["params"]>, "filters">
    > & {
      filters?: Partial<
        Omit<
          NonNullable<
            NonNullable<FetchGradeLevelsPayload["params"]>["filters"]
          >,
          "level"
        >
      >;
    };
  };
  meta?: {
    isLoadMore?: boolean;
    isReset?: boolean;
    resolve?: (payload?: any) => void;
  };
};

export type UpdateGradeLevelEnabledSagaAction = {
  type: GradeLevelActionTypes.UPDATE_GRADE_LEVEL_ENABLED_SAGA;
  payload: UpdateGradeLevelEnabledPayload;
  meta?: {
    resolve?: (payload?: any) => void;
  };
};

export type UpdateGradeLevelSagaAction = {
  type: GradeLevelActionTypes.UPDATE_GRADE_LEVEL_SAGA;
  payload: UpdateGradeLevelPayload;
  meta?: {
    resolve?: (payload?: any) => void;
  };
};

export type CreateGradeLevelSagaAction = {
  type: GradeLevelActionTypes.CREATE_GRADE_LEVEL_SAGA;
  payload: CreateGradeLevelPayload;
  meta?: {
    resolve?: (payload?: any) => void;
  };
};

export type DeleteGradeLevelSagaAction = {
  type: GradeLevelActionTypes.DELETE_GRADE_LEVEL_SAGA;
  payload: DeleteGradeLevelPayload;
  meta?: {
    resolve?: (payload?: any) => void;
  };
};

export type GradeLevelAction =
  | FetchRequestedAction
  | FetchSucceededAction
  | FetchFailedAction
  | FetchGradeLevelSucceededServerAction
  | UpdateGradeLevelEnabledSucceededAction
  //
  | FetchPrimaryGradeLevelsSagaAction
  | FetchSecondaryGradeLevelsSagaAction
  | UpdateGradeLevelEnabledSagaAction
  | UpdateGradeLevelSagaAction
  | CreateGradeLevelSagaAction
  | DeleteGradeLevelSagaAction;
