import type {
  FetchEmailsPayload,
  FetchEmailsResponseData,
  DeleteEmailPayload,
  CreateEmailPayload,
} from "@/utils/apis/email";

export enum EmailActionTypes {
  FETCH_REQUESTED = "@@email/FETCH_REQUESTED",
  FETCH_SUCCEEDED = "@@email/FETCH_SUCCEEDED",
  FETCH_FAILED = "@@email/FETCH_FAILED",

  // Saga
  FETCH_EMAILS_SAGA = "@@email/FETCH_EMAILS_SAGA",
  CREATE_EMAIL_SAGA = "@@email/CREATE_EMAIL_SAGA",
  DELETE_EMAIL_SAGA = "@@email/DELETE_EMAIL_SAGA",
}

// State

export interface EmailState {
  emails: FetchEmailsResponseData["data"];
  emailsLoading: boolean;
  emailsError: string;
  emailsCount: number;
}

// ---- Reducer Action ----

export type FetchScope = "emails";

export type FetchRequestedAction = {
  type: EmailActionTypes.FETCH_REQUESTED;
  payload: {
    scope: FetchScope;
    isReset?: boolean;
  };
};

export type FetchSucceededAction = {
  type: EmailActionTypes.FETCH_SUCCEEDED;
  payload: {
    scope: FetchScope;
    data: EmailState[FetchScope];
    count?: number;
    isLoadMore?: boolean;
  };
};

export type FetchFailedAction = {
  type: EmailActionTypes.FETCH_FAILED;
  payload: {
    scope: FetchScope;
    error: string;
  };
};

// ---- Saga Action ----

export type FetchEmailsSagaAction = {
  type: EmailActionTypes.FETCH_EMAILS_SAGA;
  payload?: FetchEmailsPayload;
  meta?: {
    isLoadMore?: boolean;
    isReset?: boolean;
    resolve?: (payload?: any) => void;
  };
};

export type CreateEmailSagaAction = {
  type: EmailActionTypes.CREATE_EMAIL_SAGA;
  payload: CreateEmailPayload;
  meta?: {
    resolve?: (payload?: any) => void;
  };
};

export type DeleteEmailSagaAction = {
  type: EmailActionTypes.DELETE_EMAIL_SAGA;
  payload: DeleteEmailPayload;
  meta?: {
    resolve?: (payload?: any) => void;
  };
};

export type EmailAction =
  | FetchRequestedAction
  | FetchSucceededAction
  | FetchFailedAction
  //
  | FetchEmailsSagaAction
  | CreateEmailSagaAction
  | DeleteEmailSagaAction;
