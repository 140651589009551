import { AppointmentActionTypes } from "./types";
import { commonHelpers } from "@/utils/helpers";
import { HYDRATE } from "next-redux-wrapper";

import type { AppointmentState, AppointmentAction } from "./types";

export const initialState: AppointmentState = {
  draftAppointments: [],
  draftAppointmentsLoading: true,
  draftAppointmentsError: "",
  draftAppointmentsCount: 0,

  processingAssignAppointments: [],
  processingAssignAppointmentsLoading: true,
  processingAssignAppointmentsError: "",
  processingAssignAppointmentsCount: 0,

  rescheduledAppointments: [],
  rescheduledAppointmentsLoading: true,
  rescheduledAppointmentsError: "",
  rescheduledAppointmentsCount: 0,

  acceptedAppointments: [],
  acceptedAppointmentsLoading: true,
  acceptedAppointmentsError: "",
  acceptedAppointmentsCount: 0,

  checkedInAppointments: [],
  checkedInAppointmentsLoading: true,
  checkedInAppointmentsError: "",
  checkedInAppointmentsCount: 0,

  absenceAppointments: [],
  absenceAppointmentsLoading: true,
  absenceAppointmentsError: "",
  absenceAppointmentsCount: 0,

  inCompletedAppointments: [],
  inCompletedAppointmentsLoading: true,
  inCompletedAppointmentsError: "",
  inCompletedAppointmentsCount: 0,

  cancelledAppointments: [],
  cancelledAppointmentsLoading: true,
  cancelledAppointmentsError: "",
  cancelledAppointmentsCount: 0,

  appointment: null,
  appointmentError: "",
  appointmentLoading: true,
};

const reducer = (
  state = initialState,
  action: AppointmentAction
): AppointmentState => {
  switch (action.type) {
    case HYDRATE as any: {
      const { appointment, appointmentError, appointmentLoading, hydrated } = (
        action as any
      ).payload.appointment as AppointmentState;

      const newState = {
        appointment,
        appointmentError,
        appointmentLoading,
      };

      if (typeof hydrated !== "undefined") {
        Object.entries(newState).forEach(([key, _state]) => {
          if (typeof _state === "undefined") delete (newState as any)[key];
        });
      }

      return {
        ...state,
        ...(typeof hydrated !== "undefined" ? newState : {}),
        hydrated: true,
      };
    }

    case AppointmentActionTypes.FETCH_REQUESTED: {
      const { scope, isReset } = action.payload;

      const newState = {
        ...state,
        ...(typeof state[`${scope}Loading` as keyof typeof state] !==
        "undefined"
          ? {
              [`${scope}Loading`]: true,
            }
          : {}),
        ...(typeof state[`${scope}Error` as keyof typeof state] !== "undefined"
          ? {
              [`${scope}Error`]: "",
            }
          : {}),
      };

      if (isReset) {
        Object.assign(newState, {
          [scope]: Array.isArray(newState[scope]) ? [] : null,
        });
      }

      return newState;
    }
    case AppointmentActionTypes.FETCH_SUCCEEDED: {
      const { scope, data, count, isLoadMore } = action.payload;

      let newData = data;
      const stateData = state[scope];

      if (isLoadMore && Array.isArray(stateData) && Array.isArray(data)) {
        const filteredData = data.filter((item) => {
          return stateData.every(
            (stateDataItem) => item.id !== stateDataItem.id
          );
        });
        newData = [...stateData, ...filteredData];
      }

      return {
        ...state,
        [scope]: newData,
        ...(typeof state[`${scope}Loading` as keyof typeof state] !==
        "undefined"
          ? {
              [`${scope}Loading`]: false,
            }
          : {}),
        ...(commonHelpers.isNumber(count)
          ? {
              [`${scope}Count`]: count,
            }
          : {}),
      };
    }
    case AppointmentActionTypes.FETCH_FAILED: {
      const { scope, error } = action.payload;

      return {
        ...state,
        ...(typeof state[`${scope}Loading` as keyof typeof state] !==
        "undefined"
          ? {
              [`${scope}Loading`]: false,
            }
          : {}),
        ...(typeof state[`${scope}Error` as keyof typeof state] !== "undefined"
          ? {
              [`${scope}Error`]: error,
            }
          : {}),
      };
    }

    case AppointmentActionTypes.FETCH_APPOINTMENT_SUCCEEDED_SERVER: {
      return {
        appointment: action.payload,
        appointmentLoading: false,
        appointmentError: "",
        hydrated: true,
      } as Partial<AppointmentState> as AppointmentState;
    }

    default: {
      return state;
    }
  }
};

export default reducer;
