import defaultTheme from "@/theme";

export const DRAFT_STATUS_CODE = "draft";
export const SYSTEM_ASSIGNING_STATUS_CODE = "system_assigning";
export const SYSTEM_ASSIGNED_STATUS_CODE = "system_assigned";
export const ADMIN_MANUAL_ASSIGNED_STATUS_CODE = "admin_manual_assigned";
export const ADMIN_FORCED_ASSIGNED_STATUS_CODE = "admin_forced_assigned";
export const SYSTEM_ASSIGNED_NOT_FOUND_STATUS_CODE =
  "system_assigned_not_found";
export const ACCEPTED_STATUS_CODE = "accepted";
export const CUSTOMER_RESCHEDULE_STATUS_CODE = "customer_reschedule";
export const BEAUTY_CENTER_RESCHEDULE_STATUS_CODE = "beauty_center_reschedule";
export const CHECKED_IN_STATUS_CODE = "checked_in";
export const ABSENTED_STATUS_CODE = "absented";
export const ADMIN_CANCELLED_STATUS_CODE = "admin_canceled";
export const CUSTOMER_CANCELLED_STATUS_CODE = "customer_canceled";
export const DECLINED_STATUS_CODE = "declined";

export const statusCodePalettes = [
  {
    code: SYSTEM_ASSIGNING_STATUS_CODE,
    color: defaultTheme.palette.success.main,
  },
  {
    code: SYSTEM_ASSIGNED_STATUS_CODE,
    color: defaultTheme.palette.success.main,
  },
  {
    code: ADMIN_MANUAL_ASSIGNED_STATUS_CODE,
    color: defaultTheme.palette.success.main,
  },
  {
    code: ADMIN_FORCED_ASSIGNED_STATUS_CODE,
    color: defaultTheme.palette.success.main,
  },
  {
    code: SYSTEM_ASSIGNED_NOT_FOUND_STATUS_CODE,
    color: defaultTheme.palette.error.main,
  },
  {
    code: ACCEPTED_STATUS_CODE,
    color: defaultTheme.palette.success.main,
  },
  {
    code: CUSTOMER_RESCHEDULE_STATUS_CODE,
    color: defaultTheme.palette.warning.main,
  },
  {
    code: BEAUTY_CENTER_RESCHEDULE_STATUS_CODE,
    color: defaultTheme.palette.warning.main,
  },
  {
    code: CHECKED_IN_STATUS_CODE,
    color: defaultTheme.palette.success.main,
  },
  {
    code: ABSENTED_STATUS_CODE,
    color: defaultTheme.palette.text.primary,
  },
  {
    code: ADMIN_CANCELLED_STATUS_CODE,
    color: defaultTheme.palette.error.main,
  },
  {
    code: CUSTOMER_CANCELLED_STATUS_CODE,
    color: defaultTheme.palette.error.main,
  },
  {
    code: DECLINED_STATUS_CODE,
    color: defaultTheme.palette.error.main,
  },
] as {
  code: string;
  color: string;
  highlight?: string;
}[];

export const codeToStatusCodePaletteMap = [...statusCodePalettes].reduce(
  (labelMap, orderType) => {
    Object.assign(labelMap, {
      [orderType.code]: orderType,
    });
    return labelMap;
  },
  {} as Record<string, (typeof statusCodePalettes)[number]>
);

export enum StatusGroupCode {
  DRAFT = "draft",
  PROCESSING_ASSIGN = "processing_assign",
  RESCHEDULED = "rescheduled",
  ACCEPTED = "accepted",
  CHECKED_IN = "checked_in",
  ABSENCE = "absence",
  IN_COMPLETED = "in_complete",
  CANCELLED = "cancelled",
}

export const statusGroups = [
  {
    value: StatusGroupCode.DRAFT,
    label: "draft",
  },
  {
    value: StatusGroupCode.PROCESSING_ASSIGN,
    label: "processingAssign" as const,
  },
  {
    value: StatusGroupCode.RESCHEDULED,
    label: "rescheduledCustomer" as const,
  },
  {
    value: StatusGroupCode.ACCEPTED,
    label: "accepted" as const,
  },
  {
    value: StatusGroupCode.CHECKED_IN,
    label: "checkedIn" as const,
  },
  {
    value: StatusGroupCode.ABSENCE,
    label: "absence" as const,
  },
  {
    value: StatusGroupCode.IN_COMPLETED,
    label: "inCompleted" as const,
  },
  {
    value: StatusGroupCode.CANCELLED,
    label: "cancelled" as const,
  },
];
