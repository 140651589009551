import { AppointmentReportActionTypes } from "./types";
import type {
  FetchRequestedAction,
  FetchSucceededAction,
  FetchFailedAction,
  ResetAllReportsAction,
  // Saga
  FetchAppointmentCountReportsSagaAction,
  FetchBeautyCenterAppointmentCountReportsSagaAction,
  FetchBeautyCenterAppointmentChargeTotalReportsSagaAction,
} from "./types";

// ---- REDUCER ACTION ----

export const fetchRequested = (
  payload: FetchRequestedAction["payload"]
): FetchRequestedAction => ({
  type: AppointmentReportActionTypes.FETCH_REQUESTED,
  payload,
});

export const fetchSucceeded = (
  payload: FetchSucceededAction["payload"]
): FetchSucceededAction => ({
  type: AppointmentReportActionTypes.FETCH_SUCCEEDED,
  payload,
});

export const fetchFailed = (
  payload: FetchFailedAction["payload"]
): FetchFailedAction => ({
  type: AppointmentReportActionTypes.FETCH_FAILED,
  payload,
});

export const resetAllReports = (): ResetAllReportsAction => ({
  type: AppointmentReportActionTypes.RESET_ALL_REPORTS,
});

// ---- SAGA ACTION ----

export const fetchAppointmentCountReportsSaga = (
  payload: FetchAppointmentCountReportsSagaAction["payload"],
  meta?: FetchAppointmentCountReportsSagaAction["meta"]
): FetchAppointmentCountReportsSagaAction => ({
  type: AppointmentReportActionTypes.FETCH_APPOINTMENT_COUNT_REPORTS_SAGA,
  payload,
  meta,
});

export const fetchBeautyCenterAppointmentCountReportsSaga = (
  payload: FetchBeautyCenterAppointmentCountReportsSagaAction["payload"],
  meta?: FetchBeautyCenterAppointmentCountReportsSagaAction["meta"]
): FetchBeautyCenterAppointmentCountReportsSagaAction => ({
  type: AppointmentReportActionTypes.FETCH_BEAUTY_CENTER_APPOINTMENT_COUNT_REPORTS_SAGA,
  payload,
  meta,
});

export const fetchBeautyCenterAppointmentChargeTotalReportsSaga = (
  payload: FetchBeautyCenterAppointmentChargeTotalReportsSagaAction["payload"],
  meta?: FetchBeautyCenterAppointmentChargeTotalReportsSagaAction["meta"]
): FetchBeautyCenterAppointmentChargeTotalReportsSagaAction => ({
  type: AppointmentReportActionTypes.FETCH_BEAUTY_CENTER_APPOINTMENT_CHARGE_TOTAL_REPORTS_SAGA,
  payload,
  meta,
});
