import type {
  FetchPermissionsPayload,
  FetchPermissionsResponseData,
} from "@/utils/apis/permission";

export enum PermissionActionTypes {
  FETCH_REQUESTED = "@@permission/FETCH_REQUESTED",
  FETCH_SUCCEEDED = "@@permission/FETCH_SUCCEEDED",
  FETCH_FAILED = "@@permission/FETCH_FAILED",

  // Saga
  FETCH_PERMISSIONS_SAGA = "@@permission/FETCH_PERMISSIONS_SAGA",
}

// State

export interface PermissionState {
  permissions: FetchPermissionsResponseData["data"];
  permissionsLoading: boolean;
  permissionsError: string;
}

// ---- Reducer Action ----

export type FetchScope = "permissions";

export type FetchRequestedAction = {
  type: PermissionActionTypes.FETCH_REQUESTED;
  payload: {
    scope: FetchScope;
    isReset?: boolean;
  };
};

export type FetchSucceededAction = {
  type: PermissionActionTypes.FETCH_SUCCEEDED;
  payload: {
    scope: FetchScope;
    data: PermissionState[FetchScope];
    count?: number;
    isLoadMore?: boolean;
  };
};

export type FetchFailedAction = {
  type: PermissionActionTypes.FETCH_FAILED;
  payload: {
    scope: FetchScope;
    error: string;
  };
};

// ---- Saga Action ----

export type FetchPermissionsSagaAction = {
  type: PermissionActionTypes.FETCH_PERMISSIONS_SAGA;
  payload?: FetchPermissionsPayload;
  meta?: {
    isLoadMore?: boolean;
    isReset?: boolean;
    resolve?: (payload?: any) => void;
  };
};

export type PermissionAction =
  | FetchRequestedAction
  | FetchSucceededAction
  | FetchFailedAction
  //
  | FetchPermissionsSagaAction;
