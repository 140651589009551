import type {
  FetchProductServiceBeautyCenterSettingsPayload,
  FetchProductServiceBeautyCenterSettingsResponseData,
  CreateProductServiceBeautyCenterSettingPayload,
  DeleteProductServiceBeautyCenterSettingsPayload,
} from "@/utils/apis/productServiceBeautyCenterSetting";

export enum ProductServiceBeautyCenterSettingActionTypes {
  FETCH_REQUESTED = "@@productServiceBeautyCenterSetting/FETCH_REQUESTED",
  FETCH_SUCCEEDED = "@@productServiceBeautyCenterSetting/FETCH_SUCCEEDED",
  FETCH_FAILED = "@@productServiceBeautyCenterSetting/FETCH_FAILED",

  FETCH_PRODUCT_SERVICE_BEAUTY_CENTER_SETTING_SUCCEEDED_SERVER = "@@productServiceBeautyCenterSetting/FETCH_PRODUCT_SERVICE_BEAUTY_CENTER_SETTING_SUCCEEDED_SERVER",

  // Saga
  FETCH_PRODUCT_SERVICE_BEAUTY_CENTER_SETTINGS_SAGA = "@@productServiceBeautyCenterSetting/FETCH_PRODUCT_SERVICE_BEAUTY_CENTER_SETTINGS_SAGA",
  CREATE_PRODUCT_SERVICE_BEAUTY_CENTER_SETTING_SAGA = "@@productServiceBeautyCenterSetting/CREATE_PRODUCT_SERVICE_BEAUTY_CENTER_SETTING_SAGA",
  DELETE_PRODUCT_SERVICE_BEAUTY_CENTER_SETTINGS_SAGA = "@@productServiceBeautyCenterSetting/DELETE_PRODUCT_SERVICE_BEAUTY_CENTER_SETTINGS_SAGA",
}

// State

export interface ProductServiceBeautyCenterSettingState {
  productServiceBeautyCenterSettings: FetchProductServiceBeautyCenterSettingsResponseData["data"];
  productServiceBeautyCenterSettingsLoading: boolean;
  productServiceBeautyCenterSettingsError: string;
  productServiceBeautyCenterSettingsCount: number;
}

// ---- Reducer Action ----

export type FetchScope = "productServiceBeautyCenterSettings";

export type FetchRequestedAction = {
  type: ProductServiceBeautyCenterSettingActionTypes.FETCH_REQUESTED;
  payload: {
    scope: FetchScope;
    isReset?: boolean;
  };
};

export type FetchSucceededAction = {
  type: ProductServiceBeautyCenterSettingActionTypes.FETCH_SUCCEEDED;
  payload: {
    scope: FetchScope;
    data: ProductServiceBeautyCenterSettingState[FetchScope];
    count?: number;
  };
};

export type FetchFailedAction = {
  type: ProductServiceBeautyCenterSettingActionTypes.FETCH_FAILED;
  payload: {
    scope: FetchScope;
    error: string;
  };
};

// ---- Saga Action ----

export type FetchProductServiceBeautyCenterSettingsSagaAction = {
  type: ProductServiceBeautyCenterSettingActionTypes.FETCH_PRODUCT_SERVICE_BEAUTY_CENTER_SETTINGS_SAGA;
  payload: FetchProductServiceBeautyCenterSettingsPayload;
  meta?: {
    isReset?: boolean;
    resolve?: (payload?: any) => void;
  };
};

export type CreateProductServiceBeautyCenterSettingSagaAction = {
  type: ProductServiceBeautyCenterSettingActionTypes.CREATE_PRODUCT_SERVICE_BEAUTY_CENTER_SETTING_SAGA;
  payload: CreateProductServiceBeautyCenterSettingPayload;
  meta?: {
    resolve?: (payload?: any) => void;
  };
};

export type DeleteProductServiceBeautyCenterSettingsSagaAction = {
  type: ProductServiceBeautyCenterSettingActionTypes.DELETE_PRODUCT_SERVICE_BEAUTY_CENTER_SETTINGS_SAGA;
  payload: DeleteProductServiceBeautyCenterSettingsPayload;
  meta?: {
    resolve?: (payload?: any) => void;
  };
};

export type ProductServiceBeautyCenterSettingAction =
  | FetchRequestedAction
  | FetchSucceededAction
  | FetchFailedAction
  //
  | FetchProductServiceBeautyCenterSettingsSagaAction
  | CreateProductServiceBeautyCenterSettingSagaAction
  | DeleteProductServiceBeautyCenterSettingsSagaAction;
