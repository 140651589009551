import { BeautyCenterActionTypes } from "./types";
import { commonHelpers } from "@/utils/helpers";
import { HYDRATE } from "next-redux-wrapper";

import type { BeautyCenterState, BeautyCenterAction } from "./types";

export const initialState: BeautyCenterState = {
  beautyCenters: [],
  beautyCentersError: "",
  beautyCentersLoading: true,
  beautyCentersCount: 0,

  beautyCenter: null,
  beautyCenterError: "",
  beautyCenterLoading: true,
};

const reducer = (
  state = initialState,
  action: BeautyCenterAction
): BeautyCenterState => {
  switch (action.type) {
    case HYDRATE as any: {
      const { beautyCenter, beautyCenterError, beautyCenterLoading, hydrated } =
        (action as any).payload.beautyCenter as BeautyCenterState;

      const newState = {
        beautyCenter,
        beautyCenterError,
        beautyCenterLoading,
      };

      if (typeof hydrated !== "undefined") {
        Object.entries(newState).forEach(([key, _state]) => {
          if (typeof _state === "undefined") delete (newState as any)[key];
        });
      }

      return {
        ...state,
        ...(typeof hydrated !== "undefined" ? newState : {}),
        hydrated: true,
      };
    }

    case BeautyCenterActionTypes.FETCH_REQUESTED: {
      const { scope, isReset } = action.payload;

      const newState = {
        ...state,
        ...(typeof state[`${scope}Loading` as keyof typeof state] !==
        "undefined"
          ? {
              [`${scope}Loading`]: true,
            }
          : {}),
        ...(typeof state[`${scope}Error` as keyof typeof state] !== "undefined"
          ? {
              [`${scope}Error`]: "",
            }
          : {}),
      };

      if (isReset) {
        Object.assign(newState, {
          [scope]: Array.isArray(newState[scope]) ? [] : null,
        });
      }

      return newState;
    }
    case BeautyCenterActionTypes.FETCH_SUCCEEDED: {
      const { scope, data, count, isLoadMore } = action.payload;

      let newData = data;
      const stateData = state[scope];

      if (isLoadMore && Array.isArray(stateData) && Array.isArray(data)) {
        const filteredData = data.filter((item) => {
          return stateData.every(
            (stateDataItem) => item.id !== stateDataItem.id
          );
        });
        newData = [...stateData, ...(filteredData as any)];
      }

      return {
        ...state,
        [scope]: newData,
        ...(typeof state[`${scope}Loading` as keyof typeof state] !==
        "undefined"
          ? {
              [`${scope}Loading`]: false,
            }
          : {}),
        ...(commonHelpers.isNumber(count)
          ? {
              [`${scope}Count`]: count,
            }
          : {}),
      };
    }
    case BeautyCenterActionTypes.FETCH_FAILED: {
      const { scope, error } = action.payload;

      return {
        ...state,
        ...(typeof state[`${scope}Loading` as keyof typeof state] !==
        "undefined"
          ? {
              [`${scope}Loading`]: false,
            }
          : {}),
        ...(typeof state[`${scope}Error` as keyof typeof state] !== "undefined"
          ? {
              [`${scope}Error`]: error,
            }
          : {}),
      };
    }

    case BeautyCenterActionTypes.FETCH_BEAUTY_CENTER_SUCCEEDED_SERVER: {
      return {
        beautyCenter: action.payload,
        beautyCenterLoading: false,
        beautyCenterError: "",
        hydrated: true,
      } as Partial<BeautyCenterState> as BeautyCenterState;
    }

    case BeautyCenterActionTypes.UPDATE_BEAUTY_CENTER_ENABLED_SUCCEEDED: {
      const { id, is_enabled } = action.payload;

      return {
        ...state,
        beautyCenters: state.beautyCenters.map((beautyCenter) => {
          if (beautyCenter.id === id)
            return {
              ...beautyCenter,
              is_enabled,
            };
          return beautyCenter;
        }),
        beautyCenter:
          state.beautyCenter?.id === id
            ? {
                ...state.beautyCenter,
                is_enabled,
              }
            : state.beautyCenter,
      };
    }

    default: {
      return state;
    }
  }
};

export default reducer;
