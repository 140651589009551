import { authAxios } from "@/libs/axios";

import type {
  FetchAdminActionLogsPayload,
  FetchAdminActionLogsResponseData,
  FetchAdminActionLogPayload,
  FetchAdminActionLogResponseData,
} from "./adminActionLog.api.types";
import type { AxiosResponseData } from "@/libs/axios";

const adminActionLogApi = {
  fetchAdminActionLogs: (payload?: FetchAdminActionLogsPayload) => {
    return authAxios.get<AxiosResponseData<FetchAdminActionLogsResponseData>>(
      "admin_action_logs",
      {
        params: payload?.params,
        cancelToken: payload?.cancelToken,
      }
    );
  },
  fetchAdminActionLog: (payload: FetchAdminActionLogPayload) => {
    return authAxios.get<AxiosResponseData<FetchAdminActionLogResponseData>>(
      `admin_action_logs/${payload.params.id}`,
      {
        cancelToken: payload?.cancelToken,
        headers: payload.headers,
      }
    );
  },
};

export default adminActionLogApi;
