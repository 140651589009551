import { all, call, put, takeEvery } from "redux-saga/effects";
import axios from "axios";

import { axiosHelpers } from "@/utils/helpers";
import { reportApi } from "@/utils/apis";

import { AppointmentReportActionTypes } from "./types";
import { fetchRequested, fetchFailed, fetchSucceeded } from "./action";

import type {
  FetchScope,
  FetchAppointmentCountReportsSagaAction,
  FetchBeautyCenterAppointmentCountReportsSagaAction,
  FetchBeautyCenterAppointmentChargeTotalReportsSagaAction,
} from "./types";

function* fetchAppointmentCountReportsSaga(
  action: FetchAppointmentCountReportsSagaAction
) {
  const { params, cancelToken } = action.payload;
  const { resolve = () => {}, isReset } = action.meta || {};
  const scope = "appointmentCountReports" as FetchScope;
  yield put(
    fetchRequested({
      scope,
      isReset,
    })
  );
  try {
    const {
      data: response,
    }: Awaited<ReturnType<typeof reportApi.fetchAppointmentCountReports>> =
      yield call(reportApi.fetchAppointmentCountReports, {
        params,
        cancelToken,
      });
    if (axiosHelpers.checkRequestSuccess(response)) {
      yield put(
        fetchSucceeded({
          scope,
          data: response.data?.data ?? null,
        })
      );
    } else {
      yield put(
        fetchFailed({
          scope,
          error: response.message,
        })
      );
    }
    resolve(response);
  } catch (error) {
    if (axios.isCancel(error)) {
      resolve({ message: error.message, isCancelled: true });
      return;
    }
    const message = axiosHelpers.getErrorMessage(error);
    yield put(
      fetchFailed({
        scope,
        error: message,
      })
    );
    resolve({ message });
  }
}

function* fetchBeautyCenterAppointmentCountReportsSaga(
  action: FetchBeautyCenterAppointmentCountReportsSagaAction
) {
  const { params, cancelToken } = action.payload;
  const { resolve = () => {}, isReset } = action.meta || {};
  const scope = "beautyCenterAppointmentCountReports" as FetchScope;
  yield put(
    fetchRequested({
      scope,
      isReset,
    })
  );
  try {
    const {
      data: response,
    }: Awaited<
      ReturnType<typeof reportApi.fetchBeautyCenterAppointmentCountReports>
    > = yield call(reportApi.fetchBeautyCenterAppointmentCountReports, {
      params,
      cancelToken,
    });
    if (axiosHelpers.checkRequestSuccess(response)) {
      yield put(
        fetchSucceeded({
          scope,
          data: response.data?.data ?? [],
        })
      );
    } else {
      yield put(
        fetchFailed({
          scope,
          error: response.message,
        })
      );
    }
    resolve(response);
  } catch (error) {
    if (axios.isCancel(error)) {
      resolve({ message: error.message, isCancelled: true });
      return;
    }
    const message = axiosHelpers.getErrorMessage(error);
    yield put(
      fetchFailed({
        scope,
        error: message,
      })
    );
    resolve({ message });
  }
}

function* fetchBeautyCenterAppointmentChargeTotalReportsSaga(
  action: FetchBeautyCenterAppointmentChargeTotalReportsSagaAction
) {
  const { params, cancelToken } = action.payload;
  const { resolve = () => {}, isReset } = action.meta || {};
  const scope = "beautyCenterAppointmentChargeTotalReports" as FetchScope;
  yield put(
    fetchRequested({
      scope,
      isReset,
    })
  );
  try {
    const {
      data: response,
    }: Awaited<
      ReturnType<
        typeof reportApi.fetchBeautyCenterAppointmentChargeTotalReports
      >
    > = yield call(reportApi.fetchBeautyCenterAppointmentChargeTotalReports, {
      params,
      cancelToken,
    });
    if (axiosHelpers.checkRequestSuccess(response)) {
      yield put(
        fetchSucceeded({
          scope,
          data: response.data?.data ?? [],
        })
      );
    } else {
      yield put(
        fetchFailed({
          scope,
          error: response.message,
        })
      );
    }
    resolve(response);
  } catch (error) {
    if (axios.isCancel(error)) {
      resolve({ message: error.message, isCancelled: true });
      return;
    }
    const message = axiosHelpers.getErrorMessage(error);
    yield put(
      fetchFailed({
        scope,
        error: message,
      })
    );
    resolve({ message });
  }
}

function* appointmentReportSaga() {
  yield all([
    takeEvery(
      AppointmentReportActionTypes.FETCH_APPOINTMENT_COUNT_REPORTS_SAGA,
      fetchAppointmentCountReportsSaga
    ),
    takeEvery(
      AppointmentReportActionTypes.FETCH_BEAUTY_CENTER_APPOINTMENT_COUNT_REPORTS_SAGA,
      fetchBeautyCenterAppointmentCountReportsSaga
    ),
    takeEvery(
      AppointmentReportActionTypes.FETCH_BEAUTY_CENTER_APPOINTMENT_CHARGE_TOTAL_REPORTS_SAGA,
      fetchBeautyCenterAppointmentChargeTotalReportsSaga
    ),
  ]);
}

export default appointmentReportSaga;
