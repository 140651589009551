import { authAxios } from "@/libs/axios";

import type {
  FetchStaffsPayload,
  FetchStaffsResponseData,
  FetchStaffPayload,
  FetchStaffResponseData,
  UpdateStaffEnabledPayload,
  UpdateStaffPayload,
  CreateStaffPayload,
  DeleteStaffPayload,
  DownloadStaffsPayload
} from "./staff.api.types";
import type { AxiosResponseData } from "@/libs/axios";

const staffApi = {
  fetchStaffs: (payload?: FetchStaffsPayload) => {
    return authAxios.get<AxiosResponseData<FetchStaffsResponseData>>("staffs", {
      params: payload?.params,
      cancelToken: payload?.cancelToken,
    });
  },
  fetchStaff: (payload: FetchStaffPayload) => {
    return authAxios.get<AxiosResponseData<FetchStaffResponseData>>(
      `staffs/${payload.params.id}`,
      {
        cancelToken: payload?.cancelToken,
        headers: payload.headers,
      }
    );
  },
  updateStaffEnabled: (payload: UpdateStaffEnabledPayload) => {
    const { id, ...params } = payload.params;
    return authAxios.patch<AxiosResponseData>(`staffs/enable/${id}`, params, {
      cancelToken: payload?.cancelToken,
    });
  },
  updateStaff: (payload: UpdateStaffPayload) => {
    const { id, ...params } = payload.params;
    return authAxios.put<AxiosResponseData>(`staffs/${id}`, params, {
      cancelToken: payload?.cancelToken,
    });
  },
  createStaff: (payload: CreateStaffPayload) => {
    return authAxios.post<AxiosResponseData>("staffs", payload.params, {
      cancelToken: payload?.cancelToken,
    });
  },
  deleteStaff: (payload: DeleteStaffPayload) => {
    return authAxios.delete<AxiosResponseData>(`staffs/${payload.params.id}`, {
      cancelToken: payload?.cancelToken,
    });
  },
  downloadStaffs: (payload?: DownloadStaffsPayload) => {
    return authAxios.get<Blob>("staffs/export", {
      params: payload?.params,
      cancelToken: payload?.cancelToken,
      responseType: "blob",
    });
  },
};

export default staffApi;
