import { CustomerActionTypes } from "./types";
import { commonHelpers } from "@/utils/helpers";
import { HYDRATE } from "next-redux-wrapper";

import type { CustomerState, CustomerAction } from "./types";

export const initialState: CustomerState = {
  customers: [],
  customersError: "",
  customersLoading: true,
  customersCount: 0,

  customer: null,
  customerError: "",
  customerLoading: true,
};

const reducer = (
  state = initialState,
  action: CustomerAction
): CustomerState => {
  switch (action.type) {
    case HYDRATE as any: {
      const { customer, customerError, customerLoading, hydrated } = (
        action as any
      ).payload.customer as CustomerState;

      const newState = {
        customer,
        customerError,
        customerLoading,
      };

      if (typeof hydrated !== "undefined") {
        Object.entries(newState).forEach(([key, _state]) => {
          if (typeof _state === "undefined") delete (newState as any)[key];
        });
      }

      return {
        ...state,
        ...(typeof hydrated !== "undefined" ? newState : {}),
        hydrated: true,
      };
    }

    case CustomerActionTypes.FETCH_REQUESTED: {
      const { scope, isReset } = action.payload;

      const newState = {
        ...state,
        ...(typeof state[`${scope}Loading` as keyof typeof state] !==
        "undefined"
          ? {
              [`${scope}Loading`]: true,
            }
          : {}),
        ...(typeof state[`${scope}Error` as keyof typeof state] !== "undefined"
          ? {
              [`${scope}Error`]: "",
            }
          : {}),
      };

      if (isReset) {
        Object.assign(newState, {
          [scope]: Array.isArray(newState[scope]) ? [] : null,
        });
      }

      return newState;
    }
    case CustomerActionTypes.FETCH_SUCCEEDED: {
      const { scope, data, count, isLoadMore } = action.payload;

      let newData = data;
      const stateData = state[scope];

      if (isLoadMore && Array.isArray(stateData) && Array.isArray(data)) {
        const filteredData = data.filter((item) => {
          return stateData.every(
            (stateDataItem) => item.id !== stateDataItem.id
          );
        });
        newData = [...stateData, ...filteredData];
      }

      return {
        ...state,
        [scope]: newData,
        ...(typeof state[`${scope}Loading` as keyof typeof state] !==
        "undefined"
          ? {
              [`${scope}Loading`]: false,
            }
          : {}),
        ...(commonHelpers.isNumber(count)
          ? {
              [`${scope}Count`]: count,
            }
          : {}),
      };
    }
    case CustomerActionTypes.FETCH_FAILED: {
      const { scope, error } = action.payload;

      return {
        ...state,
        ...(typeof state[`${scope}Loading` as keyof typeof state] !==
        "undefined"
          ? {
              [`${scope}Loading`]: false,
            }
          : {}),
        ...(typeof state[`${scope}Error` as keyof typeof state] !== "undefined"
          ? {
              [`${scope}Error`]: error,
            }
          : {}),
      };
    }

    case CustomerActionTypes.FETCH_CUSTOMER_SUCCEEDED_SERVER: {
      return {
        customer: action.payload,
        customerLoading: false,
        customerError: "",
        hydrated: true,
      } as Partial<CustomerState> as CustomerState;
    }

    case CustomerActionTypes.UPDATE_CUSTOMER_BLOCKED_SUCCEEDED: {
      const { id, is_blocked } = action.payload;

      return {
        ...state,
        customers: state.customers.map((customer) => {
          if (customer.id === id)
            return {
              ...customer,
              is_blocked: is_blocked,
            };
          return customer;
        }),
        customer:
          state.customer?.id === id
            ? {
                ...state.customer,
                is_blocked: is_blocked,
              }
            : state.customer,
      };
    }

    default: {
      return state;
    }
  }
};

export default reducer;
