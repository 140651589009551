import { all, call, put, takeEvery, takeLatest } from "redux-saga/effects";
import axios from "axios";

import { axiosHelpers } from "@/utils/helpers";
import { productServiceBeautyCenterSettingApi } from "@/utils/apis";

import { ProductServiceBeautyCenterSettingActionTypes } from "./types";
import { fetchRequested, fetchFailed, fetchSucceeded } from "./action";

import type {
  FetchScope,
  FetchProductServiceBeautyCenterSettingsSagaAction,
  CreateProductServiceBeautyCenterSettingSagaAction,
  DeleteProductServiceBeautyCenterSettingsSagaAction,
} from "./types";

function* fetchProductServiceBeautyCenterSettingsSaga(
  action: FetchProductServiceBeautyCenterSettingsSagaAction
) {
  const { params, cancelToken } = action.payload || {};
  const { resolve = () => {}, isReset } = action.meta || {};
  const scope = "productServiceBeautyCenterSettings" as FetchScope;
  yield put(
    fetchRequested({
      scope,
      isReset,
    })
  );
  try {
    const {
      data: response,
    }: Awaited<
      ReturnType<
        typeof productServiceBeautyCenterSettingApi.fetchProductServiceBeautyCenterSettings
      >
    > = yield call(
      productServiceBeautyCenterSettingApi.fetchProductServiceBeautyCenterSettings,
      {
        params,
        cancelToken,
      }
    );
    if (axiosHelpers.checkRequestSuccess(response)) {
      yield put(
        fetchSucceeded({
          scope,
          data: response.data?.data ?? [],
          count: response.data?.pagination?.total ?? 0,
        })
      );
    } else {
      yield put(
        fetchFailed({
          scope,
          error: response.message,
        })
      );
    }
    resolve(response);
  } catch (error) {
    if (axios.isCancel(error)) {
      resolve({ message: error.message, isCancelled: true });
      return;
    }
    const message = axiosHelpers.getErrorMessage(error);
    yield put(
      fetchFailed({
        scope,
        error: message,
      })
    );
    resolve({ message });
  }
}

function* createProductServiceBeautyCenterSettingSaga(
  action: CreateProductServiceBeautyCenterSettingSagaAction
) {
  const { params, cancelToken } = action.payload;
  const { resolve = () => {} } = action.meta || {};

  try {
    const {
      data: response,
    }: Awaited<
      ReturnType<
        typeof productServiceBeautyCenterSettingApi.createProductServiceBeautyCenterSetting
      >
    > = yield call(
      productServiceBeautyCenterSettingApi.createProductServiceBeautyCenterSetting,
      {
        params,
        cancelToken,
      }
    );
    resolve(response);
  } catch (error) {
    if (axios.isCancel(error)) {
      resolve({ message: error.message, isCancelled: true });
      return;
    }
    const message = axiosHelpers.getErrorMessage(error);
    resolve({ message });
  }
}

function* deleteProductServiceBeautyCenterSettingsSaga(
  action: DeleteProductServiceBeautyCenterSettingsSagaAction
) {
  const { params, cancelToken } = action.payload;
  const { resolve = () => {} } = action.meta || {};

  try {
    const {
      data: response,
    }: Awaited<
      ReturnType<
        typeof productServiceBeautyCenterSettingApi.deleteProductServiceBeautyCenterSettings
      >
    > = yield call(
      productServiceBeautyCenterSettingApi.deleteProductServiceBeautyCenterSettings,
      {
        params,
        cancelToken,
      }
    );
    resolve(response);
  } catch (error) {
    if (axios.isCancel(error)) {
      resolve({ message: error.message, isCancelled: true });
      return;
    }
    const message = axiosHelpers.getErrorMessage(error);
    resolve({ message });
  }
}

function* productServiceBeautyCenterSettingSaga() {
  yield all([
    takeEvery(
      ProductServiceBeautyCenterSettingActionTypes.FETCH_PRODUCT_SERVICE_BEAUTY_CENTER_SETTINGS_SAGA,
      fetchProductServiceBeautyCenterSettingsSaga
    ),
    takeLatest(
      ProductServiceBeautyCenterSettingActionTypes.CREATE_PRODUCT_SERVICE_BEAUTY_CENTER_SETTING_SAGA,
      createProductServiceBeautyCenterSettingSaga
    ),
    takeLatest(
      ProductServiceBeautyCenterSettingActionTypes.DELETE_PRODUCT_SERVICE_BEAUTY_CENTER_SETTINGS_SAGA,
      deleteProductServiceBeautyCenterSettingsSaga
    ),
  ]);
}

export default productServiceBeautyCenterSettingSaga;
