import { all, call, put, takeEvery, takeLatest } from "redux-saga/effects";
import axios from "axios";

import { axiosHelpers } from "@/utils/helpers";
import { customerApi } from "@/utils/apis";

import {
  CustomerActionTypes,
  CreateCustomerSagaAction,
  UpdateCustomerSagaAction,
  DeleteCustomerSagaAction,
} from "./types";
import {
  fetchRequested,
  fetchFailed,
  fetchSucceeded,
  updateCustomerBlockedSucceeded,
} from "./action";

import type {
  FetchScope,
  FetchCustomersSagaAction,
  UpdateCustomerBlockedSagaAction,
} from "./types";

function* fetchCustomersSaga(action: FetchCustomersSagaAction) {
  const { params, cancelToken } = action.payload || {};
  const { resolve = () => {}, isLoadMore, isReset } = action.meta || {};
  const scope = "customers" as FetchScope;
  yield put(
    fetchRequested({
      scope,
      isReset,
    })
  );
  try {
    const {
      data: response,
    }: Awaited<ReturnType<typeof customerApi.fetchCustomers>> = yield call(
      customerApi.fetchCustomers,
      {
        params,
        cancelToken,
      }
    );
    if (axiosHelpers.checkRequestSuccess(response)) {
      yield put(
        fetchSucceeded({
          scope,
          data: response.data?.data ?? [],
          count: response.data?.pagination?.total ?? 0,
          isLoadMore: !!isLoadMore,
        })
      );
    } else {
      yield put(
        fetchFailed({
          scope,
          error: response.message,
        })
      );
    }
    resolve(response);
  } catch (error) {
    if (axios.isCancel(error)) {
      resolve({ message: error.message, isCancelled: true });
      return;
    }
    const message = axiosHelpers.getErrorMessage(error);
    yield put(
      fetchFailed({
        scope,
        error: message,
      })
    );
    resolve({ message });
  }
}

function* updateCustomerBlockedSaga(action: UpdateCustomerBlockedSagaAction) {
  const { params, cancelToken } = action.payload;
  const { resolve = () => {} } = action.meta || {};

  try {
    const {
      data: response,
    }: Awaited<ReturnType<typeof customerApi.updateCustomerBlocked>> =
      yield call(customerApi.updateCustomerBlocked, {
        params,
        cancelToken,
      });
    if (axiosHelpers.checkRequestSuccess(response)) {
      yield put(
        updateCustomerBlockedSucceeded({
          id: params.id,
          is_blocked: params.is_blocked,
        })
      );
    }
    resolve(response);
  } catch (error) {
    if (axios.isCancel(error)) {
      resolve({ message: error.message, isCancelled: true });
      return;
    }
    const message = axiosHelpers.getErrorMessage(error);
    resolve({ message });
  }
}

function* updateCustomerSaga(action: UpdateCustomerSagaAction) {
  const { params, cancelToken } = action.payload;
  const { resolve = () => {} } = action.meta || {};

  try {
    const {
      data: response,
    }: Awaited<ReturnType<typeof customerApi.updateCustomer>> = yield call(
      customerApi.updateCustomer,
      {
        params,
        cancelToken,
      }
    );
    resolve(response);
  } catch (error) {
    if (axios.isCancel(error)) {
      resolve({ message: error.message, isCancelled: true });
      return;
    }
    const message = axiosHelpers.getErrorMessage(error);
    resolve({ message });
  }
}

function* createCustomerSaga(action: CreateCustomerSagaAction) {
  const { params, cancelToken } = action.payload;
  const { resolve = () => {} } = action.meta || {};

  try {
    const {
      data: response,
    }: Awaited<ReturnType<typeof customerApi.createCustomer>> = yield call(
      customerApi.createCustomer,
      {
        params,
        cancelToken,
      }
    );
    resolve(response);
  } catch (error) {
    if (axios.isCancel(error)) {
      resolve({ message: error.message, isCancelled: true });
      return;
    }
    const message = axiosHelpers.getErrorMessage(error);
    resolve({ message });
  }
}

function* deleteCustomerSaga(action: DeleteCustomerSagaAction) {
  const { params, cancelToken } = action.payload;
  const { resolve = () => {} } = action.meta || {};

  try {
    const {
      data: response,
    }: Awaited<ReturnType<typeof customerApi.deleteCustomer>> = yield call(
      customerApi.deleteCustomer,
      {
        params,
        cancelToken,
      }
    );
    resolve(response);
  } catch (error) {
    if (axios.isCancel(error)) {
      resolve({ message: error.message, isCancelled: true });
      return;
    }
    const message = axiosHelpers.getErrorMessage(error);
    resolve({ message });
  }
}

function* customerSaga() {
  yield all([
    takeEvery(CustomerActionTypes.FETCH_CUSTOMERS_SAGA, fetchCustomersSaga),
    takeLatest(
      CustomerActionTypes.UPDATE_CUSTOMER_BLOCKED_SAGA,
      updateCustomerBlockedSaga
    ),
    takeLatest(CustomerActionTypes.UPDATE_CUSTOMER_SAGA, updateCustomerSaga),
    takeLatest(CustomerActionTypes.CREATE_CUSTOMER_SAGA, createCustomerSaga),
    takeLatest(CustomerActionTypes.DELETE_CUSTOMER_SAGA, deleteCustomerSaga),
  ]);
}

export default customerSaga;
