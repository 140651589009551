import { all, call, put, takeEvery, takeLatest } from "redux-saga/effects";
import axios from "axios";

import { axiosHelpers } from "@/utils/helpers";
import { roleApi } from "@/utils/apis";

import {
  RoleActionTypes,
  CreateRoleSagaAction,
  UpdateRoleSagaAction,
  DeleteRoleSagaAction,
} from "./types";
import {
  fetchRequested,
  fetchFailed,
  fetchSucceeded,
  updateRoleEnabledSucceeded,
} from "./action";

import type {
  FetchScope,
  FetchRolesSagaAction,
  UpdateRoleEnabledSagaAction,
} from "./types";

function* fetchRolesSaga(action: FetchRolesSagaAction) {
  const { params, cancelToken } = action.payload || {};
  const { resolve = () => {}, isLoadMore, isReset } = action.meta || {};
  const scope = "roles" as FetchScope;
  yield put(
    fetchRequested({
      scope,
      isReset,
    })
  );
  try {
    const { data: response }: Awaited<ReturnType<typeof roleApi.fetchRoles>> =
      yield call(roleApi.fetchRoles, {
        params,
        cancelToken,
      });
    if (axiosHelpers.checkRequestSuccess(response)) {
      yield put(
        fetchSucceeded({
          scope,
          data: response.data?.data ?? [],
          count: response.data?.pagination?.total ?? 0,
          isLoadMore: !!isLoadMore,
        })
      );
    } else {
      yield put(
        fetchFailed({
          scope,
          error: response.message,
        })
      );
    }
    resolve(response);
  } catch (error) {
    if (axios.isCancel(error)) {
      resolve({ message: error.message, isCancelled: true });
      return;
    }
    const message = axiosHelpers.getErrorMessage(error);
    yield put(
      fetchFailed({
        scope,
        error: message,
      })
    );
    resolve({ message });
  }
}

function* updateRoleEnabledSaga(action: UpdateRoleEnabledSagaAction) {
  const { params, cancelToken } = action.payload;
  const { resolve = () => {} } = action.meta || {};

  try {
    const {
      data: response,
    }: Awaited<ReturnType<typeof roleApi.updateRoleEnabled>> = yield call(
      roleApi.updateRoleEnabled,
      {
        params,
        cancelToken,
      }
    );
    if (axiosHelpers.checkRequestSuccess(response)) {
      yield put(
        updateRoleEnabledSucceeded({
          id: params.id,
          is_enabled: params.is_enabled,
        })
      );
    }
    resolve(response);
  } catch (error) {
    if (axios.isCancel(error)) {
      resolve({ message: error.message, isCancelled: true });
      return;
    }
    const message = axiosHelpers.getErrorMessage(error);
    resolve({ message });
  }
}

function* updateRoleSaga(action: UpdateRoleSagaAction) {
  const { params, cancelToken } = action.payload;
  const { resolve = () => {} } = action.meta || {};

  try {
    const { data: response }: Awaited<ReturnType<typeof roleApi.updateRole>> =
      yield call(roleApi.updateRole, {
        params,
        cancelToken,
      });
    resolve(response);
  } catch (error) {
    if (axios.isCancel(error)) {
      resolve({ message: error.message, isCancelled: true });
      return;
    }
    const message = axiosHelpers.getErrorMessage(error);
    resolve({ message });
  }
}

function* createRoleSaga(action: CreateRoleSagaAction) {
  const { params, cancelToken } = action.payload;
  const { resolve = () => {} } = action.meta || {};

  try {
    const { data: response }: Awaited<ReturnType<typeof roleApi.createRole>> =
      yield call(roleApi.createRole, {
        params,
        cancelToken,
      });
    resolve(response);
  } catch (error) {
    if (axios.isCancel(error)) {
      resolve({ message: error.message, isCancelled: true });
      return;
    }
    const message = axiosHelpers.getErrorMessage(error);
    resolve({ message });
  }
}

function* deleteRoleSaga(action: DeleteRoleSagaAction) {
  const { params, cancelToken } = action.payload;
  const { resolve = () => {} } = action.meta || {};

  try {
    const { data: response }: Awaited<ReturnType<typeof roleApi.deleteRole>> =
      yield call(roleApi.deleteRole, {
        params,
        cancelToken,
      });
    resolve(response);
  } catch (error) {
    if (axios.isCancel(error)) {
      resolve({ message: error.message, isCancelled: true });
      return;
    }
    const message = axiosHelpers.getErrorMessage(error);
    resolve({ message });
  }
}

function* roleSaga() {
  yield all([
    takeEvery(RoleActionTypes.FETCH_ROLES_SAGA, fetchRolesSaga),
    takeLatest(RoleActionTypes.UPDATE_ROLE_ENABLED_SAGA, updateRoleEnabledSaga),
    takeLatest(RoleActionTypes.UPDATE_ROLE_SAGA, updateRoleSaga),
    takeLatest(RoleActionTypes.CREATE_ROLE_SAGA, createRoleSaga),
    takeLatest(RoleActionTypes.DELETE_ROLE_SAGA, deleteRoleSaga),
  ]);
}

export default roleSaga;
