import { authAxios } from "@/libs/axios";

import type {
  FetchPlatformCodesPayload,
  FetchPlatformCodesResponseData,
  FetchOptionPlatformCodesPayload,
  FetchOptionPlatformCodesResponseData,
  CreatePlatformCodePayload,
  UpdatePlatformCodePayload,
  DeletePlatformCodePayload,
  FetchPlatformCodePayload,
  FetchPlatformCodeResponseData,
} from "./platformCode.api.types";
import type { AxiosResponseData } from "@/libs/axios";

const platformCodeApi = {
  fetchPlatformCodes: (payload?: FetchPlatformCodesPayload) => {
    return authAxios.get<AxiosResponseData<FetchPlatformCodesResponseData>>(
      "platform_codes",
      {
        params: payload?.params,
        cancelToken: payload?.cancelToken,
      }
    );
  },
  fetchOptionPlatformCodes: (payload?: FetchOptionPlatformCodesPayload) => {
    return authAxios.get<
      AxiosResponseData<FetchOptionPlatformCodesResponseData>
    >("platform_codes/select_list", {
      params: payload?.params,
      cancelToken: payload?.cancelToken,
    });
  },
  fetchPlatformCode: (payload: FetchPlatformCodePayload) => {
    const { id, ...params } = payload.params;
    return authAxios.get<AxiosResponseData<FetchPlatformCodeResponseData>>(
      `platform_codes/${id}`,
      {
        params: params,
        cancelToken: payload?.cancelToken,
        headers: payload.headers,
      }
    );
  },
  createPlatformCode: (payload: CreatePlatformCodePayload) => {
    return authAxios.post<AxiosResponseData>("platform_codes", payload.params, {
      cancelToken: payload?.cancelToken,
    });
  },
  updatePlatformCode: (payload: UpdatePlatformCodePayload) => {
    const { id, ...params } = payload.params;
    return authAxios.put<AxiosResponseData>(`platform_codes/${id}`, params, {
      cancelToken: payload?.cancelToken,
    });
  },
  deletePlatformCode: (payload: DeletePlatformCodePayload) => {
    const { id } = payload.params;
    return authAxios.delete<AxiosResponseData>(`platform_codes/${id}`, {
      cancelToken: payload?.cancelToken,
    });
  },
};

export default platformCodeApi;
