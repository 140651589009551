import type {
  FetchCategoriesPayload,
  FetchCategoriesResponseData,
  FetchCategoryResponseData,
  UpdateCategoryEnabledPayload,
  DeleteCategoryPayload,
  UpdateCategoryPayload,
  CreateCategoryPayload,
} from "@/utils/apis/category";

export enum CategoryActionTypes {
  FETCH_REQUESTED = "@@category/FETCH_REQUESTED",
  FETCH_SUCCEEDED = "@@category/FETCH_SUCCEEDED",
  FETCH_FAILED = "@@category/FETCH_FAILED",

  UPDATE_CATEGORY_ENABLED_SUCCEEDED = "@@category/UPDATE_CATEGORY_ENABLED_SUCCEEDED",

  FETCH_CATEGORY_SUCCEEDED_SERVER = "@@category/FETCH_CATEGORY_SUCCEEDED_SERVER",

  // Saga
  FETCH_PRIMARY_CATEGORIES_SAGA = "@@category/FETCH_PRIMARY_CATEGORIES_SAGA",
  FETCH_SECONDARY_CATEGORIES_SAGA = "@@category/FETCH_SECONDARY_CATEGORIES_SAGA",
  UPDATE_CATEGORY_ENABLED_SAGA = "@@category/UPDATE_CATEGORY_ENABLED_SAGA",
  UPDATE_CATEGORY_SAGA = "@@category/UPDATE_CATEGORY_SAGA",
  CREATE_CATEGORY_SAGA = "@@category/CREATE_CATEGORY_SAGA",
  DELETE_CATEGORY_SAGA = "@@category/DELETE_CATEGORY_SAGA",
}

// State

export interface CategoryState {
  hydrated?: boolean;

  primaryCategories: FetchCategoriesResponseData["data"];
  primaryCategoriesLoading: boolean;
  primaryCategoriesError: string;
  primaryCategoriesCount: number;

  secondaryCategories: FetchCategoriesResponseData["data"];
  secondaryCategoriesLoading: boolean;
  secondaryCategoriesError: string;
  secondaryCategoriesCount: number;

  category: FetchCategoryResponseData["data"] | null;
  categoryLoading: boolean;
  categoryError: string;
}

// ---- Reducer Action ----

export type FetchScope =
  | "primaryCategories"
  | "secondaryCategories"
  | "category";

export type FetchRequestedAction = {
  type: CategoryActionTypes.FETCH_REQUESTED;
  payload: {
    scope: FetchScope;
    isReset?: boolean;
  };
};

export type FetchSucceededAction = {
  type: CategoryActionTypes.FETCH_SUCCEEDED;
  payload: {
    scope: FetchScope;
    data: CategoryState[FetchScope];
    count?: number;
    isLoadMore?: boolean;
  };
};

export type FetchFailedAction = {
  type: CategoryActionTypes.FETCH_FAILED;
  payload: {
    scope: FetchScope;
    error: string;
  };
};

export type FetchCategorySucceededServerAction = {
  type: CategoryActionTypes.FETCH_CATEGORY_SUCCEEDED_SERVER;
  payload: FetchCategoryResponseData["data"] | null;
};

export type UpdateCategoryEnabledSucceededAction = {
  type: CategoryActionTypes.UPDATE_CATEGORY_ENABLED_SUCCEEDED;
  payload: {
    id: number;
    is_enabled: BooleanNumber;
  };
  meta?: {
    resolve?: (payload?: any) => void;
  };
};

// ---- Saga Action ----

export type FetchPrimaryCategoriesSagaAction = {
  type: CategoryActionTypes.FETCH_PRIMARY_CATEGORIES_SAGA;
  payload?: Pick<FetchCategoriesPayload, "cancelToken"> & {
    params?: Partial<
      Omit<NonNullable<FetchCategoriesPayload["params"]>, "filters">
    > & {
      filters?: Partial<
        Omit<
          NonNullable<NonNullable<FetchCategoriesPayload["params"]>["filters"]>,
          "level"
        >
      >;
    };
  };
  meta?: {
    isLoadMore?: boolean;
    isReset?: boolean;
    resolve?: (payload?: any) => void;
  };
};

export type FetchSecondaryCategoriesSagaAction = {
  type: CategoryActionTypes.FETCH_SECONDARY_CATEGORIES_SAGA;
  payload?: Pick<FetchCategoriesPayload, "cancelToken"> & {
    params?: Partial<
      Omit<NonNullable<FetchCategoriesPayload["params"]>, "filters">
    > & {
      filters?: Partial<
        Omit<
          NonNullable<NonNullable<FetchCategoriesPayload["params"]>["filters"]>,
          "level"
        >
      >;
    };
  };
  meta?: {
    isLoadMore?: boolean;
    isReset?: boolean;
    resolve?: (payload?: any) => void;
  };
};

export type UpdateCategoryEnabledSagaAction = {
  type: CategoryActionTypes.UPDATE_CATEGORY_ENABLED_SAGA;
  payload: UpdateCategoryEnabledPayload;
  meta?: {
    resolve?: (payload?: any) => void;
  };
};

export type UpdateCategorySagaAction = {
  type: CategoryActionTypes.UPDATE_CATEGORY_SAGA;
  payload: UpdateCategoryPayload;
  meta?: {
    resolve?: (payload?: any) => void;
  };
};

export type CreateCategorySagaAction = {
  type: CategoryActionTypes.CREATE_CATEGORY_SAGA;
  payload: CreateCategoryPayload;
  meta?: {
    resolve?: (payload?: any) => void;
  };
};

export type DeleteCategorySagaAction = {
  type: CategoryActionTypes.DELETE_CATEGORY_SAGA;
  payload: DeleteCategoryPayload;
  meta?: {
    resolve?: (payload?: any) => void;
  };
};

export type CategoryAction =
  | FetchRequestedAction
  | FetchSucceededAction
  | FetchFailedAction
  | FetchCategorySucceededServerAction
  | UpdateCategoryEnabledSucceededAction
  //
  | FetchPrimaryCategoriesSagaAction
  | FetchSecondaryCategoriesSagaAction
  | UpdateCategoryEnabledSagaAction
  | UpdateCategorySagaAction
  | CreateCategorySagaAction
  | DeleteCategorySagaAction;
