import type {
  FetchAppointmentCountReportsPayload,
  FetchAppointmentCountReportsResponseData,
  FetchBeautyCenterAppointmentChargeTotalReportsPayload,
  FetchBeautyCenterAppointmentChargeTotalReportsResponseData,
  FetchBeautyCenterAppointmentCountReportsPayload,
  FetchBeautyCenterAppointmentCountReportsResponseData,
} from "@/utils/apis/report";

export enum AppointmentReportActionTypes {
  FETCH_REQUESTED = "@@report/FETCH_REQUESTED",
  FETCH_SUCCEEDED = "@@report/FETCH_SUCCEEDED",
  FETCH_FAILED = "@@report/FETCH_FAILED",

  RESET_ALL_REPORTS = "@@report/RESET_ALL_REPORTS",

  // Saga
  FETCH_APPOINTMENT_COUNT_REPORTS_SAGA = "@@report/FETCH_APPOINTMENT_COUNT_REPORTS_SAGA",
  FETCH_BEAUTY_CENTER_APPOINTMENT_COUNT_REPORTS_SAGA = "@@report/FETCH_BEAUTY_CENTER_APPOINTMENT_COUNT_REPORTS_SAGA",
  FETCH_BEAUTY_CENTER_APPOINTMENT_CHARGE_TOTAL_REPORTS_SAGA = "@@report/FETCH_BEAUTY_CENTER_APPOINTMENT_CHARGE_TOTAL_REPORTS_SAGA",
}

// State

export interface ReportState {
  appointmentCountReports:
    | FetchAppointmentCountReportsResponseData["data"]
    | null;
  appointmentCountReportsLoading: boolean;
  appointmentCountReportsError: string;

  beautyCenterAppointmentCountReports: FetchBeautyCenterAppointmentCountReportsResponseData["data"];
  beautyCenterAppointmentCountReportsLoading: boolean;
  beautyCenterAppointmentCountReportsError: string;

  beautyCenterAppointmentChargeTotalReports: FetchBeautyCenterAppointmentChargeTotalReportsResponseData["data"];
  beautyCenterAppointmentChargeTotalReportsLoading: boolean;
  beautyCenterAppointmentChargeTotalReportsError: string;
}

// ---- Reducer Action ----

export type FetchScope =
  | "appointmentCountReports"
  | "beautyCenterAppointmentCountReports"
  | "beautyCenterAppointmentChargeTotalReports";

export type FetchRequestedAction = {
  type: AppointmentReportActionTypes.FETCH_REQUESTED;
  payload: {
    scope: FetchScope;
    isReset?: boolean;
  };
};

export type FetchSucceededAction = {
  type: AppointmentReportActionTypes.FETCH_SUCCEEDED;
  payload: {
    scope: FetchScope;
    data: ReportState[FetchScope];
    count?: number;
    isLoadMore?: boolean;
  };
};

export type FetchFailedAction = {
  type: AppointmentReportActionTypes.FETCH_FAILED;
  payload: {
    scope: FetchScope;
    error: string;
  };
};

export type ResetAllReportsAction = {
  type: AppointmentReportActionTypes.RESET_ALL_REPORTS;
};

// ---- Saga Action ----

export type FetchAppointmentCountReportsSagaAction = {
  type: AppointmentReportActionTypes.FETCH_APPOINTMENT_COUNT_REPORTS_SAGA;
  payload: FetchAppointmentCountReportsPayload;
  meta?: {
    isReset?: boolean;
    resolve?: (payload?: any) => void;
  };
};

export type FetchBeautyCenterAppointmentCountReportsSagaAction = {
  type: AppointmentReportActionTypes.FETCH_BEAUTY_CENTER_APPOINTMENT_COUNT_REPORTS_SAGA;
  payload: FetchBeautyCenterAppointmentCountReportsPayload;
  meta?: {
    isReset?: boolean;
    resolve?: (payload?: any) => void;
  };
};

export type FetchBeautyCenterAppointmentChargeTotalReportsSagaAction = {
  type: AppointmentReportActionTypes.FETCH_BEAUTY_CENTER_APPOINTMENT_CHARGE_TOTAL_REPORTS_SAGA;
  payload: FetchBeautyCenterAppointmentChargeTotalReportsPayload;
  meta?: {
    isReset?: boolean;
    resolve?: (payload?: any) => void;
  };
};

export type AppointmentReportAction =
  | FetchRequestedAction
  | FetchSucceededAction
  | FetchFailedAction
  | ResetAllReportsAction
  //
  | FetchAppointmentCountReportsSagaAction
  | FetchBeautyCenterAppointmentCountReportsSagaAction
  | FetchBeautyCenterAppointmentChargeTotalReportsSagaAction;
