import type {
  FetchAppointmentRescheduledRequestsPayload,
  FetchAppointmentRescheduledRequestsResponseData,
} from "@/utils/apis/appointmentRescheduledRequest";

export enum AppointmentRescheduledRequestActionTypes {
  FETCH_REQUESTED = "@@appointmentRescheduledRequest/FETCH_REQUESTED",
  FETCH_SUCCEEDED = "@@appointmentRescheduledRequest/FETCH_SUCCEEDED",
  FETCH_FAILED = "@@appointmentRescheduledRequest/FETCH_FAILED",
  // Saga
  FETCH_APPOINTMENT_RESCHEDULED_REQUESTS_SAGA = "@@appointmentRescheduledRequest/FETCH_APPOINTMENT_RESCHEDULED_REQUESTS_SAGA",
}

// State

export interface AppointmentRescheduledRequestState {
  appointmentRescheduledRequests: FetchAppointmentRescheduledRequestsResponseData["data"];
  appointmentRescheduledRequestsLoading: boolean;
  appointmentRescheduledRequestsError: string;
}

// ---- Reducer Action ----

export type FetchScope = "appointmentRescheduledRequests";

export type FetchRequestedAction = {
  type: AppointmentRescheduledRequestActionTypes.FETCH_REQUESTED;
  payload: {
    scope: FetchScope;
    isReset?: boolean;
  };
};

export type FetchSucceededAction = {
  type: AppointmentRescheduledRequestActionTypes.FETCH_SUCCEEDED;
  payload: {
    scope: FetchScope;
    data: AppointmentRescheduledRequestState[FetchScope];
    count?: number;
    isLoadMore?: boolean;
  };
};

export type FetchFailedAction = {
  type: AppointmentRescheduledRequestActionTypes.FETCH_FAILED;
  payload: {
    scope: FetchScope;
    error: string;
  };
};

// ---- Saga Action ----

export type FetchAppointmentRescheduledRequestsSagaAction = {
  type: AppointmentRescheduledRequestActionTypes.FETCH_APPOINTMENT_RESCHEDULED_REQUESTS_SAGA;
  payload: FetchAppointmentRescheduledRequestsPayload;
  meta?: {
    isLoadMore?: boolean;
    isReset?: boolean;
    resolve?: (payload?: any) => void;
  };
};

export type AppointmentRescheduledRequestAction =
  | FetchRequestedAction
  | FetchSucceededAction
  | FetchFailedAction
  //
  | FetchAppointmentRescheduledRequestsSagaAction;
