import { all, call, put, takeEvery, takeLatest } from "redux-saga/effects";
import axios from "axios";

import { axiosHelpers } from "@/utils/helpers";
import { staffApi } from "@/utils/apis";

import {
  StaffActionTypes,
  CreateStaffSagaAction,
  UpdateStaffSagaAction,
} from "./types";
import {
  fetchRequested,
  fetchFailed,
  fetchSucceeded,
  updateStaffEnabledSucceeded,
} from "./action";

import type {
  FetchScope,
  FetchStaffsSagaAction,
  UpdateStaffEnabledSagaAction,
  DeleteStaffSagaAction,
} from "./types";

function* fetchStaffsSaga(action: FetchStaffsSagaAction) {
  const { params, cancelToken } = action.payload || {};
  const { resolve = () => {}, isLoadMore, isReset } = action.meta || {};
  const scope = "staffs" as FetchScope;
  yield put(
    fetchRequested({
      scope,
      isReset,
    })
  );
  try {
    const { data: response }: Awaited<ReturnType<typeof staffApi.fetchStaffs>> =
      yield call(staffApi.fetchStaffs, {
        params,
        cancelToken,
      });
    if (axiosHelpers.checkRequestSuccess(response)) {
      yield put(
        fetchSucceeded({
          scope,
          data: response.data?.data ?? [],
          count: response.data?.pagination?.total ?? 0,
          isLoadMore: !!isLoadMore,
        })
      );
    } else {
      yield put(
        fetchFailed({
          scope,
          error: response.message,
        })
      );
    }
    resolve(response);
  } catch (error) {
    if (axios.isCancel(error)) {
      resolve({ message: error.message, isCancelled: true });
      return;
    }
    const message = axiosHelpers.getErrorMessage(error);
    yield put(
      fetchFailed({
        scope,
        error: message,
      })
    );
    resolve({ message });
  }
}

function* updateStaffEnabledSaga(action: UpdateStaffEnabledSagaAction) {
  const { params, cancelToken } = action.payload;
  const { resolve = () => {} } = action.meta || {};

  try {
    const {
      data: response,
    }: Awaited<ReturnType<typeof staffApi.updateStaffEnabled>> = yield call(
      staffApi.updateStaffEnabled,
      {
        params,
        cancelToken,
      }
    );
    if (axiosHelpers.checkRequestSuccess(response)) {
      yield put(
        updateStaffEnabledSucceeded({
          id: params.id,
          is_enabled: params.is_enabled,
        })
      );
    }
    resolve(response);
  } catch (error) {
    if (axios.isCancel(error)) {
      resolve({ message: error.message, isCancelled: true });
      return;
    }
    const message = axiosHelpers.getErrorMessage(error);
    resolve({ message });
  }
}

function* updateStaffSaga(action: UpdateStaffSagaAction) {
  const { params, cancelToken } = action.payload;
  const { resolve = () => {} } = action.meta || {};

  try {
    const { data: response }: Awaited<ReturnType<typeof staffApi.updateStaff>> =
      yield call(staffApi.updateStaff, {
        params,
        cancelToken,
      });
    resolve(response);
  } catch (error) {
    if (axios.isCancel(error)) {
      resolve({ message: error.message, isCancelled: true });
      return;
    }
    const message = axiosHelpers.getErrorMessage(error);
    resolve({ message });
  }
}

function* createStaffSaga(action: CreateStaffSagaAction) {
  const { params, cancelToken } = action.payload;
  const { resolve = () => {} } = action.meta || {};

  try {
    const { data: response }: Awaited<ReturnType<typeof staffApi.createStaff>> =
      yield call(staffApi.createStaff, {
        params,
        cancelToken,
      });
    resolve(response);
  } catch (error) {
    if (axios.isCancel(error)) {
      resolve({ message: error.message, isCancelled: true });
      return;
    }
    const message = axiosHelpers.getErrorMessage(error);
    resolve({ message });
  }
}

function* deleteStaffSaga(action: DeleteStaffSagaAction) {
  const { params, cancelToken } = action.payload;
  const { resolve = () => {} } = action.meta || {};

  try {
    const { data: response }: Awaited<ReturnType<typeof staffApi.deleteStaff>> =
      yield call(staffApi.deleteStaff, {
        params,
        cancelToken,
      });
    resolve(response);
  } catch (error) {
    if (axios.isCancel(error)) {
      resolve({ message: error.message, isCancelled: true });
      return;
    }
    const message = axiosHelpers.getErrorMessage(error);
    resolve({ message });
  }
}

function* staffSaga() {
  yield all([
    takeEvery(StaffActionTypes.FETCH_STAFFS_SAGA, fetchStaffsSaga),
    takeLatest(
      StaffActionTypes.UPDATE_STAFF_STATUS_SAGA,
      updateStaffEnabledSaga
    ),
    takeLatest(StaffActionTypes.UPDATE_STAFF_SAGA, updateStaffSaga),
    takeLatest(StaffActionTypes.CREATE_STAFF_SAGA, createStaffSaga),
    takeLatest(StaffActionTypes.DELETE_STAFF_SAGA, deleteStaffSaga),
  ]);
}

export default staffSaga;
