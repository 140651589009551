import { EmailActionTypes } from "./types";
import type {
  FetchRequestedAction,
  FetchSucceededAction,
  FetchFailedAction,
  // Saga
  FetchEmailsSagaAction,
  CreateEmailSagaAction,
  DeleteEmailSagaAction,
} from "./types";

// ---- REDUCER ACTION ----

export const fetchRequested = (
  payload: FetchRequestedAction["payload"]
): FetchRequestedAction => ({
  type: EmailActionTypes.FETCH_REQUESTED,
  payload,
});

export const fetchSucceeded = (
  payload: FetchSucceededAction["payload"]
): FetchSucceededAction => ({
  type: EmailActionTypes.FETCH_SUCCEEDED,
  payload,
});

export const fetchFailed = (
  payload: FetchFailedAction["payload"]
): FetchFailedAction => ({
  type: EmailActionTypes.FETCH_FAILED,
  payload,
});

// ---- SAGA ACTION ----

export const fetchEmailsSaga = (
  payload?: FetchEmailsSagaAction["payload"],
  meta?: FetchEmailsSagaAction["meta"]
): FetchEmailsSagaAction => ({
  type: EmailActionTypes.FETCH_EMAILS_SAGA,
  payload,
  meta,
});

export const createEmailSaga = (
  payload: CreateEmailSagaAction["payload"],
  meta?: CreateEmailSagaAction["meta"]
): CreateEmailSagaAction => ({
  type: EmailActionTypes.CREATE_EMAIL_SAGA,
  payload,
  meta,
});

export const deleteEmailSaga = (
  payload: DeleteEmailSagaAction["payload"],
  meta?: DeleteEmailSagaAction["meta"]
): DeleteEmailSagaAction => ({
  type: EmailActionTypes.DELETE_EMAIL_SAGA,
  payload,
  meta,
});
