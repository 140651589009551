import { authAxios } from "@/libs/axios";

import type {
  FetchAppointmentLogsPayload,
  FetchAppointmentLogsResponseData,
} from "./appointmentLog.api.types";
import type { AxiosResponseData } from "@/libs/axios";

const appointmentLogApi = {
  fetchAppointmentLogs: (payload?: FetchAppointmentLogsPayload) => {
    return authAxios.get<AxiosResponseData<FetchAppointmentLogsResponseData>>(
      "appointment_changed_logs",
      {
        params: payload?.params,
        cancelToken: payload?.cancelToken,
      }
    );
  },
};

export default appointmentLogApi;
