import { authAxios } from "@/libs/axios";

import type {
  FetchSmsLogsPayload,
  FetchSmsLogsResponseData,
  ResendSmsPayload,
} from "./smsLog.api.types";
import type { AxiosResponseData } from "@/libs/axios";

const smsLogApi = {
  fetchSmsLogs: (payload?: FetchSmsLogsPayload) => {
    return authAxios.get<AxiosResponseData<FetchSmsLogsResponseData>>(
      "sms_histories",
      {
        params: payload?.params,
        cancelToken: payload?.cancelToken,
      }
    );
  },
  resendSms: (payload: ResendSmsPayload) => {
    return authAxios.put<AxiosResponseData>(
      "sms_histories/resend",
      payload.params,
      {
        cancelToken: payload?.cancelToken,
      }
    );
  },
};

export default smsLogApi;
