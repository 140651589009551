import { DistrictActionTypes } from "./types";
import type {
  FetchRequestedAction,
  FetchSucceededAction,
  FetchFailedAction,
  // Saga
  FetchDistrictsSagaAction,
} from "./types";

// ---- REDUCER ACTION ----

export const fetchRequested = (
  payload: FetchRequestedAction["payload"]
): FetchRequestedAction => ({
  type: DistrictActionTypes.FETCH_REQUESTED,
  payload,
});

export const fetchSucceeded = (
  payload: FetchSucceededAction["payload"]
): FetchSucceededAction => ({
  type: DistrictActionTypes.FETCH_SUCCEEDED,
  payload,
});

export const fetchFailed = (
  payload: FetchFailedAction["payload"]
): FetchFailedAction => ({
  type: DistrictActionTypes.FETCH_FAILED,
  payload,
});

// ---- SAGA ACTION ----

export const fetchDistrictsSaga = (
  payload?: FetchDistrictsSagaAction["payload"],
  meta?: FetchDistrictsSagaAction["meta"]
): FetchDistrictsSagaAction => ({
  type: DistrictActionTypes.FETCH_DISTRICTS_SAGA,
  payload,
  meta,
});
