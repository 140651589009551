import { all, call, put, takeEvery, takeLatest } from "redux-saga/effects";
import axios from "axios";

import { axiosHelpers } from "@/utils/helpers";
import { productServiceApi } from "@/utils/apis";

import {
  ProductServiceActionTypes,
  CreateProductServiceSagaAction,
  UpdateProductServiceSagaAction,
  DeleteProductServiceSagaAction,
  FetchBeautyCenterProductServicesSagaAction,
  FetchBeautyCenterProductServiceSettingSagaAction,
  SaveBeautyCenterProductServiceSettingSagaAction,
  DeleteBeautyCenterProductServiceSettingSagaAction,
} from "./types";
import {
  fetchRequested,
  fetchFailed,
  fetchSucceeded,
  updateProductServiceEnabledSucceeded,
} from "./action";

import type {
  FetchScope,
  FetchProductServicesSagaAction,
  UpdateProductServiceEnabledSagaAction,
} from "./types";

function* fetchProductServicesSaga(action: FetchProductServicesSagaAction) {
  const { params, cancelToken } = action.payload || {};
  const { resolve = () => {}, isLoadMore, isReset } = action.meta || {};
  const scope = "productServices" as FetchScope;
  yield put(
    fetchRequested({
      scope,
      isReset,
    })
  );
  try {
    const {
      data: response,
    }: Awaited<ReturnType<typeof productServiceApi.fetchProductServices>> =
      yield call(productServiceApi.fetchProductServices, {
        params,
        cancelToken,
      });
    if (axiosHelpers.checkRequestSuccess(response)) {
      yield put(
        fetchSucceeded({
          scope,
          data: response.data?.data ?? [],
          count: response.data?.pagination?.total ?? 0,
          isLoadMore: !!isLoadMore,
        })
      );
    } else {
      yield put(
        fetchFailed({
          scope,
          error: response.message,
        })
      );
    }
    resolve(response);
  } catch (error) {
    if (axios.isCancel(error)) {
      resolve({ message: error.message, isCancelled: true });
      return;
    }
    const message = axiosHelpers.getErrorMessage(error);
    yield put(
      fetchFailed({
        scope,
        error: message,
      })
    );
    resolve({ message });
  }
}

function* updateProductServiceEnabledSaga(
  action: UpdateProductServiceEnabledSagaAction
) {
  const { params, cancelToken } = action.payload;
  const { resolve = () => {} } = action.meta || {};

  try {
    const {
      data: response,
    }: Awaited<
      ReturnType<typeof productServiceApi.updateProductServiceEnabled>
    > = yield call(productServiceApi.updateProductServiceEnabled, {
      params,
      cancelToken,
    });
    if (axiosHelpers.checkRequestSuccess(response)) {
      yield put(
        updateProductServiceEnabledSucceeded({
          id: params.id,
          is_enabled: params.is_enabled,
        })
      );
    }
    resolve(response);
  } catch (error) {
    if (axios.isCancel(error)) {
      resolve({ message: error.message, isCancelled: true });
      return;
    }
    const message = axiosHelpers.getErrorMessage(error);
    resolve({ message });
  }
}

function* updateProductServiceSaga(action: UpdateProductServiceSagaAction) {
  const { params, cancelToken } = action.payload;
  const { resolve = () => {} } = action.meta || {};

  try {
    const {
      data: response,
    }: Awaited<ReturnType<typeof productServiceApi.updateProductService>> =
      yield call(productServiceApi.updateProductService, {
        params,
        cancelToken,
      });
    resolve(response);
  } catch (error) {
    if (axios.isCancel(error)) {
      resolve({ message: error.message, isCancelled: true });
      return;
    }
    const message = axiosHelpers.getErrorMessage(error);
    resolve({ message });
  }
}

function* createProductServiceSaga(action: CreateProductServiceSagaAction) {
  const { params, cancelToken } = action.payload;
  const { resolve = () => {} } = action.meta || {};

  try {
    const {
      data: response,
    }: Awaited<ReturnType<typeof productServiceApi.createProductService>> =
      yield call(productServiceApi.createProductService, {
        params,
        cancelToken,
      });
    resolve(response);
  } catch (error) {
    if (axios.isCancel(error)) {
      resolve({ message: error.message, isCancelled: true });
      return;
    }
    const message = axiosHelpers.getErrorMessage(error);
    resolve({ message });
  }
}

function* deleteProductServiceSaga(action: DeleteProductServiceSagaAction) {
  const { params, cancelToken } = action.payload;
  const { resolve = () => {} } = action.meta || {};

  try {
    const {
      data: response,
    }: Awaited<ReturnType<typeof productServiceApi.deleteProductService>> =
      yield call(productServiceApi.deleteProductService, {
        params,
        cancelToken,
      });
    resolve(response);
  } catch (error) {
    if (axios.isCancel(error)) {
      resolve({ message: error.message, isCancelled: true });
      return;
    }
    const message = axiosHelpers.getErrorMessage(error);
    resolve({ message });
  }
}

function* fetchBeautyCenterProductServicesSaga(
  action: FetchBeautyCenterProductServicesSagaAction
) {
  const { params, cancelToken } = action.payload;
  const { resolve = () => {}, isLoadMore, isReset } = action.meta || {};
  const scope = "beautyCenterProductServices" as FetchScope;

  yield put(
    fetchRequested({
      scope,
      isReset,
    })
  );
  try {
    const {
      data: response,
    }: Awaited<
      ReturnType<typeof productServiceApi.fetchBeautyCenterProductServices>
    > = yield call(productServiceApi.fetchBeautyCenterProductServices, {
      params,
      cancelToken,
    });
    if (axiosHelpers.checkRequestSuccess(response)) {
      yield put(
        fetchSucceeded({
          scope,
          data: response.data?.data ?? [],
          count: response.data?.pagination?.total ?? 0,
          isLoadMore: !!isLoadMore,
        })
      );
    } else {
      yield put(
        fetchFailed({
          scope,
          error: response.message,
        })
      );
    }
    resolve(response);
  } catch (error) {
    if (axios.isCancel(error)) {
      resolve({ message: error.message, isCancelled: true });
      return;
    }
    const message = axiosHelpers.getErrorMessage(error);
    yield put(
      fetchFailed({
        scope,
        error: message,
      })
    );
    resolve({ message });
  }
}

function* fetchBeautyCenterProductServiceSettingSaga(
  action: FetchBeautyCenterProductServiceSettingSagaAction
) {
  const { params, cancelToken } = action.payload;
  const { resolve = () => {}, isReset } = action.meta || {};
  const scope = "beautyCenterProductServiceSetting" as FetchScope;

  yield put(
    fetchRequested({
      scope,
      isReset,
    })
  );
  try {
    const {
      data: response,
    }: Awaited<
      ReturnType<
        typeof productServiceApi.fetchBeautyCenterProductServiceSetting
      >
    > = yield call(productServiceApi.fetchBeautyCenterProductServiceSetting, {
      params,
      cancelToken,
    });
    if (axiosHelpers.checkRequestSuccess(response)) {
      yield put(
        fetchSucceeded({
          scope,
          data: response.data ?? null,
        })
      );
    } else {
      yield put(
        fetchFailed({
          scope,
          error: response.message,
        })
      );
    }
    resolve(response);
  } catch (error) {
    if (axios.isCancel(error)) {
      resolve({ message: error.message, isCancelled: true });
      return;
    }
    const message = axiosHelpers.getErrorMessage(error);
    yield put(
      fetchFailed({
        scope,
        error: message,
      })
    );
    resolve({ message });
  }
}

function* saveBeautyCenterProductServiceSettingSaga(
  action: SaveBeautyCenterProductServiceSettingSagaAction
) {
  const { params, cancelToken } = action.payload;
  const { resolve = () => {} } = action.meta || {};

  try {
    const {
      data: response,
    }: Awaited<
      ReturnType<typeof productServiceApi.saveBeautyCenterProductServiceSetting>
    > = yield call(productServiceApi.saveBeautyCenterProductServiceSetting, {
      params,
      cancelToken,
    });
    resolve(response);
  } catch (error) {
    if (axios.isCancel(error)) {
      resolve({ message: error.message, isCancelled: true });
      return;
    }
    const message = axiosHelpers.getErrorMessage(error);
    resolve({ message });
  }
}

function* deleteBeautyCenterProductServiceSettingSaga(
  action: DeleteBeautyCenterProductServiceSettingSagaAction
) {
  const { params, cancelToken } = action.payload;
  const { resolve = () => {} } = action.meta || {};

  try {
    const {
      data: response,
    }: Awaited<
      ReturnType<typeof productServiceApi.saveBeautyCenterProductServiceSetting>
    > = yield call(productServiceApi.saveBeautyCenterProductServiceSetting, {
      params: {
        beauty_center_id: params.beauty_center_id,
        product_service_id: params.product_service_id,
        grade_level_id: "",
        unit_price: "" as unknown as number,
      },
      cancelToken,
    });
    resolve(response);
  } catch (error) {
    if (axios.isCancel(error)) {
      resolve({ message: error.message, isCancelled: true });
      return;
    }
    const message = axiosHelpers.getErrorMessage(error);
    resolve({ message });
  }
}

function* productServiceSaga() {
  yield all([
    takeEvery(
      ProductServiceActionTypes.FETCH_PRODUCT_SERVICES_SAGA,
      fetchProductServicesSaga
    ),
    takeLatest(
      ProductServiceActionTypes.UPDATE_PRODUCT_SERVICE_ENABLED_SAGA,
      updateProductServiceEnabledSaga
    ),
    takeLatest(
      ProductServiceActionTypes.UPDATE_PRODUCT_SERVICE_SAGA,
      updateProductServiceSaga
    ),
    takeLatest(
      ProductServiceActionTypes.CREATE_PRODUCT_SERVICE_SAGA,
      createProductServiceSaga
    ),
    takeLatest(
      ProductServiceActionTypes.DELETE_PRODUCT_SERVICE_SAGA,
      deleteProductServiceSaga
    ),
    takeEvery(
      ProductServiceActionTypes.FETCH_BEAUTY_CENTER_PRODUCT_SERVICES_SAGA,
      fetchBeautyCenterProductServicesSaga
    ),
    takeEvery(
      ProductServiceActionTypes.FETCH_BEAUTY_CENTER_PRODUCT_SERVICE_SETTING_SAGA,
      fetchBeautyCenterProductServiceSettingSaga
    ),
    takeEvery(
      ProductServiceActionTypes.SAVE_BEAUTY_CENTER_PRODUCT_SERVICE_SETTING_SAGA,
      saveBeautyCenterProductServiceSettingSaga
    ),
    takeEvery(
      ProductServiceActionTypes.DELETE_BEAUTY_CENTER_PRODUCT_SERVICE_SETTING_SAGA,
      deleteBeautyCenterProductServiceSettingSaga
    ),
  ]);
}

export default productServiceSaga;
