import { authAxios } from "@/libs/axios";

import type {
  FetchAppointmentAutoAssignedBeautyCenterGroupsPayload,
  FetchAppointmentAutoAssignedBeautyCenterGroupsResponseData,
} from "./appointmentAutoAssignedBeautyCenterGroup.api.types";
import type { AxiosResponseData } from "@/libs/axios";

const appointmentAutoAssignedBeautyCenterGroupApi = {
  fetchAppointmentAutoAssignedBeautyCenterGroups: (
    payload: FetchAppointmentAutoAssignedBeautyCenterGroupsPayload
  ) => {
    const { appointment_id } = payload.params;
    return authAxios.get<
      AxiosResponseData<FetchAppointmentAutoAssignedBeautyCenterGroupsResponseData>
    >(`appointments/auto_assign_list/${appointment_id}`, {
      cancelToken: payload?.cancelToken,
    });
  },
};

export default appointmentAutoAssignedBeautyCenterGroupApi;
