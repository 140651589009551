import { authAxios } from "@/libs/axios";

import type {
  FetchCustomersPayload,
  FetchCustomersResponseData,
  FetchOptionCustomersPayload,
  FetchOptionCustomersResponseData,
  CreateCustomerPayload,
  UpdateCustomerPayload,
  UpdateCustomerBlockedPayload,
  DeleteCustomerPayload,
  FetchCustomerPayload,
  FetchCustomerResponseData,
  DownloadCustomersPayload,
  DownloadCustomersSampleFilePayload,
  UploadCustomerPayload,
} from "./customer.api.types";
import type { AxiosResponseData } from "@/libs/axios";

const customerApi = {
  fetchCustomers: (payload?: FetchCustomersPayload) => {
    return authAxios.get<AxiosResponseData<FetchCustomersResponseData>>(
      "customers",
      {
        params: payload?.params,
        cancelToken: payload?.cancelToken,
      }
    );
  },
  fetchOptionCustomers: (payload?: FetchOptionCustomersPayload) => {
    return authAxios.get<AxiosResponseData<FetchOptionCustomersResponseData>>(
      "customers/select_list",
      {
        params: payload?.params,
        cancelToken: payload?.cancelToken,
      }
    );
  },
  fetchCustomer: (payload: FetchCustomerPayload) => {
    const { id, ...params } = payload.params;
    return authAxios.get<AxiosResponseData<FetchCustomerResponseData>>(
      `customers/${id}`,
      {
        params: params,
        cancelToken: payload?.cancelToken,
        headers: payload.headers,
      }
    );
  },
  createCustomer: (payload: CreateCustomerPayload) => {
    return authAxios.post<AxiosResponseData>("customers", payload.params, {
      cancelToken: payload?.cancelToken,
    });
  },
  updateCustomer: (payload: UpdateCustomerPayload) => {
    const { id, ...params } = payload.params;
    return authAxios.put<AxiosResponseData>(`customers/${id}`, params, {
      cancelToken: payload?.cancelToken,
    });
  },
  updateCustomerBlocked: (payload: UpdateCustomerBlockedPayload) => {
    const { id, ...params } = payload.params;
    return authAxios.patch<AxiosResponseData>(`customers/block/${id}`, params, {
      cancelToken: payload?.cancelToken,
    });
  },
  deleteCustomer: (payload: DeleteCustomerPayload) => {
    const { id } = payload.params;
    return authAxios.delete<AxiosResponseData>(`customers/${id}`, {
      cancelToken: payload?.cancelToken,
    });
  },
  downloadCustomers: (payload?: DownloadCustomersPayload) => {
    return authAxios.get<Blob>("customers/export", {
      params: payload?.params,
      cancelToken: payload?.cancelToken,
      responseType: "blob",
    });
  },
  downloadCustomersSampleFile: (
    payload?: DownloadCustomersSampleFilePayload
  ) => {
    return authAxios.get<Blob>("customers/export_sample", {
      cancelToken: payload?.cancelToken,
      responseType: "blob",
      headers: {
        "Content-Type": "blob",
      },
    });
  },
  uploadCustomers: (payload: UploadCustomerPayload) => {
    const { file } = payload.params;
    const fd = new FormData();
    fd.append("file", file);

    return authAxios.post<AxiosResponseData>("customers/import", fd, {
      cancelToken: payload?.cancelToken,
    });
  },
};

export default customerApi;
