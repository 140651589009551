import { all, call, put, takeEvery, takeLatest } from "redux-saga/effects";
import axios from "axios";

import { axiosHelpers } from "@/utils/helpers";
import { categoryApi } from "@/utils/apis";

import {
  CategoryActionTypes,
  CreateCategorySagaAction,
  UpdateCategorySagaAction,
  DeleteCategorySagaAction,
} from "./types";
import {
  fetchRequested,
  fetchFailed,
  fetchSucceeded,
  updateCategoryEnabledSucceeded,
} from "./action";

import type {
  FetchScope,
  FetchPrimaryCategoriesSagaAction,
  FetchSecondaryCategoriesSagaAction,
  UpdateCategoryEnabledSagaAction,
} from "./types";

function* fetchPrimaryCategoriesSaga(action: FetchPrimaryCategoriesSagaAction) {
  const { params, cancelToken } = action.payload || {};
  const { resolve = () => {}, isLoadMore, isReset } = action.meta || {};
  const scope = "primaryCategories" as FetchScope;
  yield put(
    fetchRequested({
      scope,
      isReset,
    })
  );
  try {
    const {
      data: response,
    }: Awaited<ReturnType<typeof categoryApi.fetchCategories>> = yield call(
      categoryApi.fetchCategories,
      {
        params: {
          ...params,
          filters: {
            ...params?.filters,
            level: 1,
          },
        },
        cancelToken,
      }
    );
    if (axiosHelpers.checkRequestSuccess(response)) {
      yield put(
        fetchSucceeded({
          scope,
          data: response.data?.data ?? [],
          count: response.data?.pagination?.total ?? 0,
          isLoadMore: !!isLoadMore,
        })
      );
    } else {
      yield put(
        fetchFailed({
          scope,
          error: response.message,
        })
      );
    }
    resolve(response);
  } catch (error) {
    if (axios.isCancel(error)) {
      resolve({ message: error.message, isCancelled: true });
      return;
    }
    const message = axiosHelpers.getErrorMessage(error);
    yield put(
      fetchFailed({
        scope,
        error: message,
      })
    );
    resolve({ message });
  }
}

function* fetchSecondaryCategoriesSaga(
  action: FetchSecondaryCategoriesSagaAction
) {
  const { params, cancelToken } = action.payload || {};
  const { resolve = () => {}, isLoadMore, isReset } = action.meta || {};
  const scope = "secondaryCategories" as FetchScope;
  yield put(
    fetchRequested({
      scope,
      isReset,
    })
  );
  try {
    const {
      data: response,
    }: Awaited<ReturnType<typeof categoryApi.fetchCategories>> = yield call(
      categoryApi.fetchCategories,
      {
        params: {
          ...params,
          filters: {
            ...params?.filters,
            level: 2,
          },
        },
        cancelToken,
      }
    );
    if (axiosHelpers.checkRequestSuccess(response)) {
      yield put(
        fetchSucceeded({
          scope,
          data: response.data?.data ?? [],
          count: response.data?.pagination?.total ?? 0,
          isLoadMore: !!isLoadMore,
        })
      );
    } else {
      yield put(
        fetchFailed({
          scope,
          error: response.message,
        })
      );
    }
    resolve(response);
  } catch (error) {
    if (axios.isCancel(error)) {
      resolve({ message: error.message, isCancelled: true });
      return;
    }
    const message = axiosHelpers.getErrorMessage(error);
    yield put(
      fetchFailed({
        scope,
        error: message,
      })
    );
    resolve({ message });
  }
}

function* updateCategoryEnabledSaga(action: UpdateCategoryEnabledSagaAction) {
  const { params, cancelToken } = action.payload;
  const { resolve = () => {} } = action.meta || {};

  try {
    const {
      data: response,
    }: Awaited<ReturnType<typeof categoryApi.updateCategoryEnabled>> =
      yield call(categoryApi.updateCategoryEnabled, {
        params,
        cancelToken,
      });
    if (axiosHelpers.checkRequestSuccess(response)) {
      yield put(
        updateCategoryEnabledSucceeded({
          id: params.id,
          is_enabled: params.is_enabled,
        })
      );
    }
    resolve(response);
  } catch (error) {
    if (axios.isCancel(error)) {
      resolve({ message: error.message, isCancelled: true });
      return;
    }
    const message = axiosHelpers.getErrorMessage(error);
    resolve({ message });
  }
}

function* updateCategorySaga(action: UpdateCategorySagaAction) {
  const { params, cancelToken } = action.payload;
  const { resolve = () => {} } = action.meta || {};

  try {
    const {
      data: response,
    }: Awaited<ReturnType<typeof categoryApi.updateCategory>> = yield call(
      categoryApi.updateCategory,
      {
        params,
        cancelToken,
      }
    );
    resolve(response);
  } catch (error) {
    if (axios.isCancel(error)) {
      resolve({ message: error.message, isCancelled: true });
      return;
    }
    const message = axiosHelpers.getErrorMessage(error);
    resolve({ message });
  }
}

function* createCategorySaga(action: CreateCategorySagaAction) {
  const { params, cancelToken } = action.payload;
  const { resolve = () => {} } = action.meta || {};

  try {
    const {
      data: response,
    }: Awaited<ReturnType<typeof categoryApi.createCategory>> = yield call(
      categoryApi.createCategory,
      {
        params,
        cancelToken,
      }
    );
    resolve(response);
  } catch (error) {
    if (axios.isCancel(error)) {
      resolve({ message: error.message, isCancelled: true });
      return;
    }
    const message = axiosHelpers.getErrorMessage(error);
    resolve({ message });
  }
}

function* deleteCategorySaga(action: DeleteCategorySagaAction) {
  const { params, cancelToken } = action.payload;
  const { resolve = () => {} } = action.meta || {};

  try {
    const {
      data: response,
    }: Awaited<ReturnType<typeof categoryApi.deleteCategory>> = yield call(
      categoryApi.deleteCategory,
      {
        params,
        cancelToken,
      }
    );
    resolve(response);
  } catch (error) {
    if (axios.isCancel(error)) {
      resolve({ message: error.message, isCancelled: true });
      return;
    }
    const message = axiosHelpers.getErrorMessage(error);
    resolve({ message });
  }
}

function* categorySaga() {
  yield all([
    takeEvery(
      CategoryActionTypes.FETCH_PRIMARY_CATEGORIES_SAGA,
      fetchPrimaryCategoriesSaga
    ),
    takeEvery(
      CategoryActionTypes.FETCH_SECONDARY_CATEGORIES_SAGA,
      fetchSecondaryCategoriesSaga
    ),
    takeLatest(
      CategoryActionTypes.UPDATE_CATEGORY_ENABLED_SAGA,
      updateCategoryEnabledSaga
    ),
    takeLatest(CategoryActionTypes.UPDATE_CATEGORY_SAGA, updateCategorySaga),
    takeLatest(CategoryActionTypes.CREATE_CATEGORY_SAGA, createCategorySaga),
    takeLatest(CategoryActionTypes.DELETE_CATEGORY_SAGA, deleteCategorySaga),
  ]);
}

export default categorySaga;
