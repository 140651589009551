import { ProductServiceActionTypes } from "./types";
import type {
  FetchRequestedAction,
  FetchSucceededAction,
  FetchFailedAction,
  FetchProductServiceSucceededServerAction,
  UpdateProductServiceEnabledSucceededAction,
  FetchBeautyCenterProductServiceSettingSucceededServerAction,
  // Saga
  FetchProductServicesSagaAction,
  UpdateProductServiceEnabledSagaAction,
  CreateProductServiceSagaAction,
  UpdateProductServiceSagaAction,
  DeleteProductServiceSagaAction,
  FetchBeautyCenterProductServicesSagaAction,
  FetchBeautyCenterProductServiceSettingSagaAction,
  SaveBeautyCenterProductServiceSettingSagaAction,
  DeleteBeautyCenterProductServiceSettingSagaAction,
} from "./types";

// ---- REDUCER ACTION ----

export const fetchRequested = (
  payload: FetchRequestedAction["payload"]
): FetchRequestedAction => ({
  type: ProductServiceActionTypes.FETCH_REQUESTED,
  payload,
});

export const fetchSucceeded = (
  payload: FetchSucceededAction["payload"]
): FetchSucceededAction => ({
  type: ProductServiceActionTypes.FETCH_SUCCEEDED,
  payload,
});

export const fetchFailed = (
  payload: FetchFailedAction["payload"]
): FetchFailedAction => ({
  type: ProductServiceActionTypes.FETCH_FAILED,
  payload,
});

export const fetchProductServiceSucceededServer = (
  payload: FetchProductServiceSucceededServerAction["payload"]
): FetchProductServiceSucceededServerAction => ({
  type: ProductServiceActionTypes.FETCH_PRODUCT_SERVICE_SUCCEEDED_SERVER,
  payload,
});

export const fetchBeautyCenterProductServiceSettingSucceededServer = (
  payload: FetchBeautyCenterProductServiceSettingSucceededServerAction["payload"]
): FetchBeautyCenterProductServiceSettingSucceededServerAction => ({
  type: ProductServiceActionTypes.FETCH_BEAUTY_CENTER_PRODUCT_SERVICE_SETTING_SUCCEEDED_SERVER,
  payload,
});

export const updateProductServiceEnabledSucceeded = (
  payload: UpdateProductServiceEnabledSucceededAction["payload"]
): UpdateProductServiceEnabledSucceededAction => ({
  type: ProductServiceActionTypes.UPDATE_PRODUCT_SERVICE_ENABLED_SUCCEEDED,
  payload,
});

// ---- SAGA ACTION ----

export const fetchProductServicesSaga = (
  payload?: FetchProductServicesSagaAction["payload"],
  meta?: FetchProductServicesSagaAction["meta"]
): FetchProductServicesSagaAction => ({
  type: ProductServiceActionTypes.FETCH_PRODUCT_SERVICES_SAGA,
  payload,
  meta,
});

export const updateProductServiceEnabledSaga = (
  payload: UpdateProductServiceEnabledSagaAction["payload"],
  meta?: UpdateProductServiceEnabledSagaAction["meta"]
): UpdateProductServiceEnabledSagaAction => ({
  type: ProductServiceActionTypes.UPDATE_PRODUCT_SERVICE_ENABLED_SAGA,
  payload,
  meta,
});

export const updateProductServiceSaga = (
  payload: UpdateProductServiceSagaAction["payload"],
  meta?: UpdateProductServiceSagaAction["meta"]
): UpdateProductServiceSagaAction => ({
  type: ProductServiceActionTypes.UPDATE_PRODUCT_SERVICE_SAGA,
  payload,
  meta,
});

export const createProductServiceSaga = (
  payload: CreateProductServiceSagaAction["payload"],
  meta?: CreateProductServiceSagaAction["meta"]
): CreateProductServiceSagaAction => ({
  type: ProductServiceActionTypes.CREATE_PRODUCT_SERVICE_SAGA,
  payload,
  meta,
});

export const deleteProductServiceSaga = (
  payload: DeleteProductServiceSagaAction["payload"],
  meta?: DeleteProductServiceSagaAction["meta"]
): DeleteProductServiceSagaAction => ({
  type: ProductServiceActionTypes.DELETE_PRODUCT_SERVICE_SAGA,
  payload,
  meta,
});

export const fetchBeautyCenterProductServicesSaga = (
  payload: FetchBeautyCenterProductServicesSagaAction["payload"],
  meta?: FetchBeautyCenterProductServicesSagaAction["meta"]
): FetchBeautyCenterProductServicesSagaAction => ({
  type: ProductServiceActionTypes.FETCH_BEAUTY_CENTER_PRODUCT_SERVICES_SAGA,
  payload,
  meta,
});

export const fetchBeautyCenterProductServiceSettingSaga = (
  payload: FetchBeautyCenterProductServiceSettingSagaAction["payload"],
  meta?: FetchBeautyCenterProductServiceSettingSagaAction["meta"]
): FetchBeautyCenterProductServiceSettingSagaAction => ({
  type: ProductServiceActionTypes.FETCH_BEAUTY_CENTER_PRODUCT_SERVICE_SETTING_SAGA,
  payload,
  meta,
});

export const saveBeautyCenterProductServiceSettingSaga = (
  payload: SaveBeautyCenterProductServiceSettingSagaAction["payload"],
  meta?: SaveBeautyCenterProductServiceSettingSagaAction["meta"]
): SaveBeautyCenterProductServiceSettingSagaAction => ({
  type: ProductServiceActionTypes.SAVE_BEAUTY_CENTER_PRODUCT_SERVICE_SETTING_SAGA,
  payload,
  meta,
});

export const deleteBeautyCenterProductServiceSettingSaga = (
  payload: DeleteBeautyCenterProductServiceSettingSagaAction["payload"],
  meta?: DeleteBeautyCenterProductServiceSettingSagaAction["meta"]
): DeleteBeautyCenterProductServiceSettingSagaAction => ({
  type: ProductServiceActionTypes.DELETE_BEAUTY_CENTER_PRODUCT_SERVICE_SETTING_SAGA,
  payload,
  meta,
});
