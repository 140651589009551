import { authAxios } from "@/libs/axios";

import type {
  FetchGradeLevelsPayload,
  FetchGradeLevelsResponseData,
  FetchOptionGradeLevelsPayload,
  FetchOptionGradeLevelsResponseData,
  CreateGradeLevelPayload,
  UpdateGradeLevelPayload,
  UpdateGradeLevelEnabledPayload,
  DeleteGradeLevelPayload,
  FetchGradeLevelPayload,
  FetchGradeLevelResponseData,
  DownloadGradeLevelsPayload
} from "./gradeLevel.api.types";
import type { AxiosResponseData } from "@/libs/axios";

const gradeLevelApi = {
  fetchGradeLevels: (payload?: FetchGradeLevelsPayload) => {
    return authAxios.get<AxiosResponseData<FetchGradeLevelsResponseData>>(
      "grade_levels",
      {
        params: payload?.params,
        cancelToken: payload?.cancelToken,
      }
    );
  },
  fetchOptionGradeLevels: (payload?: FetchOptionGradeLevelsPayload) => {
    return authAxios.get<AxiosResponseData<FetchOptionGradeLevelsResponseData>>(
      "grade_levels/select_list",
      {
        params: payload?.params,
        cancelToken: payload?.cancelToken,
      }
    );
  },
  fetchGradeLevel: (payload: FetchGradeLevelPayload) => {
    const { id, ...params } = payload.params;
    return authAxios.get<AxiosResponseData<FetchGradeLevelResponseData>>(
      `grade_levels/${id}`,
      {
        params: params,
        cancelToken: payload?.cancelToken,
        headers: payload.headers,
      }
    );
  },
  createGradeLevel: (payload: CreateGradeLevelPayload) => {
    return authAxios.post<AxiosResponseData>("grade_levels", payload.params, {
      cancelToken: payload?.cancelToken,
    });
  },
  updateGradeLevel: (payload: UpdateGradeLevelPayload) => {
    const { id, ...params } = payload.params;
    return authAxios.put<AxiosResponseData>(`grade_levels/${id}`, params, {
      cancelToken: payload?.cancelToken,
    });
  },
  updateGradeLevelEnabled: (payload: UpdateGradeLevelEnabledPayload) => {
    const { id, ...params } = payload.params;
    return authAxios.patch<AxiosResponseData>(
      `grade_levels/enable/${id}`,
      params,
      {
        cancelToken: payload?.cancelToken,
      }
    );
  },
  deleteGradeLevel: (payload: DeleteGradeLevelPayload) => {
    const { id } = payload.params;
    return authAxios.delete<AxiosResponseData>(`grade_levels/${id}`, {
      cancelToken: payload?.cancelToken,
    });
  },
  downloadGradeLevels: (payload?: DownloadGradeLevelsPayload) => {
    return authAxios.get<Blob>("grade_levels/export", {
      params: payload?.params,
      cancelToken: payload?.cancelToken,
      responseType: "blob",
    });
  },
};

export default gradeLevelApi;
