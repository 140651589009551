import { PlatformCodeActionTypes } from "./types";
import type {
  FetchRequestedAction,
  FetchSucceededAction,
  FetchFailedAction,
  FetchPlatformCodeSucceededServerAction,
  // Saga
  FetchPlatformCodesSagaAction,
  CreatePlatformCodeSagaAction,
  UpdatePlatformCodeSagaAction,
  DeletePlatformCodeSagaAction,
} from "./types";

// ---- REDUCER ACTION ----

export const fetchRequested = (
  payload: FetchRequestedAction["payload"]
): FetchRequestedAction => ({
  type: PlatformCodeActionTypes.FETCH_REQUESTED,
  payload,
});

export const fetchSucceeded = (
  payload: FetchSucceededAction["payload"]
): FetchSucceededAction => ({
  type: PlatformCodeActionTypes.FETCH_SUCCEEDED,
  payload,
});

export const fetchFailed = (
  payload: FetchFailedAction["payload"]
): FetchFailedAction => ({
  type: PlatformCodeActionTypes.FETCH_FAILED,
  payload,
});

export const fetchPlatformCodeSucceededServer = (
  payload: FetchPlatformCodeSucceededServerAction["payload"]
): FetchPlatformCodeSucceededServerAction => ({
  type: PlatformCodeActionTypes.FETCH_PLATFORM_CODE_SUCCEEDED_SERVER,
  payload,
});

// ---- SAGA ACTION ----

export const fetchPlatformCodesSaga = (
  payload?: FetchPlatformCodesSagaAction["payload"],
  meta?: FetchPlatformCodesSagaAction["meta"]
): FetchPlatformCodesSagaAction => ({
  type: PlatformCodeActionTypes.FETCH_PLATFORM_CODES_SAGA,
  payload,
  meta,
});

export const updatePlatformCodeSaga = (
  payload: UpdatePlatformCodeSagaAction["payload"],
  meta?: UpdatePlatformCodeSagaAction["meta"]
): UpdatePlatformCodeSagaAction => ({
  type: PlatformCodeActionTypes.UPDATE_PLATFORM_CODE_SAGA,
  payload,
  meta,
});

export const createPlatformCodeSaga = (
  payload: CreatePlatformCodeSagaAction["payload"],
  meta?: CreatePlatformCodeSagaAction["meta"]
): CreatePlatformCodeSagaAction => ({
  type: PlatformCodeActionTypes.CREATE_PLATFORM_CODE_SAGA,
  payload,
  meta,
});

export const deletePlatformCodeSaga = (
  payload: DeletePlatformCodeSagaAction["payload"],
  meta?: DeletePlatformCodeSagaAction["meta"]
): DeletePlatformCodeSagaAction => ({
  type: PlatformCodeActionTypes.DELETE_PLATFORM_CODE_SAGA,
  payload,
  meta,
});
