import type {
  FetchPlatformCodesPayload,
  FetchPlatformCodesResponseData,
  FetchPlatformCodeResponseData,
  DeletePlatformCodePayload,
  UpdatePlatformCodePayload,
  CreatePlatformCodePayload,
} from "@/utils/apis/platformCode";

export enum PlatformCodeActionTypes {
  FETCH_REQUESTED = "@@platformCode/FETCH_REQUESTED",
  FETCH_SUCCEEDED = "@@platformCode/FETCH_SUCCEEDED",
  FETCH_FAILED = "@@platformCode/FETCH_FAILED",

  FETCH_PLATFORM_CODE_SUCCEEDED_SERVER = "@@platformCode/FETCH_PLATFORM_CODE_SUCCEEDED_SERVER",

  // Saga
  FETCH_PLATFORM_CODES_SAGA = "@@platformCode/FETCH_PLATFORM_CODES_SAGA",
  UPDATE_PLATFORM_CODE_ENABLED_SAGA = "@@platformCode/UPDATE_PLATFORM_CODE_ENABLED_SAGA",
  UPDATE_PLATFORM_CODE_SAGA = "@@platformCode/UPDATE_PLATFORM_CODE_SAGA",
  CREATE_PLATFORM_CODE_SAGA = "@@platformCode/CREATE_PLATFORM_CODE_SAGA",
  DELETE_PLATFORM_CODE_SAGA = "@@platformCode/DELETE_PLATFORM_CODE_SAGA",
}

// State

export interface PlatformCodeState {
  hydrated?: boolean;

  platformCodes: FetchPlatformCodesResponseData["data"];
  platformCodesLoading: boolean;
  platformCodesError: string;
  platformCodesCount: number;

  platformCode: FetchPlatformCodeResponseData["data"] | null;
  platformCodeLoading: boolean;
  platformCodeError: string;
}

// ---- Reducer Action ----

export type FetchScope = "platformCodes" | "platformCode";

export type FetchRequestedAction = {
  type: PlatformCodeActionTypes.FETCH_REQUESTED;
  payload: {
    scope: FetchScope;
    isReset?: boolean;
  };
};

export type FetchSucceededAction = {
  type: PlatformCodeActionTypes.FETCH_SUCCEEDED;
  payload: {
    scope: FetchScope;
    data: PlatformCodeState[FetchScope];
    count?: number;
    isLoadMore?: boolean;
  };
};

export type FetchFailedAction = {
  type: PlatformCodeActionTypes.FETCH_FAILED;
  payload: {
    scope: FetchScope;
    error: string;
  };
};

export type FetchPlatformCodeSucceededServerAction = {
  type: PlatformCodeActionTypes.FETCH_PLATFORM_CODE_SUCCEEDED_SERVER;
  payload: FetchPlatformCodeResponseData["data"] | null;
};

// ---- Saga Action ----

export type FetchPlatformCodesSagaAction = {
  type: PlatformCodeActionTypes.FETCH_PLATFORM_CODES_SAGA;
  payload?: FetchPlatformCodesPayload;
  meta?: {
    isLoadMore?: boolean;
    isReset?: boolean;
    resolve?: (payload?: any) => void;
  };
};

export type UpdatePlatformCodeSagaAction = {
  type: PlatformCodeActionTypes.UPDATE_PLATFORM_CODE_SAGA;
  payload: UpdatePlatformCodePayload;
  meta?: {
    resolve?: (payload?: any) => void;
  };
};

export type CreatePlatformCodeSagaAction = {
  type: PlatformCodeActionTypes.CREATE_PLATFORM_CODE_SAGA;
  payload: CreatePlatformCodePayload;
  meta?: {
    resolve?: (payload?: any) => void;
  };
};

export type DeletePlatformCodeSagaAction = {
  type: PlatformCodeActionTypes.DELETE_PLATFORM_CODE_SAGA;
  payload: DeletePlatformCodePayload;
  meta?: {
    resolve?: (payload?: any) => void;
  };
};

export type PlatformCodeAction =
  | FetchRequestedAction
  | FetchSucceededAction
  | FetchFailedAction
  | FetchPlatformCodeSucceededServerAction
  //
  | FetchPlatformCodesSagaAction
  | UpdatePlatformCodeSagaAction
  | CreatePlatformCodeSagaAction
  | DeletePlatformCodeSagaAction;
