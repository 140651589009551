import type { RegisterFirebaseNotificationDevicePayload } from "@/utils/apis/common";

export enum CommonActionTypes {
  REGISTER_FIREBASE_NOTIFICATION_DEVICE_REQUESTED = "@@common/REGISTER_FIREBASE_NOTIFICATION_DEVICE_REQUESTED",
  REGISTER_FIREBASE_NOTIFICATION_DEVICE_SUCCEEDED = "@@common/REGISTER_FIREBASE_NOTIFICATION_DEVICE_SUCCEEDED",
  // Saga
  SET_FLOAT_ADMIN_SIDEBAR_OPENED = "@@common/SET_FLOAT_ADMIN_SIDEBAR_OPENED",
  SET_ADMIN_SIDEBAR_COLLAPSE_OPENED = "@@common/SET_ADMIN_SIDEBAR_COLLAPSE_OPENED",
  REGISTER_FIREBASE_NOTIFICATION_DEVICE_SAGA = "@@common/REGISTER_FIREBASE_NOTIFICATION_DEVICE_SAGA",
}

// State

export interface CommonState {
  floatAdminSidebarOpened: boolean;
  adminSidebarCollapseOpened: boolean;
  firebaseNotificationFcmToken: string;
}

// ---- Reducer Action ----

export type SetFloatAdminSidebarOpenedAction = {
  type: CommonActionTypes.SET_FLOAT_ADMIN_SIDEBAR_OPENED;
  payload: boolean;
};

export type SetAdminSidebarCollapseOpenedAction = {
  type: CommonActionTypes.SET_ADMIN_SIDEBAR_COLLAPSE_OPENED;
  payload: boolean;
};

export type RegisterFirebaseNotificationDeviceSucceededAction = {
  type: CommonActionTypes.REGISTER_FIREBASE_NOTIFICATION_DEVICE_SUCCEEDED;
  payload: {
    fcm_token: string;
  };
};

export type RegisterFirebaseNotificationDeviceRequestedAction = {
  type: CommonActionTypes.REGISTER_FIREBASE_NOTIFICATION_DEVICE_REQUESTED;
  payload: {
    fcm_token: string;
  };
};

// ---- Saga Action ----

export type RegisterFirebaseNotificationDeviceSagaAction = {
  type: CommonActionTypes.REGISTER_FIREBASE_NOTIFICATION_DEVICE_SAGA;
  payload: RegisterFirebaseNotificationDevicePayload;
  meta?: {
    resolve?: (payload?: any) => void;
  };
};

export type CommonAction =
  | SetFloatAdminSidebarOpenedAction
  | SetAdminSidebarCollapseOpenedAction
  | RegisterFirebaseNotificationDeviceSucceededAction
  | RegisterFirebaseNotificationDeviceRequestedAction
  // Saga
  | RegisterFirebaseNotificationDeviceSagaAction;
