import { combineReducers } from "@reduxjs/toolkit";

import auth from "./auth/reducer";
import common from "./common/reducer";
import adminUser from "./adminUser/reducer";
import role from "./role/reducer";
import permission from "./permission/reducer";
import beautyCenter from "./beautyCenter/reducer";
import district from "./district/reducer";
import staff from "./staff/reducer";
import productService from "./productService/reducer";
import category from "./category/reducer";
import gradeLevel from "./gradeLevel/reducer";
import platformCode from "./platformCode/reducer";
import customer from "./customer/reducer";
import appointment from "./appointment/reducer";
import appointmentStatus from "./appointmentStatus/reducer";
import appointmentLog from "./appointmentLog/reducer";
import adminActionLog from "./adminActionLog/reducer";
import email from "./email/reducer";
import smsLog from "./smsLog/reducer";
import productServiceBeautyCenterSetting from "./productServiceBeautyCenterSetting/reducer";
import beautyCenterProductServiceSetting from "./beautyCenterProductServiceSetting/reducer";
import appointmentAutoAssignedBeautyCenterGroup from "./appointmentAutoAssignedBeautyCenterGroup/reducer";
import appointmentRescheduledRequest from "./appointmentRescheduledRequest/reducer";
import notification from "./notification/reducer";
import report from "./report/reducer";

const rootReducer = combineReducers({
  auth,
  common,
  adminUser,
  role,
  permission,
  beautyCenter,
  district,
  staff,
  productService,
  category,
  gradeLevel,
  platformCode,
  customer,
  appointment,
  appointmentStatus,
  appointmentLog,
  adminActionLog,
  email,
  smsLog,
  productServiceBeautyCenterSetting,
  beautyCenterProductServiceSetting,
  appointmentAutoAssignedBeautyCenterGroup,
  appointmentRescheduledRequest,
  notification,
  report,
});

export type AppState = ReturnType<typeof rootReducer>;

export default rootReducer;
