import { authAxios } from "@/libs/axios";

import type {
  DeleteProductServiceBeautyCenterSettingsPayload,
  FetchProductServiceBeautyCenterSettingsResponseData,
  FetchProductServiceBeautyCenterSettingsPayload,
  CreateProductServiceBeautyCenterSettingPayload,
  FetchOptionProductServiceBeautyCenterSettingsPayload,
  FetchOptionProductServiceBeautyCenterSettingsResponseData,
  DownloadProductServiceBeautyCenterSettingsPayload,
} from "./productServiceBeautyCenterSetting.api.types";
import type { AxiosResponseData } from "@/libs/axios";

const productServiceBeautyCenterSettingApi = {
  fetchProductServiceBeautyCenterSettings: (
    payload: FetchProductServiceBeautyCenterSettingsPayload
  ) => {
    const { product_service_id, ...params } = payload.params;

    return authAxios.get<
      AxiosResponseData<FetchProductServiceBeautyCenterSettingsResponseData>
    >(
      `beauty_centers_product_services/product_services/${product_service_id}/beauty_centers`,
      {
        params,
        cancelToken: payload?.cancelToken,
      }
    );
  },
  fetchOptionProductServiceBeautyCenterSettings: (
    payload: FetchOptionProductServiceBeautyCenterSettingsPayload
  ) => {
    const { product_service_id, ...params } = payload.params;

    return authAxios.get<
      AxiosResponseData<FetchOptionProductServiceBeautyCenterSettingsResponseData>
    >(
      `beauty_centers_product_services/product_services/${product_service_id}/beauty_centers`,
      {
        params,
        cancelToken: payload?.cancelToken,
      }
    );
  },
  downloadProductServiceBeautyCenterSettings: (
    payload: DownloadProductServiceBeautyCenterSettingsPayload
  ) => {
    const { product_id } = payload.params;
    return authAxios.get<Blob>(
      `beauty_centers_product_services/product_services/${product_id}/export`,
      {
        cancelToken: payload?.cancelToken,
        responseType: "blob",
        headers: {
          "Content-Type": "blob",
        },
      }
    );
  },
  createProductServiceBeautyCenterSetting: (
    payload: CreateProductServiceBeautyCenterSettingPayload
  ) => {
    const { product_service_id, ...params } = payload.params;

    return authAxios.put<AxiosResponseData>(
      `beauty_centers_product_services/product_services/${product_service_id}/beauty_centers/link`,
      params,
      {
        cancelToken: payload?.cancelToken,
      }
    );
  },
  deleteProductServiceBeautyCenterSettings: (
    payload: DeleteProductServiceBeautyCenterSettingsPayload
  ) => {
    const { product_service_id, ...params } = payload.params;

    return authAxios.put<AxiosResponseData>(
      `beauty_centers_product_services/product_services/${product_service_id}/beauty_centers/unlink`,
      params,
      {
        cancelToken: payload?.cancelToken,
      }
    );
  },
};

export default productServiceBeautyCenterSettingApi;
