import { all, call, put, takeEvery } from "redux-saga/effects";
import axios from "axios";

import { axiosHelpers } from "@/utils/helpers";
import { appointmentAutoAssignedBeautyCenterGroupApi } from "@/utils/apis";

import { AppointmentAutoAssignedBeautyCenterGroupActionTypes } from "./types";
import { fetchRequested, fetchFailed, fetchSucceeded } from "./action";

import type {
  FetchScope,
  FetchAppointmentAutoAssignedBeautyCenterGroupsSagaAction,
} from "./types";

function* fetchAppointmentAutoAssignedBeautyCenterGroupsSaga(
  action: FetchAppointmentAutoAssignedBeautyCenterGroupsSagaAction
) {
  const { params, cancelToken } = action.payload;
  const { resolve = () => {}, isLoadMore, isReset } = action.meta || {};
  const scope = "appointmentAutoAssignedBeautyCenterGroups" as FetchScope;
  yield put(
    fetchRequested({
      scope,
      isReset,
    })
  );
  try {
    const {
      data: response,
    }: Awaited<
      ReturnType<
        typeof appointmentAutoAssignedBeautyCenterGroupApi.fetchAppointmentAutoAssignedBeautyCenterGroups
      >
    > = yield call(
      appointmentAutoAssignedBeautyCenterGroupApi.fetchAppointmentAutoAssignedBeautyCenterGroups,
      {
        params,
        cancelToken,
      }
    );
    if (axiosHelpers.checkRequestSuccess(response)) {
      yield put(
        fetchSucceeded({
          scope,
          data: response.data?.data ?? [],
          isLoadMore: !!isLoadMore,
        })
      );
    } else {
      yield put(
        fetchFailed({
          scope,
          error: response.message,
        })
      );
    }
    resolve(response);
  } catch (error) {
    if (axios.isCancel(error)) {
      resolve({ message: error.message, isCancelled: true });
      return;
    }
    const message = axiosHelpers.getErrorMessage(error);
    yield put(
      fetchFailed({
        scope,
        error: message,
      })
    );
    resolve({ message });
  }
}

function* appointmentAutoAssignedBeautyCenterGroupSaga() {
  yield all([
    takeEvery(
      AppointmentAutoAssignedBeautyCenterGroupActionTypes.FETCH_APPOINTMENT_AUTO_ASSIGNED_BEAUTY_CENTER_GROUPS_SAGA,
      fetchAppointmentAutoAssignedBeautyCenterGroupsSaga
    ),
  ]);
}

export default appointmentAutoAssignedBeautyCenterGroupSaga;
