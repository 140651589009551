import { authAxios } from "@/libs/axios";

import type {
  FetchDistrictsPayload,
  FetchDistrictsResponseData,
} from "./district.api.types";
import type { AxiosResponseData } from "@/libs/axios";

const districtApi = {
  fetchDistricts: (payload?: FetchDistrictsPayload) => {
    return authAxios.get<AxiosResponseData<FetchDistrictsResponseData>>(
      "districts/select_list",
      {
        cancelToken: payload?.cancelToken,
      }
    );
  },
};

export default districtApi;
