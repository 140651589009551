import { CategoryActionTypes } from "./types";
import type {
  FetchRequestedAction,
  FetchSucceededAction,
  FetchFailedAction,
  FetchCategorySucceededServerAction,
  UpdateCategoryEnabledSucceededAction,
  // Saga
  FetchPrimaryCategoriesSagaAction,
  FetchSecondaryCategoriesSagaAction,
  UpdateCategoryEnabledSagaAction,
  CreateCategorySagaAction,
  UpdateCategorySagaAction,
  DeleteCategorySagaAction,
} from "./types";

// ---- REDUCER ACTION ----

export const fetchRequested = (
  payload: FetchRequestedAction["payload"]
): FetchRequestedAction => ({
  type: CategoryActionTypes.FETCH_REQUESTED,
  payload,
});

export const fetchSucceeded = (
  payload: FetchSucceededAction["payload"]
): FetchSucceededAction => ({
  type: CategoryActionTypes.FETCH_SUCCEEDED,
  payload,
});

export const fetchFailed = (
  payload: FetchFailedAction["payload"]
): FetchFailedAction => ({
  type: CategoryActionTypes.FETCH_FAILED,
  payload,
});

export const fetchCategorySucceededServer = (
  payload: FetchCategorySucceededServerAction["payload"]
): FetchCategorySucceededServerAction => ({
  type: CategoryActionTypes.FETCH_CATEGORY_SUCCEEDED_SERVER,
  payload,
});

export const updateCategoryEnabledSucceeded = (
  payload: UpdateCategoryEnabledSucceededAction["payload"]
): UpdateCategoryEnabledSucceededAction => ({
  type: CategoryActionTypes.UPDATE_CATEGORY_ENABLED_SUCCEEDED,
  payload,
});

// ---- SAGA ACTION ----

export const fetchPrimaryCategoriesSaga = (
  payload?: FetchPrimaryCategoriesSagaAction["payload"],
  meta?: FetchPrimaryCategoriesSagaAction["meta"]
): FetchPrimaryCategoriesSagaAction => ({
  type: CategoryActionTypes.FETCH_PRIMARY_CATEGORIES_SAGA,
  payload,
  meta,
});

export const fetchSecondaryCategoriesSaga = (
  payload?: FetchSecondaryCategoriesSagaAction["payload"],
  meta?: FetchSecondaryCategoriesSagaAction["meta"]
): FetchSecondaryCategoriesSagaAction => ({
  type: CategoryActionTypes.FETCH_SECONDARY_CATEGORIES_SAGA,
  payload,
  meta,
});

export const updateCategoryEnabledSaga = (
  payload: UpdateCategoryEnabledSagaAction["payload"],
  meta?: UpdateCategoryEnabledSagaAction["meta"]
): UpdateCategoryEnabledSagaAction => ({
  type: CategoryActionTypes.UPDATE_CATEGORY_ENABLED_SAGA,
  payload,
  meta,
});

export const updateCategorySaga = (
  payload: UpdateCategorySagaAction["payload"],
  meta?: UpdateCategorySagaAction["meta"]
): UpdateCategorySagaAction => ({
  type: CategoryActionTypes.UPDATE_CATEGORY_SAGA,
  payload,
  meta,
});

export const createCategorySaga = (
  payload: CreateCategorySagaAction["payload"],
  meta?: CreateCategorySagaAction["meta"]
): CreateCategorySagaAction => ({
  type: CategoryActionTypes.CREATE_CATEGORY_SAGA,
  payload,
  meta,
});

export const deleteCategorySaga = (
  payload: DeleteCategorySagaAction["payload"],
  meta?: DeleteCategorySagaAction["meta"]
): DeleteCategorySagaAction => ({
  type: CategoryActionTypes.DELETE_CATEGORY_SAGA,
  payload,
  meta,
});
