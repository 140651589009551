import type {
  FetchProductServicesPayload,
  FetchProductServicesResponseData,
  FetchProductServiceResponseData,
  UpdateProductServiceEnabledPayload,
  DeleteProductServicePayload,
  UpdateProductServicePayload,
  CreateProductServicePayload,
  FetchBeautyCenterProductServicesResponse,
  FetchBeautyCenterProductServicesPayload,
  FetchBeautyCenterProductServiceSettingResponseData,
  FetchBeautyCenterProductServiceSettingPayload,
  SaveBeautyCenterProductServiceSettingPayload,
} from "@/utils/apis/productService";

export enum ProductServiceActionTypes {
  FETCH_REQUESTED = "@@productService/FETCH_REQUESTED",
  FETCH_SUCCEEDED = "@@productService/FETCH_SUCCEEDED",
  FETCH_FAILED = "@@productService/FETCH_FAILED",

  UPDATE_PRODUCT_SERVICE_ENABLED_SUCCEEDED = "@@productService/UPDATE_PRODUCT_SERVICE_ENABLED_SUCCEEDED",

  FETCH_PRODUCT_SERVICE_SUCCEEDED_SERVER = "@@productService/FETCH_PRODUCT_SERVICE_SUCCEEDED_SERVER",
  FETCH_BEAUTY_CENTER_PRODUCT_SERVICE_SETTING_SUCCEEDED_SERVER = "@@productService/FETCH_BEAUTY_CENTER_PRODUCT_SERVICE_SETTING_SUCCEEDED_SERVER",

  // Saga
  FETCH_BEAUTY_CENTER_PRODUCT_SERVICE_SETTING_SAGA = "@@productService/FETCH_BEAUTY_CENTER_PRODUCT_SERVICE_SETTING_SAGA",
  FETCH_BEAUTY_CENTER_PRODUCT_SERVICES_SAGA = "@@productService/FETCH_BEAUTY_CENTER_PRODUCT_SERVICES_SAGA",
  FETCH_PRODUCT_SERVICES_SAGA = "@@productService/FETCH_PRODUCT_SERVICES_SAGA",
  SAVE_BEAUTY_CENTER_PRODUCT_SERVICE_SETTING_SAGA = "@@productService/SAVE_BEAUTY_CENTER_PRODUCT_SERVICE_SETTING_SAGA",
  DELETE_BEAUTY_CENTER_PRODUCT_SERVICE_SETTING_SAGA = "@@productService/DELETE_BEAUTY_CENTER_PRODUCT_SERVICE_SETTING_SAGA",
  UPDATE_PRODUCT_SERVICE_ENABLED_SAGA = "@@productService/UPDATE_PRODUCT_SERVICE_ENABLED_SAGA",
  UPDATE_PRODUCT_SERVICE_SAGA = "@@productService/UPDATE_PRODUCT_SERVICE_SAGA",
  CREATE_PRODUCT_SERVICE_SAGA = "@@productService/CREATE_PRODUCT_SERVICE_SAGA",
  DELETE_PRODUCT_SERVICE_SAGA = "@@productService/DELETE_PRODUCT_SERVICE_SAGA",
}

// State

export interface ProductServiceState {
  hydrated?: boolean;

  productServices: FetchProductServicesResponseData["data"];
  productServicesLoading: boolean;
  productServicesError: string;
  productServicesCount: number;

  productService: FetchProductServiceResponseData["data"] | null;
  productServiceLoading: boolean;
  productServiceError: string;

  beautyCenterProductServices: FetchBeautyCenterProductServicesResponse["data"];
  beautyCenterProductServicesLoading: boolean;
  beautyCenterProductServicesError: string;
  beautyCenterProductServicesCount: number;

  beautyCenterProductServiceSetting: FetchBeautyCenterProductServiceSettingResponseData | null;
  beautyCenterProductServiceSettingLoading: boolean;
  beautyCenterProductServiceSettingError: string;
}

// ---- Reducer Action ----

export type FetchScope =
  | "productServices"
  | "productService"
  | "beautyCenterProductServices"
  | "beautyCenterProductServiceSetting";

export type FetchRequestedAction = {
  type: ProductServiceActionTypes.FETCH_REQUESTED;
  payload: {
    scope: FetchScope;
    isReset?: boolean;
  };
};

export type FetchSucceededAction = {
  type: ProductServiceActionTypes.FETCH_SUCCEEDED;
  payload: {
    scope: FetchScope;
    data: ProductServiceState[FetchScope];
    count?: number;
    isLoadMore?: boolean;
  };
};

export type FetchFailedAction = {
  type: ProductServiceActionTypes.FETCH_FAILED;
  payload: {
    scope: FetchScope;
    error: string;
  };
};

export type FetchProductServiceSucceededServerAction = {
  type: ProductServiceActionTypes.FETCH_PRODUCT_SERVICE_SUCCEEDED_SERVER;
  payload: FetchProductServiceResponseData["data"] | null;
};

export type FetchBeautyCenterProductServiceSettingSucceededServerAction = {
  type: ProductServiceActionTypes.FETCH_BEAUTY_CENTER_PRODUCT_SERVICE_SETTING_SUCCEEDED_SERVER;
  payload: FetchBeautyCenterProductServiceSettingResponseData | null;
};

export type UpdateProductServiceEnabledSucceededAction = {
  type: ProductServiceActionTypes.UPDATE_PRODUCT_SERVICE_ENABLED_SUCCEEDED;
  payload: {
    id: number;
    is_enabled: BooleanNumber;
  };
  meta?: {
    resolve?: (payload?: any) => void;
  };
};

// ---- Saga Action ----

export type FetchProductServicesSagaAction = {
  type: ProductServiceActionTypes.FETCH_PRODUCT_SERVICES_SAGA;
  payload?: FetchProductServicesPayload;
  meta?: {
    isLoadMore?: boolean;
    isReset?: boolean;
    resolve?: (payload?: any) => void;
  };
};

export type UpdateProductServiceEnabledSagaAction = {
  type: ProductServiceActionTypes.UPDATE_PRODUCT_SERVICE_ENABLED_SAGA;
  payload: UpdateProductServiceEnabledPayload;
  meta?: {
    resolve?: (payload?: any) => void;
  };
};

export type UpdateProductServiceSagaAction = {
  type: ProductServiceActionTypes.UPDATE_PRODUCT_SERVICE_SAGA;
  payload: UpdateProductServicePayload;
  meta?: {
    resolve?: (payload?: any) => void;
  };
};

export type CreateProductServiceSagaAction = {
  type: ProductServiceActionTypes.CREATE_PRODUCT_SERVICE_SAGA;
  payload: CreateProductServicePayload;
  meta?: {
    resolve?: (payload?: any) => void;
  };
};

export type DeleteProductServiceSagaAction = {
  type: ProductServiceActionTypes.DELETE_PRODUCT_SERVICE_SAGA;
  payload: DeleteProductServicePayload;
  meta?: {
    resolve?: (payload?: any) => void;
  };
};

export type FetchBeautyCenterProductServicesSagaAction = {
  type: ProductServiceActionTypes.FETCH_BEAUTY_CENTER_PRODUCT_SERVICES_SAGA;
  payload: FetchBeautyCenterProductServicesPayload;
  meta?: {
    isLoadMore?: boolean;
    isReset?: boolean;
    resolve?: (payload?: any) => void;
  };
};

export type FetchBeautyCenterProductServiceSettingSagaAction = {
  type: ProductServiceActionTypes.FETCH_BEAUTY_CENTER_PRODUCT_SERVICE_SETTING_SAGA;
  payload: FetchBeautyCenterProductServiceSettingPayload;
  meta?: {
    isReset?: boolean;
    resolve?: (payload?: any) => void;
  };
};

export type SaveBeautyCenterProductServiceSettingSagaAction = {
  type: ProductServiceActionTypes.SAVE_BEAUTY_CENTER_PRODUCT_SERVICE_SETTING_SAGA;
  payload: SaveBeautyCenterProductServiceSettingPayload;
  meta?: {
    resolve?: (payload?: any) => void;
  };
};

export type DeleteBeautyCenterProductServiceSettingSagaAction = {
  type: ProductServiceActionTypes.DELETE_BEAUTY_CENTER_PRODUCT_SERVICE_SETTING_SAGA;
  payload: {
    params: Pick<
      SaveBeautyCenterProductServiceSettingPayload["params"],
      "beauty_center_id" | "product_service_id"
    >;
  } & Pick<SaveBeautyCenterProductServiceSettingPayload, "cancelToken">;
  meta?: {
    resolve?: (payload?: any) => void;
  };
};

export type ProductServiceAction =
  | FetchRequestedAction
  | FetchSucceededAction
  | FetchFailedAction
  | FetchProductServiceSucceededServerAction
  | UpdateProductServiceEnabledSucceededAction
  | FetchBeautyCenterProductServiceSettingSucceededServerAction
  //
  | FetchProductServicesSagaAction
  | UpdateProductServiceEnabledSagaAction
  | UpdateProductServiceSagaAction
  | CreateProductServiceSagaAction
  | DeleteProductServiceSagaAction
  | FetchBeautyCenterProductServicesSagaAction
  | FetchBeautyCenterProductServiceSettingSagaAction
  | SaveBeautyCenterProductServiceSettingSagaAction
  | DeleteBeautyCenterProductServiceSettingSagaAction;
