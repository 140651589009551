import { authAxios } from "@/libs/axios";

import type {
  FetchAppointmentRescheduledRequestsPayload,
  FetchAppointmentRescheduledRequestsResponseData,
} from "./appointmentRescheduledRequest.types";
import type { AxiosResponseData } from "@/libs/axios";

const appointmentRescheduledRequestApi = {
  fetchAppointmentRescheduledRequests: (
    payload: FetchAppointmentRescheduledRequestsPayload
  ) => {
    const { appointment_id } = payload.params;
    return authAxios.get<
      AxiosResponseData<FetchAppointmentRescheduledRequestsResponseData>
    >(`appointment_reschedule_requests/${appointment_id}`, {
      params: payload?.params,
      cancelToken: payload?.cancelToken,
    });
  },
};

export default appointmentRescheduledRequestApi;
