import type {
  FetchBeautyCenterProductServiceSettingsPayload,
  FetchBeautyCenterProductServiceSettingsResponseData,
  CreateBeautyCenterProductServiceSettingPayload,
  DeleteBeautyCenterProductServiceSettingsPayload,
} from "@/utils/apis/beautyCenterProductServiceSetting";

export enum BeautyCenterProductServiceSettingActionTypes {
  FETCH_REQUESTED = "@@beautyCenterProductServiceSetting/FETCH_REQUESTED",
  FETCH_SUCCEEDED = "@@beautyCenterProductServiceSetting/FETCH_SUCCEEDED",
  FETCH_FAILED = "@@beautyCenterProductServiceSetting/FETCH_FAILED",

  FETCH_BEAUTY_CENTER_PRODUCT_SERVICE_SETTING_SUCCEEDED_SERVER = "@@beautyCenterProductServiceSetting/FETCH_BEAUTY_CENTER_PRODUCT_SERVICE_SETTING_SUCCEEDED_SERVER",

  // Saga
  FETCH_BEAUTY_CENTER_PRODUCT_SERVICE_SETTINGS_SAGA = "@@beautyCenterProductServiceSetting/FETCH_BEAUTY_CENTER_PRODUCT_SERVICE_SETTINGS_SAGA",
  CREATE_BEAUTY_CENTER_PRODUCT_SERVICE_SETTING_SAGA = "@@beautyCenterProductServiceSetting/CREATE_BEAUTY_CENTER_PRODUCT_SERVICE_SETTING_SAGA",
  DELETE_BEAUTY_CENTER_PRODUCT_SERVICE_SETTINGS_SAGA = "@@beautyCenterProductServiceSetting/DELETE_BEAUTY_CENTER_PRODUCT_SERVICE_SETTINGS_SAGA",
}

// State

export interface BeautyCenterProductServiceSettingState {
  beautyCenterProductServiceSettings: FetchBeautyCenterProductServiceSettingsResponseData["data"];
  beautyCenterProductServiceSettingsLoading: boolean;
  beautyCenterProductServiceSettingsError: string;
  beautyCenterProductServiceSettingsCount: number;
}

// ---- Reducer Action ----

export type FetchScope = "beautyCenterProductServiceSettings";

export type FetchRequestedAction = {
  type: BeautyCenterProductServiceSettingActionTypes.FETCH_REQUESTED;
  payload: {
    scope: FetchScope;
    isReset?: boolean;
  };
};

export type FetchSucceededAction = {
  type: BeautyCenterProductServiceSettingActionTypes.FETCH_SUCCEEDED;
  payload: {
    scope: FetchScope;
    data: BeautyCenterProductServiceSettingState[FetchScope];
    count?: number;
  };
};

export type FetchFailedAction = {
  type: BeautyCenterProductServiceSettingActionTypes.FETCH_FAILED;
  payload: {
    scope: FetchScope;
    error: string;
  };
};

// ---- Saga Action ----

export type FetchBeautyCenterProductServiceSettingsSagaAction = {
  type: BeautyCenterProductServiceSettingActionTypes.FETCH_BEAUTY_CENTER_PRODUCT_SERVICE_SETTINGS_SAGA;
  payload: FetchBeautyCenterProductServiceSettingsPayload;
  meta?: {
    isReset?: boolean;
    resolve?: (payload?: any) => void;
  };
};

export type CreateBeautyCenterProductServiceSettingSagaAction = {
  type: BeautyCenterProductServiceSettingActionTypes.CREATE_BEAUTY_CENTER_PRODUCT_SERVICE_SETTING_SAGA;
  payload: CreateBeautyCenterProductServiceSettingPayload;
  meta?: {
    resolve?: (payload?: any) => void;
  };
};

export type DeleteBeautyCenterProductServiceSettingsSagaAction = {
  type: BeautyCenterProductServiceSettingActionTypes.DELETE_BEAUTY_CENTER_PRODUCT_SERVICE_SETTINGS_SAGA;
  payload: DeleteBeautyCenterProductServiceSettingsPayload;
  meta?: {
    resolve?: (payload?: any) => void;
  };
};

export type BeautyCenterProductServiceSettingAction =
  | FetchRequestedAction
  | FetchSucceededAction
  | FetchFailedAction
  //
  | FetchBeautyCenterProductServiceSettingsSagaAction
  | CreateBeautyCenterProductServiceSettingSagaAction
  | DeleteBeautyCenterProductServiceSettingsSagaAction;
