import { all, call, put, takeEvery } from "redux-saga/effects";
import axios from "axios";

import { axiosHelpers } from "@/utils/helpers";
import { permissionApi } from "@/utils/apis";

import { PermissionActionTypes } from "./types";
import { fetchRequested, fetchFailed, fetchSucceeded } from "./action";

import type { FetchScope, FetchPermissionsSagaAction } from "./types";

function* fetchPermissionsSaga(action: FetchPermissionsSagaAction) {
  const { cancelToken } = action.payload || {};
  const { resolve = () => {}, isReset } = action.meta || {};
  const scope = "permissions" as FetchScope;
  yield put(
    fetchRequested({
      scope,
      isReset,
    })
  );
  try {
    const {
      data: response,
    }: Awaited<ReturnType<typeof permissionApi.fetchPermissions>> = yield call(
      permissionApi.fetchPermissions,
      {
        cancelToken,
      }
    );
    if (axiosHelpers.checkRequestSuccess(response)) {
      yield put(
        fetchSucceeded({
          scope,
          data: response.data?.data ?? [],
        })
      );
    } else {
      yield put(
        fetchFailed({
          scope,
          error: response.message,
        })
      );
    }
    resolve(response);
  } catch (error) {
    if (axios.isCancel(error)) {
      resolve({ message: error.message, isCancelled: true });
      return;
    }
    const message = axiosHelpers.getErrorMessage(error);
    yield put(
      fetchFailed({
        scope,
        error: message,
      })
    );
    resolve({ message });
  }
}

function* permissionSaga() {
  yield all([
    takeEvery(
      PermissionActionTypes.FETCH_PERMISSIONS_SAGA,
      fetchPermissionsSaga
    ),
  ]);
}

export default permissionSaga;
