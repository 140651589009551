import { CustomerActionTypes } from "./types";
import type {
  FetchRequestedAction,
  FetchSucceededAction,
  FetchFailedAction,
  FetchCustomerSucceededServerAction,
  UpdateCustomerBlockedSucceededAction,
  // Saga
  FetchCustomersSagaAction,
  UpdateCustomerBlockedSagaAction,
  CreateCustomerSagaAction,
  UpdateCustomerSagaAction,
  DeleteCustomerSagaAction,
} from "./types";

// ---- REDUCER ACTION ----

export const fetchRequested = (
  payload: FetchRequestedAction["payload"]
): FetchRequestedAction => ({
  type: CustomerActionTypes.FETCH_REQUESTED,
  payload,
});

export const fetchSucceeded = (
  payload: FetchSucceededAction["payload"]
): FetchSucceededAction => ({
  type: CustomerActionTypes.FETCH_SUCCEEDED,
  payload,
});

export const fetchFailed = (
  payload: FetchFailedAction["payload"]
): FetchFailedAction => ({
  type: CustomerActionTypes.FETCH_FAILED,
  payload,
});

export const fetchCustomerSucceededServer = (
  payload: FetchCustomerSucceededServerAction["payload"]
): FetchCustomerSucceededServerAction => ({
  type: CustomerActionTypes.FETCH_CUSTOMER_SUCCEEDED_SERVER,
  payload,
});

export const updateCustomerBlockedSucceeded = (
  payload: UpdateCustomerBlockedSucceededAction["payload"]
): UpdateCustomerBlockedSucceededAction => ({
  type: CustomerActionTypes.UPDATE_CUSTOMER_BLOCKED_SUCCEEDED,
  payload,
});

// ---- SAGA ACTION ----

export const fetchCustomersSaga = (
  payload?: FetchCustomersSagaAction["payload"],
  meta?: FetchCustomersSagaAction["meta"]
): FetchCustomersSagaAction => ({
  type: CustomerActionTypes.FETCH_CUSTOMERS_SAGA,
  payload,
  meta,
});

export const updateCustomerBlockedSaga = (
  payload: UpdateCustomerBlockedSagaAction["payload"],
  meta?: UpdateCustomerBlockedSagaAction["meta"]
): UpdateCustomerBlockedSagaAction => ({
  type: CustomerActionTypes.UPDATE_CUSTOMER_BLOCKED_SAGA,
  payload,
  meta,
});

export const updateCustomerSaga = (
  payload: UpdateCustomerSagaAction["payload"],
  meta?: UpdateCustomerSagaAction["meta"]
): UpdateCustomerSagaAction => ({
  type: CustomerActionTypes.UPDATE_CUSTOMER_SAGA,
  payload,
  meta,
});

export const createCustomerSaga = (
  payload: CreateCustomerSagaAction["payload"],
  meta?: CreateCustomerSagaAction["meta"]
): CreateCustomerSagaAction => ({
  type: CustomerActionTypes.CREATE_CUSTOMER_SAGA,
  payload,
  meta,
});

export const deleteCustomerSaga = (
  payload: DeleteCustomerSagaAction["payload"],
  meta?: DeleteCustomerSagaAction["meta"]
): DeleteCustomerSagaAction => ({
  type: CustomerActionTypes.DELETE_CUSTOMER_SAGA,
  payload,
  meta,
});
