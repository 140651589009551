import type {
  FetchRolesPayload,
  FetchRolesResponseData,
  FetchRoleResponseData,
  UpdateRoleEnabledPayload,
  DeleteRolePayload,
  UpdateRolePayload,
  CreateRolePayload,
} from "@/utils/apis/role";

export enum RoleActionTypes {
  FETCH_REQUESTED = "@@role/FETCH_REQUESTED",
  FETCH_SUCCEEDED = "@@role/FETCH_SUCCEEDED",
  FETCH_FAILED = "@@role/FETCH_FAILED",

  UPDATE_ROLE_ENABLED_SUCCEEDED = "@@role/UPDATE_ROLE_ENABLED_SUCCEEDED",

  FETCH_ROLE_SUCCEEDED_SERVER = "@@role/FETCH_ROLE_SUCCEEDED_SERVER",

  // Saga
  FETCH_ROLES_SAGA = "@@role/FETCH_ROLES_SAGA",
  UPDATE_ROLE_ENABLED_SAGA = "@@role/UPDATE_ROLE_ENABLED_SAGA",
  UPDATE_ROLE_SAGA = "@@role/UPDATE_ROLE_SAGA",
  CREATE_ROLE_SAGA = "@@role/CREATE_ROLE_SAGA",
  DELETE_ROLE_SAGA = "@@role/DELETE_ROLE_SAGA",
}

// State

export interface RoleState {
  hydrated?: boolean;

  roles: FetchRolesResponseData["data"];
  rolesLoading: boolean;
  rolesError: string;
  rolesCount: number;

  role: FetchRoleResponseData["data"] | null;
  roleLoading: boolean;
  roleError: string;
}

// ---- Reducer Action ----

export type FetchScope = "roles" | "role";

export type FetchRequestedAction = {
  type: RoleActionTypes.FETCH_REQUESTED;
  payload: {
    scope: FetchScope;
    isReset?: boolean;
  };
};

export type FetchSucceededAction = {
  type: RoleActionTypes.FETCH_SUCCEEDED;
  payload: {
    scope: FetchScope;
    data: RoleState[FetchScope];
    count?: number;
    isLoadMore?: boolean;
  };
};

export type FetchFailedAction = {
  type: RoleActionTypes.FETCH_FAILED;
  payload: {
    scope: FetchScope;
    error: string;
  };
};

export type FetchRoleSucceededServerAction = {
  type: RoleActionTypes.FETCH_ROLE_SUCCEEDED_SERVER;
  payload: FetchRoleResponseData["data"] | null;
};

export type UpdateRoleEnabledSucceededAction = {
  type: RoleActionTypes.UPDATE_ROLE_ENABLED_SUCCEEDED;
  payload: {
    id: number;
    is_enabled: BooleanNumber;
  };
  meta?: {
    resolve?: (payload?: any) => void;
  };
};

// ---- Saga Action ----

export type FetchRolesSagaAction = {
  type: RoleActionTypes.FETCH_ROLES_SAGA;
  payload?: FetchRolesPayload;
  meta?: {
    isLoadMore?: boolean;
    isReset?: boolean;
    resolve?: (payload?: any) => void;
  };
};

export type UpdateRoleEnabledSagaAction = {
  type: RoleActionTypes.UPDATE_ROLE_ENABLED_SAGA;
  payload: UpdateRoleEnabledPayload;
  meta?: {
    resolve?: (payload?: any) => void;
  };
};

export type UpdateRoleSagaAction = {
  type: RoleActionTypes.UPDATE_ROLE_SAGA;
  payload: UpdateRolePayload;
  meta?: {
    resolve?: (payload?: any) => void;
  };
};

export type CreateRoleSagaAction = {
  type: RoleActionTypes.CREATE_ROLE_SAGA;
  payload: CreateRolePayload;
  meta?: {
    resolve?: (payload?: any) => void;
  };
};

export type DeleteRoleSagaAction = {
  type: RoleActionTypes.DELETE_ROLE_SAGA;
  payload: DeleteRolePayload;
  meta?: {
    resolve?: (payload?: any) => void;
  };
};

export type RoleAction =
  | FetchRequestedAction
  | FetchSucceededAction
  | FetchFailedAction
  | FetchRoleSucceededServerAction
  | UpdateRoleEnabledSucceededAction
  //
  | FetchRolesSagaAction
  | UpdateRoleEnabledSagaAction
  | UpdateRoleSagaAction
  | CreateRoleSagaAction
  | DeleteRoleSagaAction;
