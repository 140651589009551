import { authAxios } from "@/libs/axios";

import type {
  FetchPermissionsPayload,
  FetchPermissionsResponseData,
} from "./permission.api.types";
import type { AxiosResponseData } from "@/libs/axios";

const permissionApi = {
  fetchPermissions: (payload?: FetchPermissionsPayload) => {
    return authAxios.get<AxiosResponseData<FetchPermissionsResponseData>>(
      "permissions",
      {
        cancelToken: payload?.cancelToken,
      }
    );
  },
};

export default permissionApi;
