import { all, call, put, takeEvery, takeLatest } from "redux-saga/effects";
import axios from "axios";

import { axiosHelpers } from "@/utils/helpers";
import { gradeLevelApi } from "@/utils/apis";

import {
  GradeLevelActionTypes,
  CreateGradeLevelSagaAction,
  UpdateGradeLevelSagaAction,
  DeleteGradeLevelSagaAction,
} from "./types";
import {
  fetchRequested,
  fetchFailed,
  fetchSucceeded,
  updateGradeLevelEnabledSucceeded,
} from "./action";

import type {
  FetchScope,
  FetchPrimaryGradeLevelsSagaAction,
  FetchSecondaryGradeLevelsSagaAction,
  UpdateGradeLevelEnabledSagaAction,
} from "./types";

function* fetchPrimaryGradeLevelsSaga(
  action: FetchPrimaryGradeLevelsSagaAction
) {
  const { params, cancelToken } = action.payload || {};
  const { resolve = () => {}, isLoadMore, isReset } = action.meta || {};
  const scope = "primaryGradeLevels" as FetchScope;
  yield put(
    fetchRequested({
      scope,
      isReset,
    })
  );
  try {
    const {
      data: response,
    }: Awaited<ReturnType<typeof gradeLevelApi.fetchGradeLevels>> = yield call(
      gradeLevelApi.fetchGradeLevels,
      {
        params: {
          ...params,
          filters: {
            ...params?.filters,
            level: 1,
          },
        },
        cancelToken,
      }
    );
    if (axiosHelpers.checkRequestSuccess(response)) {
      yield put(
        fetchSucceeded({
          scope,
          data: response.data?.data ?? [],
          count: response.data?.pagination?.total ?? 0,
          isLoadMore: !!isLoadMore,
        })
      );
    } else {
      yield put(
        fetchFailed({
          scope,
          error: response.message,
        })
      );
    }
    resolve(response);
  } catch (error) {
    if (axios.isCancel(error)) {
      resolve({ message: error.message, isCancelled: true });
      return;
    }
    const message = axiosHelpers.getErrorMessage(error);
    yield put(
      fetchFailed({
        scope,
        error: message,
      })
    );
    resolve({ message });
  }
}

function* fetchSecondaryGradeLevelsSaga(
  action: FetchSecondaryGradeLevelsSagaAction
) {
  const { params, cancelToken } = action.payload || {};
  const { resolve = () => {}, isLoadMore, isReset } = action.meta || {};
  const scope = "secondaryGradeLevels" as FetchScope;
  yield put(
    fetchRequested({
      scope,
      isReset,
    })
  );
  try {
    const {
      data: response,
    }: Awaited<ReturnType<typeof gradeLevelApi.fetchGradeLevels>> = yield call(
      gradeLevelApi.fetchGradeLevels,
      {
        params: {
          ...params,
          filters: {
            ...params?.filters,
            level: 2,
          },
        },
        cancelToken,
      }
    );
    if (axiosHelpers.checkRequestSuccess(response)) {
      yield put(
        fetchSucceeded({
          scope,
          data: response.data?.data ?? [],
          count: response.data?.pagination?.total ?? 0,
          isLoadMore: !!isLoadMore,
        })
      );
    } else {
      yield put(
        fetchFailed({
          scope,
          error: response.message,
        })
      );
    }
    resolve(response);
  } catch (error) {
    if (axios.isCancel(error)) {
      resolve({ message: error.message, isCancelled: true });
      return;
    }
    const message = axiosHelpers.getErrorMessage(error);
    yield put(
      fetchFailed({
        scope,
        error: message,
      })
    );
    resolve({ message });
  }
}

function* updateGradeLevelEnabledSaga(
  action: UpdateGradeLevelEnabledSagaAction
) {
  const { params, cancelToken } = action.payload;
  const { resolve = () => {} } = action.meta || {};

  try {
    const {
      data: response,
    }: Awaited<ReturnType<typeof gradeLevelApi.updateGradeLevelEnabled>> =
      yield call(gradeLevelApi.updateGradeLevelEnabled, {
        params,
        cancelToken,
      });
    if (axiosHelpers.checkRequestSuccess(response)) {
      yield put(
        updateGradeLevelEnabledSucceeded({
          id: params.id,
          is_enabled: params.is_enabled,
        })
      );
    }
    resolve(response);
  } catch (error) {
    if (axios.isCancel(error)) {
      resolve({ message: error.message, isCancelled: true });
      return;
    }
    const message = axiosHelpers.getErrorMessage(error);
    resolve({ message });
  }
}

function* updateGradeLevelSaga(action: UpdateGradeLevelSagaAction) {
  const { params, cancelToken } = action.payload;
  const { resolve = () => {} } = action.meta || {};

  try {
    const {
      data: response,
    }: Awaited<ReturnType<typeof gradeLevelApi.updateGradeLevel>> = yield call(
      gradeLevelApi.updateGradeLevel,
      {
        params,
        cancelToken,
      }
    );
    resolve(response);
  } catch (error) {
    if (axios.isCancel(error)) {
      resolve({ message: error.message, isCancelled: true });
      return;
    }
    const message = axiosHelpers.getErrorMessage(error);
    resolve({ message });
  }
}

function* createGradeLevelSaga(action: CreateGradeLevelSagaAction) {
  const { params, cancelToken } = action.payload;
  const { resolve = () => {} } = action.meta || {};

  try {
    const {
      data: response,
    }: Awaited<ReturnType<typeof gradeLevelApi.createGradeLevel>> = yield call(
      gradeLevelApi.createGradeLevel,
      {
        params,
        cancelToken,
      }
    );
    resolve(response);
  } catch (error) {
    if (axios.isCancel(error)) {
      resolve({ message: error.message, isCancelled: true });
      return;
    }
    const message = axiosHelpers.getErrorMessage(error);
    resolve({ message });
  }
}

function* deleteGradeLevelSaga(action: DeleteGradeLevelSagaAction) {
  const { params, cancelToken } = action.payload;
  const { resolve = () => {} } = action.meta || {};

  try {
    const {
      data: response,
    }: Awaited<ReturnType<typeof gradeLevelApi.deleteGradeLevel>> = yield call(
      gradeLevelApi.deleteGradeLevel,
      {
        params,
        cancelToken,
      }
    );
    resolve(response);
  } catch (error) {
    if (axios.isCancel(error)) {
      resolve({ message: error.message, isCancelled: true });
      return;
    }
    const message = axiosHelpers.getErrorMessage(error);
    resolve({ message });
  }
}

function* gradeLevelSaga() {
  yield all([
    takeEvery(
      GradeLevelActionTypes.FETCH_PRIMARY_GRADE_LEVELS_SAGA,
      fetchPrimaryGradeLevelsSaga
    ),
    takeEvery(
      GradeLevelActionTypes.FETCH_SECONDARY_GRADE_LEVELS_SAGA,
      fetchSecondaryGradeLevelsSaga
    ),
    takeLatest(
      GradeLevelActionTypes.UPDATE_GRADE_LEVEL_ENABLED_SAGA,
      updateGradeLevelEnabledSaga
    ),
    takeLatest(
      GradeLevelActionTypes.UPDATE_GRADE_LEVEL_SAGA,
      updateGradeLevelSaga
    ),
    takeLatest(
      GradeLevelActionTypes.CREATE_GRADE_LEVEL_SAGA,
      createGradeLevelSaga
    ),
    takeLatest(
      GradeLevelActionTypes.DELETE_GRADE_LEVEL_SAGA,
      deleteGradeLevelSaga
    ),
  ]);
}

export default gradeLevelSaga;
