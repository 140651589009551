import { BeautyCenterActionTypes } from "./types";
import type {
  FetchRequestedAction,
  FetchSucceededAction,
  FetchFailedAction,
  FetchBeautyCenterSucceededServerAction,
  UpdateBeautyCenterEnabledSucceededAction,
  // Saga
  FetchBeautyCentersSagaAction,
  UpdateBeautyCenterEnabledSagaAction,
  CreateBeautyCenterSagaAction,
  UpdateBeautyCenterSagaAction,
  DeleteBeautyCenterSagaAction,
} from "./types";

// ---- REDUCER ACTION ----

export const fetchRequested = (
  payload: FetchRequestedAction["payload"]
): FetchRequestedAction => ({
  type: BeautyCenterActionTypes.FETCH_REQUESTED,
  payload,
});

export const fetchSucceeded = (
  payload: FetchSucceededAction["payload"]
): FetchSucceededAction => ({
  type: BeautyCenterActionTypes.FETCH_SUCCEEDED,
  payload,
});

export const fetchFailed = (
  payload: FetchFailedAction["payload"]
): FetchFailedAction => ({
  type: BeautyCenterActionTypes.FETCH_FAILED,
  payload,
});

export const fetchBeautyCenterSucceededServer = (
  payload: FetchBeautyCenterSucceededServerAction["payload"]
): FetchBeautyCenterSucceededServerAction => ({
  type: BeautyCenterActionTypes.FETCH_BEAUTY_CENTER_SUCCEEDED_SERVER,
  payload,
});

export const updateBeautyCenterEnabledSucceeded = (
  payload: UpdateBeautyCenterEnabledSucceededAction["payload"]
): UpdateBeautyCenterEnabledSucceededAction => ({
  type: BeautyCenterActionTypes.UPDATE_BEAUTY_CENTER_ENABLED_SUCCEEDED,
  payload,
});

// ---- SAGA ACTION ----

export const fetchBeautyCentersSaga = (
  payload?: FetchBeautyCentersSagaAction["payload"],
  meta?: FetchBeautyCentersSagaAction["meta"]
): FetchBeautyCentersSagaAction => ({
  type: BeautyCenterActionTypes.FETCH_BEAUTY_CENTERS_SAGA,
  payload,
  meta,
});

export const updateBeautyCenterEnabledSaga = (
  payload: UpdateBeautyCenterEnabledSagaAction["payload"],
  meta?: UpdateBeautyCenterEnabledSagaAction["meta"]
): UpdateBeautyCenterEnabledSagaAction => ({
  type: BeautyCenterActionTypes.UPDATE_BEAUTY_CENTER_ENABLED_SAGA,
  payload,
  meta,
});

export const updateBeautyCenterSaga = (
  payload: UpdateBeautyCenterSagaAction["payload"],
  meta?: UpdateBeautyCenterSagaAction["meta"]
): UpdateBeautyCenterSagaAction => ({
  type: BeautyCenterActionTypes.UPDATE_BEAUTY_CENTER_SAGA,
  payload,
  meta,
});

export const createBeautyCenterSaga = (
  payload: CreateBeautyCenterSagaAction["payload"],
  meta?: CreateBeautyCenterSagaAction["meta"]
): CreateBeautyCenterSagaAction => ({
  type: BeautyCenterActionTypes.CREATE_BEAUTY_CENTER_SAGA,
  payload,
  meta,
});

export const deleteBeautyCenterSaga = (
  payload: DeleteBeautyCenterSagaAction["payload"],
  meta?: DeleteBeautyCenterSagaAction["meta"]
): DeleteBeautyCenterSagaAction => ({
  type: BeautyCenterActionTypes.DELETE_BEAUTY_CENTER_SAGA,
  payload,
  meta,
});
