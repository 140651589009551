import { RoleActionTypes } from "./types";
import { commonHelpers } from "@/utils/helpers";
import { HYDRATE } from "next-redux-wrapper";

import type { RoleState, RoleAction } from "./types";

export const initialState: RoleState = {
  roles: [],
  rolesError: "",
  rolesLoading: true,
  rolesCount: 0,

  role: null,
  roleError: "",
  roleLoading: true,
};

const reducer = (state = initialState, action: RoleAction): RoleState => {
  switch (action.type) {
    case HYDRATE as any: {
      const { role, roleError, roleLoading, hydrated } = (action as any).payload
        .role as RoleState;

      const newState = {
        role,
        roleError,
        roleLoading,
      };

      if (typeof hydrated !== "undefined") {
        Object.entries(newState).forEach(([key, _state]) => {
          if (typeof _state === "undefined") delete (newState as any)[key];
        });
      }

      return {
        ...state,
        ...(typeof hydrated !== "undefined" ? newState : {}),
        hydrated: true,
      };
    }

    case RoleActionTypes.FETCH_REQUESTED: {
      const { scope, isReset } = action.payload;

      const newState = {
        ...state,
        ...(typeof state[`${scope}Loading` as keyof typeof state] !==
        "undefined"
          ? {
              [`${scope}Loading`]: true,
            }
          : {}),
        ...(typeof state[`${scope}Error` as keyof typeof state] !== "undefined"
          ? {
              [`${scope}Error`]: "",
            }
          : {}),
      };

      if (isReset) {
        Object.assign(newState, {
          [scope]: Array.isArray(newState[scope]) ? [] : null,
        });
      }

      return newState;
    }
    case RoleActionTypes.FETCH_SUCCEEDED: {
      const { scope, data, count, isLoadMore } = action.payload;

      let newData = data;
      const stateData = state[scope];

      if (isLoadMore && Array.isArray(stateData) && Array.isArray(data)) {
        const filteredData = data.filter((item) => {
          return stateData.every(
            (stateDataItem) => item.id !== stateDataItem.id
          );
        });
        newData = [...stateData, ...filteredData];
      }

      return {
        ...state,
        [scope]: newData,
        ...(typeof state[`${scope}Loading` as keyof typeof state] !==
        "undefined"
          ? {
              [`${scope}Loading`]: false,
            }
          : {}),
        ...(commonHelpers.isNumber(count)
          ? {
              [`${scope}Count`]: count,
            }
          : {}),
      };
    }
    case RoleActionTypes.FETCH_FAILED: {
      const { scope, error } = action.payload;

      return {
        ...state,
        ...(typeof state[`${scope}Loading` as keyof typeof state] !==
        "undefined"
          ? {
              [`${scope}Loading`]: false,
            }
          : {}),
        ...(typeof state[`${scope}Error` as keyof typeof state] !== "undefined"
          ? {
              [`${scope}Error`]: error,
            }
          : {}),
      };
    }

    case RoleActionTypes.FETCH_ROLE_SUCCEEDED_SERVER: {
      return {
        role: action.payload,
        roleLoading: false,
        roleError: "",
        hydrated: true,
      } as Partial<RoleState> as RoleState;
    }

    case RoleActionTypes.UPDATE_ROLE_ENABLED_SUCCEEDED: {
      const { id, is_enabled } = action.payload;

      return {
        ...state,
        roles: state.roles.map((role) => {
          if (role.id === id)
            return {
              ...role,
              is_enabled,
            };
          return role;
        }),
        role:
          state.role?.id === id
            ? {
                ...state.role,
                is_enabled,
              }
            : state.role,
      };
    }

    default: {
      return state;
    }
  }
};

export default reducer;
