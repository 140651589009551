import type {
  FetchStaffsPayload,
  FetchStaffsResponseData,
  FetchStaffResponseData,
  UpdateStaffEnabledPayload,
  UpdateStaffPayload,
  CreateStaffPayload,
  DeleteStaffPayload,
} from "@/utils/apis/staff";

export enum StaffActionTypes {
  FETCH_REQUESTED = "@@staff/FETCH_REQUESTED",
  FETCH_SUCCEEDED = "@@staff/FETCH_SUCCEEDED",
  FETCH_FAILED = "@@staff/FETCH_FAILED",

  UPDATE_STAFF_STATUS_SUCCEEDED = "@@staff/UPDATE_STAFF_STATUS_SUCCEEDED",

  FETCH_STAFF_SUCCEEDED_SERVER = "@@staff/FETCH_STAFF_SUCCEEDED_SERVER",

  // Saga
  FETCH_STAFFS_SAGA = "@@staff/FETCH_STAFFS_SAGA",
  UPDATE_STAFF_STATUS_SAGA = "@@staff/UPDATE_STAFF_STATUS_SAGA",
  UPDATE_STAFF_SAGA = "@@staff/UPDATE_STAFF_SAGA",
  CREATE_STAFF_SAGA = "@@staff/CREATE_STAFF_SAGA",
  DELETE_STAFF_SAGA = "@@staff/DELETE_STAFF_SAGA",
}

// State

export interface StaffState {
  hydrated?: boolean;

  staffs: FetchStaffsResponseData["data"];
  staffsLoading: boolean;
  staffsError: string;
  staffsCount: number;

  staff: FetchStaffResponseData["data"] | null;
  staffLoading: boolean;
  staffError: string;
}

// ---- Reducer Action ----

export type FetchScope = "staffs" | "staff";

export type FetchRequestedAction = {
  type: StaffActionTypes.FETCH_REQUESTED;
  payload: {
    scope: FetchScope;
    isReset?: boolean;
  };
};

export type FetchSucceededAction = {
  type: StaffActionTypes.FETCH_SUCCEEDED;
  payload: {
    scope: FetchScope;
    data: StaffState[FetchScope];
    count?: number;
    isLoadMore?: boolean;
  };
};

export type FetchFailedAction = {
  type: StaffActionTypes.FETCH_FAILED;
  payload: {
    scope: FetchScope;
    error: string;
  };
};

export type FetchStaffSucceededServerAction = {
  type: StaffActionTypes.FETCH_STAFF_SUCCEEDED_SERVER;
  payload: FetchStaffResponseData["data"] | null;
};

export type UpdateStaffEnabledSucceededAction = {
  type: StaffActionTypes.UPDATE_STAFF_STATUS_SUCCEEDED;
  payload: {
    id: number;
    is_enabled: BooleanNumber;
  };
  meta?: {
    resolve?: (payload?: any) => void;
  };
};

// ---- Saga Action ----

export type FetchStaffsSagaAction = {
  type: StaffActionTypes.FETCH_STAFFS_SAGA;
  payload?: FetchStaffsPayload;
  meta?: {
    isLoadMore?: boolean;
    isReset?: boolean;
    resolve?: (payload?: any) => void;
  };
};

export type UpdateStaffEnabledSagaAction = {
  type: StaffActionTypes.UPDATE_STAFF_STATUS_SAGA;
  payload: UpdateStaffEnabledPayload;
  meta?: {
    resolve?: (payload?: any) => void;
  };
};

export type UpdateStaffSagaAction = {
  type: StaffActionTypes.UPDATE_STAFF_SAGA;
  payload: UpdateStaffPayload;
  meta?: {
    resolve?: (payload?: any) => void;
  };
};

export type CreateStaffSagaAction = {
  type: StaffActionTypes.CREATE_STAFF_SAGA;
  payload: CreateStaffPayload;
  meta?: {
    resolve?: (payload?: any) => void;
  };
};

export type DeleteStaffSagaAction = {
  type: StaffActionTypes.DELETE_STAFF_SAGA;
  payload: DeleteStaffPayload;
  meta?: {
    resolve?: (payload?: any) => void;
  };
};

export type StaffAction =
  | FetchRequestedAction
  | FetchSucceededAction
  | FetchFailedAction
  | FetchStaffSucceededServerAction
  | UpdateStaffEnabledSucceededAction
  //
  | FetchStaffsSagaAction
  | UpdateStaffEnabledSagaAction
  | UpdateStaffSagaAction
  | CreateStaffSagaAction
  | DeleteStaffSagaAction;
