import { CategoryActionTypes } from "./types";
import { commonHelpers } from "@/utils/helpers";
import { HYDRATE } from "next-redux-wrapper";

import type { CategoryState, CategoryAction } from "./types";

export const initialState: CategoryState = {
  primaryCategories: [],
  primaryCategoriesError: "",
  primaryCategoriesLoading: true,
  primaryCategoriesCount: 0,

  secondaryCategories: [],
  secondaryCategoriesError: "",
  secondaryCategoriesLoading: true,
  secondaryCategoriesCount: 0,

  category: null,
  categoryError: "",
  categoryLoading: true,
};

const reducer = (
  state = initialState,
  action: CategoryAction
): CategoryState => {
  switch (action.type) {
    case HYDRATE as any: {
      const { category, categoryError, categoryLoading, hydrated } = (
        action as any
      ).payload.category as CategoryState;

      const newState = {
        category,
        categoryError,
        categoryLoading,
      };

      if (typeof hydrated !== "undefined") {
        Object.entries(newState).forEach(([key, _state]) => {
          if (typeof _state === "undefined") delete (newState as any)[key];
        });
      }

      return {
        ...state,
        ...(typeof hydrated !== "undefined" ? newState : {}),
        hydrated: true,
      };
    }

    case CategoryActionTypes.FETCH_REQUESTED: {
      const { scope, isReset } = action.payload;

      const newState = {
        ...state,
        ...(typeof state[`${scope}Loading` as keyof typeof state] !==
        "undefined"
          ? {
              [`${scope}Loading`]: true,
            }
          : {}),
        ...(typeof state[`${scope}Error` as keyof typeof state] !== "undefined"
          ? {
              [`${scope}Error`]: "",
            }
          : {}),
      };

      if (isReset) {
        Object.assign(newState, {
          [scope]: Array.isArray(newState[scope]) ? [] : null,
        });
      }

      return newState;
    }
    case CategoryActionTypes.FETCH_SUCCEEDED: {
      const { scope, data, count, isLoadMore } = action.payload;

      let newData = data;
      const stateData = state[scope];

      if (isLoadMore && Array.isArray(stateData) && Array.isArray(data)) {
        const filteredData = data.filter((item) => {
          return stateData.every(
            (stateDataItem) => item.id !== stateDataItem.id
          );
        });
        newData = [...stateData, ...filteredData];
      }

      return {
        ...state,
        [scope]: newData,
        ...(typeof state[`${scope}Loading` as keyof typeof state] !==
        "undefined"
          ? {
              [`${scope}Loading`]: false,
            }
          : {}),
        ...(commonHelpers.isNumber(count)
          ? {
              [`${scope}Count`]: count,
            }
          : {}),
      };
    }
    case CategoryActionTypes.FETCH_FAILED: {
      const { scope, error } = action.payload;

      return {
        ...state,
        ...(typeof state[`${scope}Loading` as keyof typeof state] !==
        "undefined"
          ? {
              [`${scope}Loading`]: false,
            }
          : {}),
        ...(typeof state[`${scope}Error` as keyof typeof state] !== "undefined"
          ? {
              [`${scope}Error`]: error,
            }
          : {}),
      };
    }

    case CategoryActionTypes.FETCH_CATEGORY_SUCCEEDED_SERVER: {
      return {
        category: action.payload,
        categoryLoading: false,
        categoryError: "",
        hydrated: true,
      } as Partial<CategoryState> as CategoryState;
    }

    case CategoryActionTypes.UPDATE_CATEGORY_ENABLED_SUCCEEDED: {
      const { id, is_enabled } = action.payload;

      return {
        ...state,
        primaryCategories: state.primaryCategories.map((primaryCategory) => {
          if (primaryCategory.id === id)
            return {
              ...primaryCategory,
              is_enabled,
            };
          return primaryCategory;
        }),
        secondaryCategories: state.secondaryCategories.map(
          (secondaryCategory) => {
            if (secondaryCategory.id === id)
              return {
                ...secondaryCategory,
                is_enabled,
              };
            return secondaryCategory;
          }
        ),
        category:
          state.category?.id === id
            ? {
                ...state.category,
                is_enabled,
              }
            : state.category,
      };
    }

    default: {
      return state;
    }
  }
};

export default reducer;
