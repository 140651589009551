import { StaffActionTypes } from "./types";
import type {
  FetchRequestedAction,
  FetchSucceededAction,
  FetchFailedAction,
  FetchStaffSucceededServerAction,
  UpdateStaffEnabledSucceededAction,
  // Saga
  FetchStaffsSagaAction,
  UpdateStaffEnabledSagaAction,
  CreateStaffSagaAction,
  UpdateStaffSagaAction,
  DeleteStaffSagaAction,
} from "./types";

// ---- REDUCER ACTION ----

export const fetchRequested = (
  payload: FetchRequestedAction["payload"]
): FetchRequestedAction => ({
  type: StaffActionTypes.FETCH_REQUESTED,
  payload,
});

export const fetchSucceeded = (
  payload: FetchSucceededAction["payload"]
): FetchSucceededAction => ({
  type: StaffActionTypes.FETCH_SUCCEEDED,
  payload,
});

export const fetchFailed = (
  payload: FetchFailedAction["payload"]
): FetchFailedAction => ({
  type: StaffActionTypes.FETCH_FAILED,
  payload,
});

export const fetchStaffSucceededServer = (
  payload: FetchStaffSucceededServerAction["payload"]
): FetchStaffSucceededServerAction => ({
  type: StaffActionTypes.FETCH_STAFF_SUCCEEDED_SERVER,
  payload,
});

export const updateStaffEnabledSucceeded = (
  payload: UpdateStaffEnabledSucceededAction["payload"]
): UpdateStaffEnabledSucceededAction => ({
  type: StaffActionTypes.UPDATE_STAFF_STATUS_SUCCEEDED,
  payload,
});

// ---- SAGA ACTION ----

export const fetchStaffsSaga = (
  payload?: FetchStaffsSagaAction["payload"],
  meta?: FetchStaffsSagaAction["meta"]
): FetchStaffsSagaAction => ({
  type: StaffActionTypes.FETCH_STAFFS_SAGA,
  payload,
  meta,
});

export const updateStaffEnabledSaga = (
  payload: UpdateStaffEnabledSagaAction["payload"],
  meta?: UpdateStaffEnabledSagaAction["meta"]
): UpdateStaffEnabledSagaAction => ({
  type: StaffActionTypes.UPDATE_STAFF_STATUS_SAGA,
  payload,
  meta,
});

export const updateStaffSaga = (
  payload: UpdateStaffSagaAction["payload"],
  meta?: UpdateStaffSagaAction["meta"]
): UpdateStaffSagaAction => ({
  type: StaffActionTypes.UPDATE_STAFF_SAGA,
  payload,
  meta,
});

export const createStaffSaga = (
  payload: CreateStaffSagaAction["payload"],
  meta?: CreateStaffSagaAction["meta"]
): CreateStaffSagaAction => ({
  type: StaffActionTypes.CREATE_STAFF_SAGA,
  payload,
  meta,
});

export const deleteStaffSaga = (
  payload: DeleteStaffSagaAction["payload"],
  meta?: DeleteStaffSagaAction["meta"]
): DeleteStaffSagaAction => ({
  type: StaffActionTypes.DELETE_STAFF_SAGA,
  payload,
  meta,
});
