import { BeautyCenterProductServiceSettingActionTypes } from "./types";
import type {
  FetchRequestedAction,
  FetchSucceededAction,
  FetchFailedAction,
  // Saga
  FetchBeautyCenterProductServiceSettingsSagaAction,
  CreateBeautyCenterProductServiceSettingSagaAction,
  DeleteBeautyCenterProductServiceSettingsSagaAction,
} from "./types";

// ---- REDUCER ACTION ----

export const fetchRequested = (
  payload: FetchRequestedAction["payload"]
): FetchRequestedAction => ({
  type: BeautyCenterProductServiceSettingActionTypes.FETCH_REQUESTED,
  payload,
});

export const fetchSucceeded = (
  payload: FetchSucceededAction["payload"]
): FetchSucceededAction => ({
  type: BeautyCenterProductServiceSettingActionTypes.FETCH_SUCCEEDED,
  payload,
});

export const fetchFailed = (
  payload: FetchFailedAction["payload"]
): FetchFailedAction => ({
  type: BeautyCenterProductServiceSettingActionTypes.FETCH_FAILED,
  payload,
});

// ---- SAGA ACTION ----

export const fetchBeautyCenterProductServiceSettingsSaga = (
  payload: FetchBeautyCenterProductServiceSettingsSagaAction["payload"],
  meta?: FetchBeautyCenterProductServiceSettingsSagaAction["meta"]
): FetchBeautyCenterProductServiceSettingsSagaAction => ({
  type: BeautyCenterProductServiceSettingActionTypes.FETCH_BEAUTY_CENTER_PRODUCT_SERVICE_SETTINGS_SAGA,
  payload,
  meta,
});

export const createBeautyCenterProductServiceSettingSaga = (
  payload: CreateBeautyCenterProductServiceSettingSagaAction["payload"],
  meta?: CreateBeautyCenterProductServiceSettingSagaAction["meta"]
): CreateBeautyCenterProductServiceSettingSagaAction => ({
  type: BeautyCenterProductServiceSettingActionTypes.CREATE_BEAUTY_CENTER_PRODUCT_SERVICE_SETTING_SAGA,
  payload,
  meta,
});

export const deleteBeautyCenterProductServicesSettingSaga = (
  payload: DeleteBeautyCenterProductServiceSettingsSagaAction["payload"],
  meta?: DeleteBeautyCenterProductServiceSettingsSagaAction["meta"]
): DeleteBeautyCenterProductServiceSettingsSagaAction => ({
  type: BeautyCenterProductServiceSettingActionTypes.DELETE_BEAUTY_CENTER_PRODUCT_SERVICE_SETTINGS_SAGA,
  payload,
  meta,
});
