import { ProductServiceBeautyCenterSettingActionTypes } from "./types";
import { commonHelpers } from "@/utils/helpers";

import type {
  ProductServiceBeautyCenterSettingState,
  ProductServiceBeautyCenterSettingAction,
} from "./types";

export const initialState: ProductServiceBeautyCenterSettingState = {
  productServiceBeautyCenterSettings: [],
  productServiceBeautyCenterSettingsError: "",
  productServiceBeautyCenterSettingsLoading: true,
  productServiceBeautyCenterSettingsCount: 0,
};

const reducer = (
  state = initialState,
  action: ProductServiceBeautyCenterSettingAction
): ProductServiceBeautyCenterSettingState => {
  switch (action.type) {
    case ProductServiceBeautyCenterSettingActionTypes.FETCH_REQUESTED: {
      const { scope, isReset } = action.payload;

      const newState = {
        ...state,
        ...(typeof state[`${scope}Loading` as keyof typeof state] !==
        "undefined"
          ? {
              [`${scope}Loading`]: true,
            }
          : {}),
        ...(typeof state[`${scope}Error` as keyof typeof state] !== "undefined"
          ? {
              [`${scope}Error`]: "",
            }
          : {}),
      };

      if (isReset) {
        Object.assign(newState, {
          [scope]: Array.isArray(newState[scope]) ? [] : null,
        });
      }

      return newState;
    }
    case ProductServiceBeautyCenterSettingActionTypes.FETCH_SUCCEEDED: {
      const { scope, data, count } = action.payload;

      let newData = data;

      return {
        ...state,
        [scope]: newData,
        ...(typeof state[`${scope}Loading` as keyof typeof state] !==
        "undefined"
          ? {
              [`${scope}Loading`]: false,
            }
          : {}),
        ...(commonHelpers.isNumber(count)
          ? {
              [`${scope}Count`]: count,
            }
          : {}),
      };
    }
    case ProductServiceBeautyCenterSettingActionTypes.FETCH_FAILED: {
      const { scope, error } = action.payload;

      return {
        ...state,
        ...(typeof state[`${scope}Loading` as keyof typeof state] !==
        "undefined"
          ? {
              [`${scope}Loading`]: false,
            }
          : {}),
        ...(typeof state[`${scope}Error` as keyof typeof state] !== "undefined"
          ? {
              [`${scope}Error`]: error,
            }
          : {}),
      };
    }

    default: {
      return state;
    }
  }
};

export default reducer;
