import type {
  FetchSmsLogsPayload,
  FetchSmsLogsResponseData,
  ResendSmsPayload,
} from "@/utils/apis/smsLog";

export enum SmsLogActionTypes {
  FETCH_REQUESTED = "@@smsLog/FETCH_REQUESTED",
  FETCH_SUCCEEDED = "@@smsLog/FETCH_SUCCEEDED",
  FETCH_FAILED = "@@smsLog/FETCH_FAILED",

  // Saga
  FETCH_SMS_LOGS_SAGA = "@@smsLog/FETCH_SMS_LOGS_SAGA",
  RESEND_SMS_SAGA = "@@smsLog/RESEND_SMS_SAGA",
}

// State

export interface SmsLogState {
  smsLogs: FetchSmsLogsResponseData["data"];
  smsLogsLoading: boolean;
  smsLogsError: string;
  smsLogsCount: number;
}

// ---- Reducer Action ----

export type FetchScope = "smsLogs";

export type FetchRequestedAction = {
  type: SmsLogActionTypes.FETCH_REQUESTED;
  payload: {
    scope: FetchScope;
    isReset?: boolean;
  };
};

export type FetchSucceededAction = {
  type: SmsLogActionTypes.FETCH_SUCCEEDED;
  payload: {
    scope: FetchScope;
    data: SmsLogState[FetchScope];
    count?: number;
    isLoadMore?: boolean;
  };
};

export type FetchFailedAction = {
  type: SmsLogActionTypes.FETCH_FAILED;
  payload: {
    scope: FetchScope;
    error: string;
  };
};

// ---- Saga Action ----

export type FetchSmsLogsSagaAction = {
  type: SmsLogActionTypes.FETCH_SMS_LOGS_SAGA;
  payload?: FetchSmsLogsPayload;
  meta?: {
    isLoadMore?: boolean;
    isReset?: boolean;
    resolve?: (payload?: any) => void;
  };
};

export type ResendSmsSagaAction = {
  type: SmsLogActionTypes.RESEND_SMS_SAGA;
  payload: ResendSmsPayload;
  meta?: {
    resolve?: (payload?: any) => void;
  };
};

export type SmsLogAction =
  | FetchRequestedAction
  | FetchSucceededAction
  | FetchFailedAction
  //
  | FetchSmsLogsSagaAction
  | ResendSmsSagaAction;
