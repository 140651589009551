import type {
  FetchAppointmentLogsPayload,
  FetchAppointmentLogsResponseData,
} from "@/utils/apis/appointmentLog";

export enum AppointmentLogActionTypes {
  FETCH_REQUESTED = "@@appointmentLog/FETCH_REQUESTED",
  FETCH_SUCCEEDED = "@@appointmentLog/FETCH_SUCCEEDED",
  FETCH_FAILED = "@@appointmentLog/FETCH_FAILED",

  // Saga
  FETCH_APPOINTMENT_LOGS_SAGA = "@@appointmentLog/FETCH_APPOINTMENT_LOGS_SAGA",
}

// State

export interface AppointmentLogState {
  hydrated?: boolean;

  appointmentLogs: FetchAppointmentLogsResponseData["data"];
  appointmentLogsLoading: boolean;
  appointmentLogsError: string;
  appointmentLogsCount: number;
}

// ---- Reducer Action ----

export type FetchScope = "appointmentLogs";

export type FetchRequestedAction = {
  type: AppointmentLogActionTypes.FETCH_REQUESTED;
  payload: {
    scope: FetchScope;
    isReset?: boolean;
  };
};

export type FetchSucceededAction = {
  type: AppointmentLogActionTypes.FETCH_SUCCEEDED;
  payload: {
    scope: FetchScope;
    data: AppointmentLogState[FetchScope];
    count?: number;
    isLoadMore?: boolean;
  };
};

export type FetchFailedAction = {
  type: AppointmentLogActionTypes.FETCH_FAILED;
  payload: {
    scope: FetchScope;
    error: string;
  };
};

// ---- Saga Action ----

export type FetchAppointmentLogsSagaAction = {
  type: AppointmentLogActionTypes.FETCH_APPOINTMENT_LOGS_SAGA;
  payload: FetchAppointmentLogsPayload;
  meta?: {
    isLoadMore?: boolean;
    isReset?: boolean;
    resolve?: (payload?: any) => void;
  };
};

export type AppointmentLogAction =
  | FetchRequestedAction
  | FetchSucceededAction
  | FetchFailedAction
  //
  | FetchAppointmentLogsSagaAction;
