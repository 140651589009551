import { all, call, put, takeEvery, takeLatest } from "redux-saga/effects";
import axios from "axios";

import { axiosHelpers } from "@/utils/helpers";
import { notificationApi } from "@/utils/apis";

import { NotificationActionTypes } from "./types";
import {
  fetchRequested,
  fetchFailed,
  fetchSucceeded,
  markReadNotificationSucceeded,
  markAllReadNotificationSucceeded,
} from "./action";

import type {
  FetchNotificationCountItemSagaAction,
  FetchReadNotificationsSagaAction,
  FetchScope,
  FetchUnreadNotificationsSagaAction,
  MarkAllReadNotificationSagaAction,
  MarkReadNotificationSagaAction,
} from "./types";

function* fetchNotificationCountItemSaga(
  action: FetchNotificationCountItemSagaAction
) {
  const { cancelToken } = action.payload || {};
  const { resolve = () => {}, isReset } = action.meta || {};
  const scope = "notificationCountItem" as FetchScope;
  yield put(
    fetchRequested({
      scope,
      isReset,
    })
  );

  try {
    const {
      data: response,
    }: Awaited<ReturnType<typeof notificationApi.fetchNotificationCountItem>> =
      yield call(notificationApi.fetchNotificationCountItem, {
        cancelToken,
      });
    if (axiosHelpers.checkRequestSuccess(response)) {
      yield put(
        fetchSucceeded({
          scope,
          data: response.data?.data ?? [],
        })
      );
    } else {
      yield put(
        fetchFailed({
          scope,
          error: response.message,
        })
      );
    }
    resolve(response);
  } catch (error) {
    if (axios.isCancel(error)) {
      resolve({ message: error.message, isAbsented: true });
      return;
    }
    const message = axiosHelpers.getErrorMessage(error);
    yield put(
      fetchFailed({
        scope,
        error: message,
      })
    );
    resolve({ message });
  }
}

function* fetchReadNotificationsSaga(action: FetchReadNotificationsSagaAction) {
  const { params, cancelToken } = action.payload || {};
  const {
    resolve = () => {},
    isLoadMore,
    isReset,
    disableLoading,
  } = action.meta || {};
  const scope = "readNotifications" as FetchScope;
  yield put(
    fetchRequested({
      scope,
      isReset,
      disableLoading,
    })
  );

  try {
    const {
      data: response,
    }: Awaited<ReturnType<typeof notificationApi.fetchNotifications>> =
      yield call(notificationApi.fetchNotifications, {
        params: {
          ...params,
          filters: {
            ...params?.filters,
            is_read: 1,
          },
        },
        cancelToken,
      });
    if (axiosHelpers.checkRequestSuccess(response)) {
      yield put(
        fetchSucceeded({
          scope,
          data: response.data?.data ?? [],
          count: response.data?.pagination?.total ?? 0,
          isLoadMore: !!isLoadMore,
        })
      );
    } else {
      yield put(
        fetchFailed({
          scope,
          error: response.message,
        })
      );
    }
    resolve(response);
  } catch (error) {
    if (axios.isCancel(error)) {
      resolve({ message: error.message, isAbsented: true });
      return;
    }
    const message = axiosHelpers.getErrorMessage(error);
    yield put(
      fetchFailed({
        scope,
        error: message,
      })
    );
    resolve({ message });
  }
}

function* fetchUnreadNotificationsSaga(
  action: FetchUnreadNotificationsSagaAction
) {
  const { params, cancelToken } = action.payload || {};
  const {
    resolve = () => {},
    isLoadMore,
    isReset,
    disableLoading,
  } = action.meta || {};
  const scope = "unreadNotifications" as FetchScope;
  yield put(
    fetchRequested({
      scope,
      isReset,
      disableLoading,
    })
  );

  try {
    const {
      data: response,
    }: Awaited<ReturnType<typeof notificationApi.fetchNotifications>> =
      yield call(notificationApi.fetchNotifications, {
        params: {
          ...params,
          filters: {
            ...params?.filters,
            is_read: 0,
          },
        },
        cancelToken,
      });
    if (axiosHelpers.checkRequestSuccess(response)) {
      yield put(
        fetchSucceeded({
          scope,
          data: response.data?.data ?? [],
          count: response.data?.pagination?.total ?? 0,
          isLoadMore: !!isLoadMore,
        })
      );
    } else {
      yield put(
        fetchFailed({
          scope,
          error: response.message,
        })
      );
    }
    resolve(response);
  } catch (error) {
    if (axios.isCancel(error)) {
      resolve({ message: error.message, isAbsented: true });
      return;
    }
    const message = axiosHelpers.getErrorMessage(error);
    yield put(
      fetchFailed({
        scope,
        error: message,
      })
    );
    resolve({ message });
  }
}

function* markAllReadNotificationSaga(
  action: MarkAllReadNotificationSagaAction
) {
  const { cancelToken } = action.payload || {};
  const { resolve = () => {} } = action.meta || {};

  try {
    const {
      data: response,
    }: Awaited<ReturnType<typeof notificationApi.markReadNotification>> =
      yield call(notificationApi.markReadNotification, {
        params: {
          notification_id: "",
        },
        cancelToken,
      });
    if (axiosHelpers.checkRequestSuccess(response)) {
      yield put(markAllReadNotificationSucceeded());
    }
    resolve(response);
  } catch (error) {
    if (axios.isCancel(error)) {
      resolve({ message: error.message, isCancelled: true });
      return;
    }
    const message = axiosHelpers.getErrorMessage(error);
    resolve({ message });
  }
}

function* markReadNotificationSaga(action: MarkReadNotificationSagaAction) {
  const { params, cancelToken } = action.payload;
  const { resolve = () => {} } = action.meta || {};

  yield put(markReadNotificationSucceeded());
  try {
    const {
      data: response,
    }: Awaited<ReturnType<typeof notificationApi.markReadNotification>> =
      yield call(notificationApi.markReadNotification, {
        params: {
          beauty_center_id: "",
          ...params,
        },
        cancelToken,
      });
    resolve(response);
  } catch (error) {
    if (axios.isCancel(error)) {
      resolve({ message: error.message, isCancelled: true });
      return;
    }
    const message = axiosHelpers.getErrorMessage(error);
    resolve({ message });
  }
}

function* notificationSaga() {
  yield all([
    takeEvery(
      NotificationActionTypes.FETCH_NOTIFICATION_COUNT_ITEM_SAGA,
      fetchNotificationCountItemSaga
    ),
    takeEvery(
      NotificationActionTypes.FETCH_READ_NOTIFICATIONS_SAGA,
      fetchReadNotificationsSaga
    ),
    takeEvery(
      NotificationActionTypes.FETCH_UNREAD_NOTIFICATIONS_SAGA,
      fetchUnreadNotificationsSaga
    ),
    takeLatest(
      NotificationActionTypes.MARK_ALL_READ_NOTIFICATION_SAGA,
      markAllReadNotificationSaga
    ),
    takeLatest(
      NotificationActionTypes.MARK_READ_NOTIFICATION_SAGA,
      markReadNotificationSaga
    ),
  ]);
}

export default notificationSaga;
