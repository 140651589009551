import {
  registerFirebaseNotificationDeviceRequested,
  registerFirebaseNotificationDeviceSucceeded,
} from "./action";
import { all, call, put, takeLatest } from "redux-saga/effects";
import axios from "axios";

import { axiosHelpers } from "@/utils/helpers";
import { commonApi } from "@/utils/apis";

import { CommonActionTypes } from "./types";

import type { RegisterFirebaseNotificationDeviceSagaAction } from "./types";

function* registerFirebaseNotificationDeviceSaga(
  action: RegisterFirebaseNotificationDeviceSagaAction
) {
  const { params, cancelToken } = action.payload;
  const { resolve = () => {} } = action.meta || {};
  yield put(
    registerFirebaseNotificationDeviceRequested({
      fcm_token: params.fcm_token,
    })
  );
  try {
    const {
      data: response,
    }: Awaited<
      ReturnType<typeof commonApi.registerFirebaseNotificationDevice>
    > = yield call(commonApi.registerFirebaseNotificationDevice, {
      params,
      cancelToken,
    });
    if (!axiosHelpers.checkRequestSuccess(response)) {
      yield put(
        registerFirebaseNotificationDeviceSucceeded({
          fcm_token: "",
        })
      );
    }
    resolve(response);
  } catch (error) {
    if (axios.isCancel(error)) {
      resolve({ message: error.message, isCancelled: true });
      return;
    }
    const message = axiosHelpers.getErrorMessage(error);
    resolve({ message });
  }
}

function* commonSaga() {
  yield all([
    takeLatest(
      CommonActionTypes.REGISTER_FIREBASE_NOTIFICATION_DEVICE_SAGA,
      registerFirebaseNotificationDeviceSaga
    ),
  ]);
}

export default commonSaga;
