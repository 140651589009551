import type {
  FetchAdminActionLogResponseData,
  FetchAdminActionLogsPayload,
  FetchAdminActionLogsResponseData,
} from "@/utils/apis/adminActionLog";

export enum AdminActionLogActionTypes {
  FETCH_REQUESTED = "@@adminActionLog/FETCH_REQUESTED",
  FETCH_SUCCEEDED = "@@adminActionLog/FETCH_SUCCEEDED",
  FETCH_FAILED = "@@adminActionLog/FETCH_FAILED",

  FETCH_ADMIN_ACTION_LOG_SUCCEEDED_SERVER = "@@adminUser/FETCH_ADMIN_ACTION_LOG_SUCCEEDED_SERVER",

  // Saga
  FETCH_ADMIN_ACTION_LOGS_SAGA = "@@adminActionLog/FETCH_ADMIN_ACTION_LOGS_SAGA",
}

// State

export interface AdminActionLogState {
  hydrated?: boolean;

  adminActionLogs: FetchAdminActionLogsResponseData["data"];
  adminActionLogsLoading: boolean;
  adminActionLogsError: string;
  adminActionLogsCount: number;

  adminActionLog: FetchAdminActionLogResponseData | null;
  adminActionLogLoading: boolean;
  adminActionLogError: string;
}

// ---- Reducer Action ----

export type FetchScope = "adminActionLogs" | "adminActionLog";

export type FetchRequestedAction = {
  type: AdminActionLogActionTypes.FETCH_REQUESTED;
  payload: {
    scope: FetchScope;
    isReset?: boolean;
  };
};

export type FetchSucceededAction = {
  type: AdminActionLogActionTypes.FETCH_SUCCEEDED;
  payload: {
    scope: FetchScope;
    data: AdminActionLogState[FetchScope];
    count?: number;
    isLoadMore?: boolean;
  };
};

export type FetchFailedAction = {
  type: AdminActionLogActionTypes.FETCH_FAILED;
  payload: {
    scope: FetchScope;
    error: string;
  };
};

export type FetchAdminActionLogSucceededServerAction = {
  type: AdminActionLogActionTypes.FETCH_ADMIN_ACTION_LOG_SUCCEEDED_SERVER;
  payload: FetchAdminActionLogResponseData | null;
};

// ---- Saga Action ----

export type FetchAdminActionLogsSagaAction = {
  type: AdminActionLogActionTypes.FETCH_ADMIN_ACTION_LOGS_SAGA;
  payload: FetchAdminActionLogsPayload;
  meta?: {
    isLoadMore?: boolean;
    isReset?: boolean;
    resolve?: (payload?: any) => void;
  };
};

export type AdminActionLogAction =
  | FetchRequestedAction
  | FetchSucceededAction
  | FetchFailedAction
  | FetchAdminActionLogSucceededServerAction
  //
  | FetchAdminActionLogsSagaAction;
