import type {
  FetchCustomersPayload,
  FetchCustomersResponseData,
  FetchCustomerResponseData,
  UpdateCustomerBlockedPayload,
  DeleteCustomerPayload,
  UpdateCustomerPayload,
  CreateCustomerPayload,
} from "@/utils/apis/customer";

export enum CustomerActionTypes {
  FETCH_REQUESTED = "@@customer/FETCH_REQUESTED",
  FETCH_SUCCEEDED = "@@customer/FETCH_SUCCEEDED",
  FETCH_FAILED = "@@customer/FETCH_FAILED",

  UPDATE_CUSTOMER_BLOCKED_SUCCEEDED = "@@customer/UPDATE_CUSTOMER_BLOCKED_SUCCEEDED",

  FETCH_CUSTOMER_SUCCEEDED_SERVER = "@@customer/FETCH_CUSTOMER_SUCCEEDED_SERVER",

  // Saga
  FETCH_CUSTOMERS_SAGA = "@@customer/FETCH_CUSTOMERS_SAGA",
  UPDATE_CUSTOMER_BLOCKED_SAGA = "@@customer/UPDATE_CUSTOMER_BLOCKED_SAGA",
  UPDATE_CUSTOMER_SAGA = "@@customer/UPDATE_CUSTOMER_SAGA",
  CREATE_CUSTOMER_SAGA = "@@customer/CREATE_CUSTOMER_SAGA",
  DELETE_CUSTOMER_SAGA = "@@customer/DELETE_CUSTOMER_SAGA",
}

// State

export interface CustomerState {
  hydrated?: boolean;

  customers: FetchCustomersResponseData["data"];
  customersLoading: boolean;
  customersError: string;
  customersCount: number;

  customer: FetchCustomerResponseData["data"] | null;
  customerLoading: boolean;
  customerError: string;
}

// ---- Reducer Action ----

export type FetchScope = "customers" | "customer";

export type FetchRequestedAction = {
  type: CustomerActionTypes.FETCH_REQUESTED;
  payload: {
    scope: FetchScope;
    isReset?: boolean;
  };
};

export type FetchSucceededAction = {
  type: CustomerActionTypes.FETCH_SUCCEEDED;
  payload: {
    scope: FetchScope;
    data: CustomerState[FetchScope];
    count?: number;
    isLoadMore?: boolean;
  };
};

export type FetchFailedAction = {
  type: CustomerActionTypes.FETCH_FAILED;
  payload: {
    scope: FetchScope;
    error: string;
  };
};

export type FetchCustomerSucceededServerAction = {
  type: CustomerActionTypes.FETCH_CUSTOMER_SUCCEEDED_SERVER;
  payload: FetchCustomerResponseData["data"] | null;
};

export type UpdateCustomerBlockedSucceededAction = {
  type: CustomerActionTypes.UPDATE_CUSTOMER_BLOCKED_SUCCEEDED;
  payload: {
    id: number;
    is_blocked: BooleanNumber;
  };
  meta?: {
    resolve?: (payload?: any) => void;
  };
};

// ---- Saga Action ----

export type FetchCustomersSagaAction = {
  type: CustomerActionTypes.FETCH_CUSTOMERS_SAGA;
  payload?: FetchCustomersPayload;
  meta?: {
    isLoadMore?: boolean;
    isReset?: boolean;
    resolve?: (payload?: any) => void;
  };
};

export type UpdateCustomerBlockedSagaAction = {
  type: CustomerActionTypes.UPDATE_CUSTOMER_BLOCKED_SAGA;
  payload: UpdateCustomerBlockedPayload;
  meta?: {
    resolve?: (payload?: any) => void;
  };
};

export type UpdateCustomerSagaAction = {
  type: CustomerActionTypes.UPDATE_CUSTOMER_SAGA;
  payload: UpdateCustomerPayload;
  meta?: {
    resolve?: (payload?: any) => void;
  };
};

export type CreateCustomerSagaAction = {
  type: CustomerActionTypes.CREATE_CUSTOMER_SAGA;
  payload: CreateCustomerPayload;
  meta?: {
    resolve?: (payload?: any) => void;
  };
};

export type DeleteCustomerSagaAction = {
  type: CustomerActionTypes.DELETE_CUSTOMER_SAGA;
  payload: DeleteCustomerPayload;
  meta?: {
    resolve?: (payload?: any) => void;
  };
};

export type CustomerAction =
  | FetchRequestedAction
  | FetchSucceededAction
  | FetchFailedAction
  | FetchCustomerSucceededServerAction
  | UpdateCustomerBlockedSucceededAction
  //
  | FetchCustomersSagaAction
  | UpdateCustomerBlockedSagaAction
  | UpdateCustomerSagaAction
  | CreateCustomerSagaAction
  | DeleteCustomerSagaAction;
