import { RoleActionTypes } from "./types";
import type {
  FetchRequestedAction,
  FetchSucceededAction,
  FetchFailedAction,
  FetchRoleSucceededServerAction,
  UpdateRoleEnabledSucceededAction,
  // Saga
  FetchRolesSagaAction,
  UpdateRoleEnabledSagaAction,
  CreateRoleSagaAction,
  UpdateRoleSagaAction,
  DeleteRoleSagaAction,
} from "./types";

// ---- REDUCER ACTION ----

export const fetchRequested = (
  payload: FetchRequestedAction["payload"]
): FetchRequestedAction => ({
  type: RoleActionTypes.FETCH_REQUESTED,
  payload,
});

export const fetchSucceeded = (
  payload: FetchSucceededAction["payload"]
): FetchSucceededAction => ({
  type: RoleActionTypes.FETCH_SUCCEEDED,
  payload,
});

export const fetchFailed = (
  payload: FetchFailedAction["payload"]
): FetchFailedAction => ({
  type: RoleActionTypes.FETCH_FAILED,
  payload,
});

export const fetchRoleSucceededServer = (
  payload: FetchRoleSucceededServerAction["payload"]
): FetchRoleSucceededServerAction => ({
  type: RoleActionTypes.FETCH_ROLE_SUCCEEDED_SERVER,
  payload,
});

export const updateRoleEnabledSucceeded = (
  payload: UpdateRoleEnabledSucceededAction["payload"]
): UpdateRoleEnabledSucceededAction => ({
  type: RoleActionTypes.UPDATE_ROLE_ENABLED_SUCCEEDED,
  payload,
});

// ---- SAGA ACTION ----

export const fetchRolesSaga = (
  payload?: FetchRolesSagaAction["payload"],
  meta?: FetchRolesSagaAction["meta"]
): FetchRolesSagaAction => ({
  type: RoleActionTypes.FETCH_ROLES_SAGA,
  payload,
  meta,
});

export const updateRoleEnabledSaga = (
  payload: UpdateRoleEnabledSagaAction["payload"],
  meta?: UpdateRoleEnabledSagaAction["meta"]
): UpdateRoleEnabledSagaAction => ({
  type: RoleActionTypes.UPDATE_ROLE_ENABLED_SAGA,
  payload,
  meta,
});

export const updateRoleSaga = (
  payload: UpdateRoleSagaAction["payload"],
  meta?: UpdateRoleSagaAction["meta"]
): UpdateRoleSagaAction => ({
  type: RoleActionTypes.UPDATE_ROLE_SAGA,
  payload,
  meta,
});

export const createRoleSaga = (
  payload: CreateRoleSagaAction["payload"],
  meta?: CreateRoleSagaAction["meta"]
): CreateRoleSagaAction => ({
  type: RoleActionTypes.CREATE_ROLE_SAGA,
  payload,
  meta,
});

export const deleteRoleSaga = (
  payload: DeleteRoleSagaAction["payload"],
  meta?: DeleteRoleSagaAction["meta"]
): DeleteRoleSagaAction => ({
  type: RoleActionTypes.DELETE_ROLE_SAGA,
  payload,
  meta,
});
