export const OPEN_WITH_MAIN_HOURS_WORKING_TIME_TYPE = 1;
export const OPEN_WITH_NO_MAIN_HOURS_WORKING_TIME_TYPE = 2;
export const TEMPORARILY_CLOSED_WORKING_TIME_TYPE = 3;
export const PERMANENTLY_CLOSED_WORKING_TIME_TYPE = 4;

export const workingTimeTypes = [
  {
    label: "openWithMainHours",
    value: OPEN_WITH_MAIN_HOURS_WORKING_TIME_TYPE,
  },
  {
    label: "openWithNoMainHours",
    value: OPEN_WITH_NO_MAIN_HOURS_WORKING_TIME_TYPE,
  },
  {
    label: "temporarilyClosed",
    value: TEMPORARILY_CLOSED_WORKING_TIME_TYPE,
  },
  {
    label: "permanentlyClosed",
    value: PERMANENTLY_CLOSED_WORKING_TIME_TYPE,
  },
];

export const typeToWorkingTimeTypeMap = workingTimeTypes.reduce(
  (item, workingTimeType) => {
    item[workingTimeType.value] = workingTimeType;
    return item;
  },
  {} as { [type: number]: (typeof workingTimeTypes)[number] }
);
