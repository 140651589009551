import { authAxios } from "@/libs/axios";

import type {
  FetchProductServicesPayload,
  FetchProductServicesResponseData,
  FetchOptionProductServicesPayload,
  FetchOptionProductServicesResponseData,
  CreateProductServicePayload,
  UpdateProductServicePayload,
  UpdateProductServiceEnabledPayload,
  DeleteProductServicePayload,
  FetchProductServicePayload,
  FetchProductServiceResponseData,
  FetchBeautyCenterProductServicesPayload,
  FetchBeautyCenterProductServicesResponse,
  FetchBeautyCenterProductServiceSettingPayload,
  FetchBeautyCenterProductServiceSettingResponseData,
  SaveBeautyCenterProductServiceSettingPayload,
  DownloadProductServicesPayload
} from "./productService.api.types";
import type { AxiosResponseData } from "@/libs/axios";

const productServiceApi = {
  fetchProductServices: (payload?: FetchProductServicesPayload) => {
    return authAxios.get<AxiosResponseData<FetchProductServicesResponseData>>(
      "product_services",
      {
        params: payload?.params,
        cancelToken: payload?.cancelToken,
      }
    );
  },
  fetchOptionProductServices: (payload?: FetchOptionProductServicesPayload) => {
    return authAxios.get<
      AxiosResponseData<FetchOptionProductServicesResponseData>
    >("product_services/select_list", {
      params: payload?.params,
      cancelToken: payload?.cancelToken,
    });
  },
  fetchProductService: (payload: FetchProductServicePayload) => {
    const { id, ...params } = payload.params;
    return authAxios.get<AxiosResponseData<FetchProductServiceResponseData>>(
      `product_services/${id}`,
      {
        params: params,
        cancelToken: payload?.cancelToken,
        headers: payload.headers,
      }
    );
  },
  fetchBeautyCenterProductServices: (
    payload: FetchBeautyCenterProductServicesPayload
  ) => {
    const { beauty_center_id, ...params } = payload.params;

    return authAxios.get<
      AxiosResponseData<FetchBeautyCenterProductServicesResponse>
    >(
      `beauty_centers_product_services/beauty_centers/${beauty_center_id}/product_services`,
      {
        params,
        cancelToken: payload?.cancelToken,
      }
    );
  },
  fetchBeautyCenterProductServiceSetting: (
    payload: FetchBeautyCenterProductServiceSettingPayload
  ) => {
    const { product_service_id, beauty_center_id, ...params } = payload.params;

    return authAxios.get<
      AxiosResponseData<FetchBeautyCenterProductServiceSettingResponseData>
    >(
      `beauty_centers_product_services/beauty_centers/${beauty_center_id}/product_services/${product_service_id}`,
      {
        params,
        cancelToken: payload?.cancelToken,
        headers: payload.headers,
      }
    );
  },
  saveBeautyCenterProductServiceSetting: (
    payload: SaveBeautyCenterProductServiceSettingPayload
  ) => {
    const { product_service_id, beauty_center_id, ...params } = payload.params;

    return authAxios.put<AxiosResponseData>(
      `beauty_centers_product_services/beauty_centers/${beauty_center_id}/product_services/${product_service_id}`,
      params,
      {
        cancelToken: payload?.cancelToken,
      }
    );
  },
  createProductService: (payload: CreateProductServicePayload) => {
    return authAxios.post<AxiosResponseData>(
      "product_services",
      payload.params,
      {
        cancelToken: payload?.cancelToken,
      }
    );
  },
  updateProductService: (payload: UpdateProductServicePayload) => {
    const { id, ...params } = payload.params;
    return authAxios.put<AxiosResponseData>(`product_services/${id}`, params, {
      cancelToken: payload?.cancelToken,
    });
  },
  updateProductServiceEnabled: (
    payload: UpdateProductServiceEnabledPayload
  ) => {
    const { id, ...params } = payload.params;
    return authAxios.patch<AxiosResponseData>(
      `product_services/enable/${id}`,
      params,
      {
        cancelToken: payload?.cancelToken,
      }
    );
  },
  deleteProductService: (payload: DeleteProductServicePayload) => {
    const { id } = payload.params;
    return authAxios.delete<AxiosResponseData>(`product_services/${id}`, {
      cancelToken: payload?.cancelToken,
    });
  },
  downloadProductServices: (payload?: DownloadProductServicesPayload) => {
    return authAxios.get<Blob>("product_services/export", {
      params: payload?.params,
      cancelToken: payload?.cancelToken,
      responseType: "blob",
    });
  },
};

export default productServiceApi;
