import _uniq from "lodash/uniq";
import { commonHelpers } from "@/utils/helpers";

import type { DistrictState, DistrictTree } from "@/store/district/types";

export const mapListToIdToTreeItemMap = <P extends DistrictState["districts"]>(
  districts: P
) => {
  const districtMap = {} as {
    [id: number]: DistrictTree;
  };

  districts.forEach((district) => {
    districtMap[district.id] = {
      ...district,
      is_last_item: true,
      sub_district_ids: [],
      sub_districts: [],
    };
  });

  districts.forEach((district) => {
    if (!commonHelpers.isEmpty(districtMap[district.parent_id])) {
      districtMap[district.parent_id].sub_districts.push(
        districtMap[district.id]
      );
      districtMap[district.parent_id].is_last_item = false;
      const updateSubDistrictIds = (
        id: number,
        parentId: number,
        subParentIds: number[]
      ) => {
        if (!commonHelpers.isEmpty(districtMap[parentId])) {
          const newNextIds = [id, ...subParentIds];

          districtMap[parentId].sub_district_ids = _uniq(
            districtMap[parentId].sub_district_ids.concat(newNextIds)
          );
          updateSubDistrictIds(
            districtMap[parentId].id,
            districtMap[parentId].parent_id,
            districtMap[parentId].sub_district_ids
          );
        }
      };
      updateSubDistrictIds(
        district.id,
        district.parent_id,
        districtMap[district.parent_id].sub_district_ids
      );
    }
  });

  return districtMap;
};

export const mapListToIdToItemMap = <P extends DistrictState["districts"]>(
  districts: P
) => {
  const districtMap = {} as {
    [id: number]: P[number];
  };

  districts.forEach((district) => {
    Object.assign(districtMap, {
      [district.id]: {
        ...district,
      },
    });
  });

  return districtMap;
};

export const mapListToTreeList = <P extends DistrictState["districts"]>(
  districts: P
) => {
  const newDistricts = [] as DistrictTree[];
  const districtMap = {} as {
    [id: number]: DistrictTree;
  };

  districts.forEach((district) => {
    districtMap[district.id] = {
      ...district,
      is_last_item: true,
      sub_district_ids: [],
      sub_districts: [],
    };
  });

  districts.forEach((district) => {
    if (!commonHelpers.isEmpty(districtMap[district.parent_id])) {
      districtMap[district.parent_id].sub_districts.push(
        districtMap[district.id]
      );
      districtMap[district.parent_id].is_last_item = false;
      const updateSubDistrictIds = (
        id: number,
        parentId: number,
        subParentIds: number[]
      ) => {
        if (!commonHelpers.isEmpty(districtMap[parentId])) {
          const newNextIds = [id, ...subParentIds];

          districtMap[parentId].sub_district_ids = _uniq(
            districtMap[parentId].sub_district_ids.concat(newNextIds)
          );
          updateSubDistrictIds(
            districtMap[parentId].id,
            districtMap[parentId].parent_id,
            districtMap[parentId].sub_district_ids
          );
        }
      };
      updateSubDistrictIds(
        district.id,
        district.parent_id,
        districtMap[district.parent_id].sub_district_ids
      );
    } else newDistricts.push(districtMap[district.id]);
  });

  return newDistricts;
};

export const mapListToNestedList = <P extends DistrictState["districts"]>(
  districts: P
) => {
  const newDistricts = [] as DistrictTree[];
  const districtMap = {} as {
    [id: number]: DistrictTree;
  };

  districts.forEach((district) => {
    districtMap[district.id] = {
      ...district,
      is_last_item: true,
      sub_district_ids: [],
      sub_districts: [],
    };
  });

  districts.forEach((district) => {
    if (!commonHelpers.isEmpty(districtMap[district.parent_id])) {
      districtMap[district.parent_id].sub_districts.push(
        districtMap[district.id]
      );
      districtMap[district.parent_id].is_last_item = false;
      const updateSubDistrictIds = (
        id: number,
        parentId: number,
        subParentIds: number[]
      ) => {
        if (!commonHelpers.isEmpty(districtMap[parentId])) {
          const newNextIds = [id, ...subParentIds];

          districtMap[parentId].sub_district_ids = _uniq(
            districtMap[parentId].sub_district_ids.concat(newNextIds)
          );
          updateSubDistrictIds(
            districtMap[parentId].id,
            districtMap[parentId].parent_id,
            districtMap[parentId].sub_district_ids
          );
        }
      };
      updateSubDistrictIds(
        district.id,
        district.parent_id,
        districtMap[district.parent_id].sub_district_ids
      );
    }

    newDistricts.push(districtMap[district.id]);
  });

  return newDistricts;
};
