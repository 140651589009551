import { all, call, put, takeEvery } from "redux-saga/effects";
import axios from "axios";

import { axiosHelpers } from "@/utils/helpers";
import { appointmentLogApi } from "@/utils/apis";

import { AppointmentLogActionTypes } from "./types";
import { fetchRequested, fetchFailed, fetchSucceeded } from "./action";

import type { FetchScope, FetchAppointmentLogsSagaAction } from "./types";

function* fetchAppointmentLogsSaga(action: FetchAppointmentLogsSagaAction) {
  const { params, cancelToken } = action.payload || {};
  const { resolve = () => {}, isLoadMore, isReset } = action.meta || {};
  const scope = "appointmentLogs" as FetchScope;
  yield put(
    fetchRequested({
      scope,
      isReset,
    })
  );
  try {
    const {
      data: response,
    }: Awaited<ReturnType<typeof appointmentLogApi.fetchAppointmentLogs>> =
      yield call(appointmentLogApi.fetchAppointmentLogs, {
        params,
        cancelToken,
      });
    if (axiosHelpers.checkRequestSuccess(response)) {
      yield put(
        fetchSucceeded({
          scope,
          data: response.data?.data ?? [],
          count: response.data?.pagination?.total ?? 0,
          isLoadMore: !!isLoadMore,
        })
      );
    } else {
      yield put(
        fetchFailed({
          scope,
          error: response.message,
        })
      );
    }
    resolve(response);
  } catch (error) {
    if (axios.isCancel(error)) {
      resolve({ message: error.message, isCancelled: true });
      return;
    }
    const message = axiosHelpers.getErrorMessage(error);
    yield put(
      fetchFailed({
        scope,
        error: message,
      })
    );
    resolve({ message });
  }
}

function* appointmentLogSaga() {
  yield all([
    takeEvery(
      AppointmentLogActionTypes.FETCH_APPOINTMENT_LOGS_SAGA,
      fetchAppointmentLogsSaga
    ),
  ]);
}

export default appointmentLogSaga;
