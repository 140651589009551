import { authAxios } from "@/libs/axios";

import type {
  DownloadBeautyCenterProductServiceSettingsPayload,
  DownloadBeautyCenterProductServiceSettingsSampleFilePayload,
  UploadBeautyCenterProductServiceSettingsPayload,
} from "./commonBeautyCenterProductServiceSetting.api.types";
import type { AxiosResponseData } from "@/libs/axios";

const commonBeautyCenterProductServiceSettingApi = {
  downloadBeautyCenterProductServiceSettings: (
    payload?: DownloadBeautyCenterProductServiceSettingsPayload
  ) => {
    return authAxios.get<Blob>("beauty_centers_product_services/export", {
      cancelToken: payload?.cancelToken,
      responseType: "blob",
      headers: {
        "Content-Type": "blob",
      },
    });
  },
  downloadBeautyCenterProductServiceSettingsSampleFile: (
    payload?: DownloadBeautyCenterProductServiceSettingsSampleFilePayload
  ) => {
    return authAxios.get<Blob>(
      "beauty_centers_product_services/export_sample",
      {
        cancelToken: payload?.cancelToken,
        responseType: "blob",
        headers: {
          "Content-Type": "blob",
        },
      }
    );
  },
  uploadBeautyCenterProductServiceSettings: (
    payload: UploadBeautyCenterProductServiceSettingsPayload
  ) => {
    const { file } = payload.params;
    const fd = new FormData();
    fd.append("file", file);

    return authAxios.post<AxiosResponseData>(
      "beauty_centers_product_services/import",
      fd,
      {
        cancelToken: payload?.cancelToken,
      }
    );
  },
};

export default commonBeautyCenterProductServiceSettingApi;
