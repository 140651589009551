import { ProductServiceActionTypes } from "./types";
import { commonHelpers } from "@/utils/helpers";
import { HYDRATE } from "next-redux-wrapper";

import type { ProductServiceState, ProductServiceAction } from "./types";

export const initialState: ProductServiceState = {
  productServices: [],
  productServicesError: "",
  productServicesLoading: true,
  productServicesCount: 0,

  beautyCenterProductServices: [],
  beautyCenterProductServicesError: "",
  beautyCenterProductServicesLoading: true,
  beautyCenterProductServicesCount: 0,

  productService: null,
  productServiceError: "",
  productServiceLoading: true,

  beautyCenterProductServiceSetting: null,
  beautyCenterProductServiceSettingError: "",
  beautyCenterProductServiceSettingLoading: true,
};

const reducer = (
  state = initialState,
  action: ProductServiceAction
): ProductServiceState => {
  switch (action.type) {
    case HYDRATE as any: {
      const {
        productService,
        productServiceError,
        productServiceLoading,
        beautyCenterProductServiceSetting,
        beautyCenterProductServiceSettingError,
        beautyCenterProductServiceSettingLoading,
        hydrated,
      } = (action as any).payload.productService as ProductServiceState;

      const newState = {
        productService,
        productServiceError,
        productServiceLoading,
        beautyCenterProductServiceSetting,
        beautyCenterProductServiceSettingError,
        beautyCenterProductServiceSettingLoading,
      };

      if (typeof hydrated !== "undefined") {
        Object.entries(newState).forEach(([key, _state]) => {
          if (typeof _state === "undefined") delete (newState as any)[key];
        });
      }

      return {
        ...state,
        ...(typeof hydrated !== "undefined" ? newState : {}),
        hydrated: true,
      };
    }

    case ProductServiceActionTypes.FETCH_REQUESTED: {
      const { scope, isReset } = action.payload;

      const newState = {
        ...state,
        ...(typeof state[`${scope}Loading` as keyof typeof state] !==
        "undefined"
          ? {
              [`${scope}Loading`]: true,
            }
          : {}),
        ...(typeof state[`${scope}Error` as keyof typeof state] !== "undefined"
          ? {
              [`${scope}Error`]: "",
            }
          : {}),
      };

      if (isReset) {
        Object.assign(newState, {
          [scope]: Array.isArray(newState[scope]) ? [] : null,
        });
      }

      return newState;
    }
    case ProductServiceActionTypes.FETCH_SUCCEEDED: {
      const { scope, data, count, isLoadMore } = action.payload;

      let newData = data;
      const stateData = state[scope];

      if (isLoadMore && Array.isArray(stateData) && Array.isArray(data)) {
        const filteredData = (data as any).filter((item: any) => {
          return (stateData as any).every(
            (stateDataItem: any) => item.id !== stateDataItem.id
          );
        });
        newData = [...stateData, ...filteredData];
      }

      return {
        ...state,
        [scope]: newData,
        ...(typeof state[`${scope}Loading` as keyof typeof state] !==
        "undefined"
          ? {
              [`${scope}Loading`]: false,
            }
          : {}),
        ...(commonHelpers.isNumber(count)
          ? {
              [`${scope}Count`]: count,
            }
          : {}),
      };
    }
    case ProductServiceActionTypes.FETCH_FAILED: {
      const { scope, error } = action.payload;

      return {
        ...state,
        ...(typeof state[`${scope}Loading` as keyof typeof state] !==
        "undefined"
          ? {
              [`${scope}Loading`]: false,
            }
          : {}),
        ...(typeof state[`${scope}Error` as keyof typeof state] !== "undefined"
          ? {
              [`${scope}Error`]: error,
            }
          : {}),
      };
    }

    case ProductServiceActionTypes.FETCH_PRODUCT_SERVICE_SUCCEEDED_SERVER: {
      return {
        productService: action.payload,
        productServiceLoading: false,
        productServiceError: "",
        hydrated: true,
      } as Partial<ProductServiceState> as ProductServiceState;
    }

    case ProductServiceActionTypes.FETCH_BEAUTY_CENTER_PRODUCT_SERVICE_SETTING_SUCCEEDED_SERVER: {
      return {
        beautyCenterProductServiceSetting: action.payload,
        beautyCenterProductServiceSettingLoading: false,
        beautyCenterProductServiceSettingError: "",
        hydrated: true,
      } as Partial<ProductServiceState> as ProductServiceState;
    }

    case ProductServiceActionTypes.UPDATE_PRODUCT_SERVICE_ENABLED_SUCCEEDED: {
      const { id, is_enabled } = action.payload;

      return {
        ...state,
        productServices: state.productServices.map((productService) => {
          if (productService.id === id)
            return {
              ...productService,
              is_enabled,
            };
          return productService;
        }),
        productService:
          state.productService?.id === id
            ? {
                ...state.productService,
                is_enabled,
              }
            : state.productService,
      };
    }

    default: {
      return state;
    }
  }
};

export default reducer;
