import { AppointmentRescheduledRequestActionTypes } from "./types";
import type {
  FetchRequestedAction,
  FetchSucceededAction,
  FetchFailedAction,
  // Saga
  FetchAppointmentRescheduledRequestsSagaAction,
} from "./types";

// ---- REDUCER ACTION ----

export const fetchRequested = (
  payload: FetchRequestedAction["payload"]
): FetchRequestedAction => ({
  type: AppointmentRescheduledRequestActionTypes.FETCH_REQUESTED,
  payload,
});

export const fetchSucceeded = (
  payload: FetchSucceededAction["payload"]
): FetchSucceededAction => ({
  type: AppointmentRescheduledRequestActionTypes.FETCH_SUCCEEDED,
  payload,
});

export const fetchFailed = (
  payload: FetchFailedAction["payload"]
): FetchFailedAction => ({
  type: AppointmentRescheduledRequestActionTypes.FETCH_FAILED,
  payload,
});

// ---- SAGA ACTION ----

export const fetchAppointmentRescheduledRequestsSaga = (
  payload: FetchAppointmentRescheduledRequestsSagaAction["payload"],
  meta?: FetchAppointmentRescheduledRequestsSagaAction["meta"]
): FetchAppointmentRescheduledRequestsSagaAction => ({
  type: AppointmentRescheduledRequestActionTypes.FETCH_APPOINTMENT_RESCHEDULED_REQUESTS_SAGA,
  payload,
  meta,
});
