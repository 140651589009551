import type {
  FetchAppointmentAutoAssignedBeautyCenterGroupsPayload,
  FetchAppointmentAutoAssignedBeautyCenterGroupsResponseData,
} from "@/utils/apis/appointmentAutoAssignedBeautyCenterGroup";

export enum AppointmentAutoAssignedBeautyCenterGroupActionTypes {
  FETCH_REQUESTED = "@@appointmentAutoAssignedBeautyCenterGroup/FETCH_REQUESTED",
  FETCH_SUCCEEDED = "@@appointmentAutoAssignedBeautyCenterGroup/FETCH_SUCCEEDED",
  FETCH_FAILED = "@@appointmentAutoAssignedBeautyCenterGroup/FETCH_FAILED",
  // Saga
  FETCH_APPOINTMENT_AUTO_ASSIGNED_BEAUTY_CENTER_GROUPS_SAGA = "@@appointmentAutoAssignedBeautyCenterGroup/FETCH_APPOINTMENT_AUTO_ASSIGNED_BEAUTY_CENTER_GROUPS_SAGA",
}

// State

export interface AppointmentAutoAssignedBeautyCenterGroupState {
  appointmentAutoAssignedBeautyCenterGroups: FetchAppointmentAutoAssignedBeautyCenterGroupsResponseData["data"];
  appointmentAutoAssignedBeautyCenterGroupsLoading: boolean;
  appointmentAutoAssignedBeautyCenterGroupsError: string;
}

// ---- Reducer Action ----

export type FetchScope = "appointmentAutoAssignedBeautyCenterGroups";

export type FetchRequestedAction = {
  type: AppointmentAutoAssignedBeautyCenterGroupActionTypes.FETCH_REQUESTED;
  payload: {
    scope: FetchScope;
    isReset?: boolean;
  };
};

export type FetchSucceededAction = {
  type: AppointmentAutoAssignedBeautyCenterGroupActionTypes.FETCH_SUCCEEDED;
  payload: {
    scope: FetchScope;
    data: AppointmentAutoAssignedBeautyCenterGroupState[FetchScope];
    count?: number;
    isLoadMore?: boolean;
  };
};

export type FetchFailedAction = {
  type: AppointmentAutoAssignedBeautyCenterGroupActionTypes.FETCH_FAILED;
  payload: {
    scope: FetchScope;
    error: string;
  };
};

// ---- Saga Action ----

export type FetchAppointmentAutoAssignedBeautyCenterGroupsSagaAction = {
  type: AppointmentAutoAssignedBeautyCenterGroupActionTypes.FETCH_APPOINTMENT_AUTO_ASSIGNED_BEAUTY_CENTER_GROUPS_SAGA;
  payload: FetchAppointmentAutoAssignedBeautyCenterGroupsPayload;
  meta?: {
    isLoadMore?: boolean;
    isReset?: boolean;
    resolve?: (payload?: any) => void;
  };
};

export type AppointmentAutoAssignedBeautyCenterGroupAction =
  | FetchRequestedAction
  | FetchSucceededAction
  | FetchFailedAction
  //
  | FetchAppointmentAutoAssignedBeautyCenterGroupsSagaAction;
