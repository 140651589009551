import { all, call, put, takeEvery, takeLatest } from "redux-saga/effects";
import axios from "axios";

import { axiosHelpers } from "@/utils/helpers";
import { beautyCenterProductServiceSettingApi } from "@/utils/apis";

import { BeautyCenterProductServiceSettingActionTypes } from "./types";
import { fetchRequested, fetchFailed, fetchSucceeded } from "./action";

import type {
  FetchScope,
  FetchBeautyCenterProductServiceSettingsSagaAction,
  CreateBeautyCenterProductServiceSettingSagaAction,
  DeleteBeautyCenterProductServiceSettingsSagaAction,
} from "./types";

function* fetchBeautyCenterProductServiceSettingsSaga(
  action: FetchBeautyCenterProductServiceSettingsSagaAction
) {
  const { params, cancelToken } = action.payload || {};
  const { resolve = () => {}, isReset } = action.meta || {};
  const scope = "beautyCenterProductServiceSettings" as FetchScope;
  yield put(
    fetchRequested({
      scope,
      isReset,
    })
  );
  try {
    const {
      data: response,
    }: Awaited<
      ReturnType<
        typeof beautyCenterProductServiceSettingApi.fetchBeautyCenterProductServiceSettings
      >
    > = yield call(
      beautyCenterProductServiceSettingApi.fetchBeautyCenterProductServiceSettings,
      {
        params,
        cancelToken,
      }
    );
    if (axiosHelpers.checkRequestSuccess(response)) {
      yield put(
        fetchSucceeded({
          scope,
          data: response.data?.data ?? [],
          count: response.data?.pagination?.total ?? 0,
        })
      );
    } else {
      yield put(
        fetchFailed({
          scope,
          error: response.message,
        })
      );
    }
    resolve(response);
  } catch (error) {
    if (axios.isCancel(error)) {
      resolve({ message: error.message, isCancelled: true });
      return;
    }
    const message = axiosHelpers.getErrorMessage(error);
    yield put(
      fetchFailed({
        scope,
        error: message,
      })
    );
    resolve({ message });
  }
}

function* createBeautyCenterProductServiceSettingSaga(
  action: CreateBeautyCenterProductServiceSettingSagaAction
) {
  const { params, cancelToken } = action.payload;
  const { resolve = () => {} } = action.meta || {};

  try {
    const {
      data: response,
    }: Awaited<
      ReturnType<
        typeof beautyCenterProductServiceSettingApi.createBeautyCenterProductServiceSetting
      >
    > = yield call(
      beautyCenterProductServiceSettingApi.createBeautyCenterProductServiceSetting,
      {
        params,
        cancelToken,
      }
    );
    resolve(response);
  } catch (error) {
    if (axios.isCancel(error)) {
      resolve({ message: error.message, isCancelled: true });
      return;
    }
    const message = axiosHelpers.getErrorMessage(error);
    resolve({ message });
  }
}

function* deleteBeautyCenterProductServiceSettingsSaga(
  action: DeleteBeautyCenterProductServiceSettingsSagaAction
) {
  const { params, cancelToken } = action.payload;
  const { resolve = () => {} } = action.meta || {};

  try {
    const {
      data: response,
    }: Awaited<
      ReturnType<
        typeof beautyCenterProductServiceSettingApi.deleteBeautyCenterProductServiceSettings
      >
    > = yield call(
      beautyCenterProductServiceSettingApi.deleteBeautyCenterProductServiceSettings,
      {
        params,
        cancelToken,
      }
    );
    resolve(response);
  } catch (error) {
    if (axios.isCancel(error)) {
      resolve({ message: error.message, isCancelled: true });
      return;
    }
    const message = axiosHelpers.getErrorMessage(error);
    resolve({ message });
  }
}

function* beautyCenterProductServiceSettingSaga() {
  yield all([
    takeEvery(
      BeautyCenterProductServiceSettingActionTypes.FETCH_BEAUTY_CENTER_PRODUCT_SERVICE_SETTINGS_SAGA,
      fetchBeautyCenterProductServiceSettingsSaga
    ),
    takeLatest(
      BeautyCenterProductServiceSettingActionTypes.CREATE_BEAUTY_CENTER_PRODUCT_SERVICE_SETTING_SAGA,
      createBeautyCenterProductServiceSettingSaga
    ),
    takeLatest(
      BeautyCenterProductServiceSettingActionTypes.DELETE_BEAUTY_CENTER_PRODUCT_SERVICE_SETTINGS_SAGA,
      deleteBeautyCenterProductServiceSettingsSaga
    ),
  ]);
}

export default beautyCenterProductServiceSettingSaga;
